*{margin: 0px;padding: 0px;}
/******************LANDING PAGE CSS START************************************/
.containerLanding {
	max-width: 95%;
	width: 1200px;
	margin: auto;
}
.happySchoolContainer{
  display: flex;
  flex-direction: column;
}
  .headerLanding {
    background-color: #fff;
    box-shadow: 1px 0px 4px 0 rgb(0 0 0 / 10%);
    position: fixed;
    width: 100%;
    z-index: 3;
    padding: 7px 30px;
}
  .headerLanding ul {
    margin: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    background-color: #fff;
  }
  .headerLanding li a {
    display: block;
    padding: 10px 20px;
    text-decoration: none;
    color: #070707;
    font-family: 'nexabold';
    font-size: 15px;
    border-radius: 5px;
  }
  .headerLanding li a:hover,
  .headerLanding .menu-btn:hover {
    background-color: #f4f4f4;
  }
  
  .headerLanding .logo {
    display: block;
    float: left;
    padding: 0;
    text-decoration: none;
  }
  .headerLanding li.loginBtn a.btn.btn-orange {
    min-width: inherit;
    color: #fff;
    font-size: 13px;
    padding: 10px 15px 7px 15px !important;
    margin-left: 10px;
}
  .headerLanding li.loginBtn a.btn.btn-orange:hover {
    background: #fff;
    color: #FE6E00;
    border-color: #FE6E00;
  }
  /* menu */
  
  .headerLanding .menu {
    clear: both;
    max-height: 0;
    transition: max-height .2s ease-out;
  }
  
  /* menu icon */
  
  .headerLanding .menu-icon {
    cursor: pointer;
    display: inline-block;
    float: right;
    padding: 28px 20px;
    position: relative;
    user-select: none;
  }
  
  .headerLanding .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background .2s ease-out;
    width: 18px;
  }
  
  .headerLanding .menu-icon .navicon:before,
  .headerLanding .menu-icon .navicon:after {
    background: #333;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  
  .headerLanding .menu-icon .navicon:before {
    top: 5px;
  }
  
  .headerLanding .menu-icon .navicon:after {
    top: -5px;
  }
  
  /* menu btn */
  
  .headerLanding .menu-btn {
    display: none;
  }
  
  .headerLanding .menu-btn:checked ~ .menu {
    max-height: 240px;
  }
  
  .headerLanding .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }
  
  .headerLanding .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  
  .headerLanding .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }
  
  .headerLanding .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .headerLanding .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }
  .statsBackGroundImg{
    background-image: url('../images/Group55.png');
    background-repeat: no-repeat;
    background-position: 0% 80%;
    background-size: cover;
    height: auto;
    /* padding: 50px !important; */
    /* width: 100%; */
  }
  .statsContainer{
    background-image: url('../images/statsBackground.png');
    background-repeat: no-repeat;
    /* background-position: 0% -10%; */
    background-size: cover;
    display: flex;
    box-shadow: 1px 1px 4px 0px rgba(100,100,100,0.75);
    flex-direction: column;
    width: 20%;
    height: 180px;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    padding: 30px;
    background-color: white;
    border-radius: 5px;
    margin: 20px 0;
    /* height: auto; */
  }
  .happySchoolCard {
    display: flex;
    width: 30%;
    flex-direction: column;
    margin: 20px;
    height: auto;
    align-items: center;
    text-align: center;
    border-radius: 5px;
  }
  .footer {
    display: inline-block;
    width: 100%;
    background: #fbfbfb;
  }
.teamSlider {
	width: 100%;
	float: right;
	position: relative;
	margin-top: 10px;
	left: 0;
	right: 0;
}
.containerLanding.containerOurTeam {
	max-width: initial;
	width: 100%;
	padding-left: 160px;
}
.footerLink ul li a:hover {
  color: #ff9406;
}
.showMob{
  display: none;
}
.videoContainer{
  display: block;
  margin: 0 20%
}
.youtubeVideoTitle{
  text-align: center;
  /* float: none !important; */
  width: 100% !important;
  margin: 20px 0px;
}
  /* 48em = 768px */
  
  @media (min-width: 48em) {
    .headerLanding li {
      float: left;
    }
    .headerLanding .menu {
      clear: none;
      float: right;
      max-height: none;
      border-radius: 5px;
  }
  .headerLanding .menu {
    clear: none;
    float: right;
    max-height: none;
    margin-top: 8px;
  }
.headerLanding .menu-icon {
      display: none;
    }
  }
  

  /*slide bg*/


  .topMargin {
    margin-top: 63px;
    display: inline-block;
    width: 100%;
}
  
.landingSlide {
    height: calc(100vh - 76px);
    width: 100%;
    display: inline-block;
}
.landingSlideImg {
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
}
.landingSlideImg img {
    height: 100%;
    width: 100%;
    display: inline-block;
    object-fit: fill;
}

.landingSlideImg:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
}


.landingSlideText {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    max-width: 850px;
    margin: auto;
    right: 0;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.landingSlideText h2 {
    font-size: 50px;
    font-family: 'nexaxbold';
    margin-bottom: 15px;
}
.landingSlideText p {
    font-size: 25px;
    font-family: 'nexaregular', sans-serif;
    font-weight: normal;
    margin-bottom: 45px;
}
.landingSlideBtn .btn {
    padding: 15px 30px !important;
    margin: 0 8px;
}
.btn-white {
    border: 1px solid #fff;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;
}
.school-generate {
	padding: 130px 0;
	min-height: inherit;
}
.our-partnerImg .our-partner-logo img {
	width: 100%;
	height: 50px;
}
.our-partnerImg {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-column-gap: 40px;
}
.pn-top{
  padding-top: 0 !important;
}
.ourteam {
	position: relative;
  padding: 150px 0;
	/* overflow: hidden; */
}
#WisrNews{
  position: relative;
  padding: 0px 0px 90px 0;
}

.ourteamNews {
	position: relative;
  padding: 0px 0px 90px 0;
	/* overflow: hidden; */
}
.school-generateImg img {
    width: 100%;
    height: 520px;
    border-radius: 5px;
}
.headhx1 {
    font-size: 14px;
    color: #5C5C5C;
    margin-bottom: 15px;
}
.headhx2 {
    font-size: 35px;
    font-family: 'nexaxbold';
    margin-bottom: 20px;
}
.headhx3 {
    font-size: 17px;
    color: #5C5C5C;
    font-family: 'nexaregular';
    line-height: 25px;
}
ul.listAdditional {
    margin: 40px 0 30px 0;
}
ul.listAdditional li {
    position: relative;
    margin-bottom: 25px;
    padding-left: 33px;
    font-family: 'nexaregular';
    font-size: 17px;
}
ul.listAdditional li img {
    position: absolute;
    left: 0;
    top: -2px;
    width: 21px;
}

.school-generateTxt {
    float: right;
    padding-top: 50px;
    width: 90%;
}
/* .btn {
    padding: 15px 30px !important;
} */
.our-partner-school {
  margin: 55px 0 0 0;
}
.our-partner-school h3 {
  font-size: 20px;
  margin-bottom: 30px;
}
.happy-school-one {
  padding: 40px;
  border-radius: 10px;
  white-space: normal;
}
.oneBgcolor {
  background: #f7f6ff;
  border: 1px solid #ebe9fe;
}
.oneBgcolor span {
  background: #aba1fc;
}
.happy-school-one span {
  height: 100px;
  width: 100px;
  display: inline-block;
  border-radius: 100%;
  overflow: hidden;
}
.happy-school-one h3 {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 5px;
}
.happy-school-one p {
  font-size: 14px;
  color: #5C5C5C;
  font-family: 'nexabold';
}
.happy-school-text {
  margin-top: 30px;
  width: 90%;
}
.happy-school-text p {
  font-size: 18px;
  color: #070707;
  line-height: 28px;
  font-family: 'nexaregular';
}
.our-happy-school {
  margin-top: 150px;
}
.happy-school-text p img {
  margin: 0 10px 0 0;
}
.twoBgcolor {
  background: #fef6ff;
  border: 1px solid #fce8ff;
}
.twoBgcolor span {
  background: #f19dff;
}
.threeBgcolor {
  background: #f5fdff;
  border: 1px solid #e7f9fe;
}
.threeBgcolor span {
  background: #96e5fa;
}
.advisory-board {
  background: #fff0dc;
  padding: 130px 0;
}
.our-happy-school h3.headhx2 {
  margin-bottom: 35px;
}
.advisory-board-one {
	margin: 30px 10px 0 10px;
	cursor: pointer;
}
.advisory-board-one span {
	height: 350px;
	width: 100%;
	display: inline-block;
}
.advisory-board-one span img {
	width: 100%;
	border-radius: 7px;
	height: 100%;
	object-fit: unset;
}
.advisory-board-one h4 {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.advisory-board-one p {
  font-size: 14px;
  color: #8A8A8A;
  font-family: 'nexaregular';
}

.advisory-board-oneNew {
	margin: 30px 10px 0 10px;
	cursor: pointer;
}
.advisory-board-oneNew span {
	/* height: 350px; */
	width: 100%;
	display: inline-block;
}
.advisory-board-oneNew span img {
	width: 100%;
	/* border-radius: 7px; */
  border-style: none;
	height: 100%;
	object-fit: unset;
}
.advisory-board-oneNew h4 {
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 5px;
}
.advisory-board-oneNew p {
  font-size: 14px;
  color: #8A8A8A;
  font-family: 'nexaregular';
}




.pL {float: left !important;}
.alignCenter {
  align-items: center;
}
.footerTop {
  padding: 60px 0;
}
.footerBot {
  background: #212223;
  padding: 12px 0;
  display: inline-block;
  width: 100%;
}
.footerLink ul {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
}
.footerLink ul li {
	margin: 0 7px;
	color: #989A9B;
	font-family: 'nexaregular';
	font-size: 15px;
	white-space: nowrap;
}
.footerLink ul li a {
  color: #989A9B;
}
.footerSocial a {
	background: #313334;
	height: 33px;
	width: 33px;
	border-radius: 100%;
	display: grid;
	justify-content: center;
	align-items: center;
	margin: 0 0 0 10px;
	transition: 0.5s;
}
.footerSocial a:hover {
  background: #ff9406;
}
.footerSocial {
  display: flex;
  justify-content: end;
}
.footerSocial a img {
	width: 14px;
	height: 14px;
}
.footerLink {
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}
.ourteam h3.headhx2 {
  margin: 0;
}
.mainContent .btn, .footer .btn {
  margin: 0 !important;
}
.arrowteam {
	display: flex;
	justify-content: end;
	position: absolute;
	right: 125px;
	top: 175px;
	bottom: auto;
  display: none;
}
.arrowteam a {
	margin: 0 0px 0 15px;
	pointer-events: none;
}
.arrowteam a img {
  max-width: 45px;
}


/******pop css start********/

.hide { visibility: hidden; opacity: 0; transform: translateX(-50%) translateY(-50%) scale(0.8) !important; -moz-transform: translateX(-50%) translateY(-50%) scale(0.8) !important; -o-transform: translateX(-50%) translateY(-50%) scale(0.8) !important; -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8) !important; }
.close {
  position: absolute;
  right: 2px;
  top: 0;
  width: 45px;
  height: 45px;
  font-size: 0;
  cursor: pointer;
}
.close:before, .close:after {
  content: '';
  position: absolute;
  background: #767676;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 20px;
  height: 1.5px;
  transform: rotate(45deg);
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
}
.budget .popup-header .close.toggle {
  top: -40px;
  right: -5px;
  height: 25px;
}
.budget .popup-header .close:before,
.budget .popup-header  .close:after
{
background: #ffffff;
}
.close:after { -moz-transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg); }
.popup { position: fixed; top: 50%; left: 50%; -webkit-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); background: #fff; -moz-border-radius: 5px; -o-border-radius: 5px; -webkit-border-radius: 5px; border-radius: 5px; max-width: 700px; box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.09), 0px 11px 12px 7px rgba(0, 0, 0, 0.02); -moz-transition: all 120ms; -webkit-transition: all 120ms; -o-transition: all 120ms; transition: all 120ms; z-index: 999; padding: 25px; width: 100%; }
.ovelap.active { position: fixed; top: 0; left: 0; right: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index:6; }
/******pop css end********/

#landingPopup {
  padding: 0;
  max-width: 850px;
  width: 95%;
}
#landingPopup .popup-body {
  padding: 0;
}

#landingPopup .loginbox {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
}
#landingPopup .login-wave img {
  width:70%;
  margin: auto;
}
#landingPopup .login-form, #landingPopup .signup-form {
  margin: 35px auto;
  width: 75%;
}
#landingPopup .login-wave {
  height: auto;
  border-radius: 5px 0px 5px 0px;
}
#landingPopup .login-form .htx1 {
  margin: 30px 0 10px 0;
  font-size: 22px;
  font-family: 'nexaxbold';
  color: #070707;
}
#landingPopup  .forminpt input {
  margin: 0;
  padding: 5px 15px;
}
#landingPopup span.close.toggle:before, #landingPopup span.close.toggle:after {
  display: none;
}
#landingPopup span.close.toggle {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}
#landingPopup span.close.toggle img {
  margin: auto;
  display: block;
}


.imggallery .slick-arrow.slick-next, .imggallery .slick-arrow.slick-prev {
  /* top: -37px; */
  /* background: #ff9406; */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  opacity: 1;
  top: calc(100% - 200px);

  /* top: calc(100% - 280px); */
}

.imggallery .slick-arrow.slick-prev {

  right: 10px;
  }

.imggallery .slick-arrow.slick-prev {
left: 10px;

}

/* .imggallery .slick-slider {
  margin: 0px !important;
} */
.slick-slide-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
    0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
}

.slider-wrapper {
  width: 760px;
  margin: 0px;
} 

.slick-arrow.slick-next, .slick-arrow.slick-prev {
	position: absolute;
	right: 8px;
	top: -37px;
	background: #000;
	width: 40px;
	height: 40px;
	border-radius: 100%;
	/* opacity: 0; */
	left: auto;
}
.slick-arrow.slick-prev {
	right: 67px;
}
.footerBot .containerLanding {
	max-width: 95%;
	width: 100%;
}
.teamSlider .slick-arrow.slick-next, .teamSlider .slick-arrow.slick-prev {
	opacity: 1;
	top: -40px;
  background: transparent;
}
.teamSlider .slick-arrow.slick-next a, .teamSlider .slick-arrow.slick-prev a {
  position: absolute;
  top: 0;
  left: 0;
}

.teamSlider .slick-prev:before, .teamSlider .slick-next:before{
  font-size: 40px;
}

#ourTeamMember {
	background: #ededed;
	padding: 0;
	max-width: 900px;
}

#ourTeamMember .popup-body {
	max-height: initial;
	overflow-x: visible;
}
.ourTeamMember {
	display: grid;
	grid-template-columns: 1.5fr 2fr;
	grid-column-gap:10px;
}
#ourTeamMember .popup-body {
	padding: 0;
}
.ourTeamMemberLeft {
  height: 450px;
}
.ourTeamMemberLeft img {
  border-radius: 5px 0px 0px 5px;
  width: 100%;
  height: 100%;
}
.ourTeamMemberRight {
	display: grid;
	justify-content: center;
	align-content: center;
	align-items: center;
	padding: 50px 20px;
  height: 450px;
}
.ourTeamMemberScroll {
  display: inline-block;
  width: 100%;
  height:100%;
  overflow-x: auto;
  padding:0px 50px;
}
.ourTeamMemberRight h4 {
	font-size: 27px;
	color: #fe6e00;
	margin-bottom: 8px;
}
.ourTeamMemberRight span {
	font-size: 15px;
	font-weight: normal;
	color: #8a8a8a;
	margin-bottom: 20px;
	display: inline-block;
  font-family: 'nexaregular';
}
.ourTeamMemberRight p {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 12px;
  color: #5c5c5c;
  font-family: 'nexaregular';
  line-height: 21px;
}
.ourTeamMemberRight .close.toggle {
	font-size: 0;
	margin: 10px 10px 0 0;
}
.ourTeamMemberRight .close.toggle::before {
	background: #000;
	height: 2px;
	width: 22px;
}
.ourTeamMemberRight .close.toggle::after {
	background: #000;
	height: 2px;
	width: 22px;
}

.termsBox h2 {
	margin-bottom: 40px !important;
}
.termsBoxContent h4 {
	font-size: 17px;
	margin-bottom: 13px;
}
.termsBoxContent {
	display: inline-block;
	width: 100%;
	margin-bottom: 30px;
}
.termsBoxContent p {
	font-family: 'nexaregular' !important;
	font-size: 14px !important;
}
.termsBoxContent ul {
	padding-left: 40px;
	margin: 20px 0;
	display: inline-block;
	width: 100%;
}
.termsBoxContent ul li {
	margin-bottom: 15px;
	font-weight: normal !important;
	font-family: 'nexaregular' !important;
	font-size: 14px;
}
.termsBox h2 {
	margin-bottom: 40px !important;
	font-size: 22px !important;
}
.onboarding-terms.popup.brand .termsBox p a {
	font-weight: 700;
}
/* .css-yk16xz-control .css-1pahdxg-control .css-1uccc91-singleValue {
  transform: inherit !important;
} */
.css-1uccc91-singleValue {
	top: auto !important;
}
.css-1uccc91-singleValue {
  transform: inherit !important;
}
.happySchoolCardUpperSection{
  height: 135px;
}
  /******************LANDING PAGE CSS END************************************/
  
  @media screen and (max-width: 1290px) {
    .teamSlider .slick-arrow.slick-next, .teamSlider .slick-arrow.slick-prev {
      top:-24px;
    }
    .teamSlider .slick-arrow.slick-prev {
      right: 97px;
    }
    .teamSlider .slick-arrow.slick-next {
      right:44px;
    }
    .arrowteam {
      right: 130px;
      top: 184px;
    }
    .advisory-board-one span {
      height: 300px;
    }
    /* .advisory-board-oneNew span {
      height: 100px;
    } */
    .landingSlideText h2 {
      font-size: 40px;
    }
    .landingSlideText p {
      font-size: 20px;
      margin-bottom: 45px;
    }
    .headhx1 {
      font-size: 12px;
    }
    .headhx2 {
      font-size: 30px;
    }
    .headhx3 {
      font-size: 15px;
    }
    .happy-school-one span {
      height: 80px;
      width: 80px;
    }
    .happy-school-one h3 {
      font-size: 16px;
    }
    .happy-school-text p {
      font-size: 16px;
    }
    .advisory-board-one h4 {
      font-size: 18px;
    }
    .advisory-board-oneNew h4 {
      font-size: 18px;
    }


  }
  @media screen and (max-width: 1024px) {
    .teamSlider .slick-arrow.slick-prev {
      right: 117px;
    }
    .teamSlider .slick-arrow.slick-next {
      right: 57px;
    }
    .arrowteam {
      right: 80px;
      top: 184px;
    }
  }

  @media screen and (max-width: 999px) {
    .landingSlide {
      height: 100%;
    }
    .containerLanding.containerOurTeam {
      padding-left: 50px;
    }
    .arrowteam {
      right: 60px;
      top: 84px;
    }
    .teamSlider .slick-arrow.slick-prev {
      right: 102px;
    }
    .teamSlider .slick-arrow.slick-next, .teamSlider .slick-arrow.slick-prev {
      top: -20px;
    }
    .teamSlider .slick-arrow.slick-next {
      right: 42px;
    }
    .ovelap.active {
      z-index: 1;
    }
    .school-generateTxt {
      padding-top: 40px;
      width: 100%;
    }
    .happy-school-one {
      margin-bottom: 30px;
    }
    .our-happy-school {
      margin-top: 70px;
    }
    .school-generate {
      padding: 90px 0;
      min-height: 800px;
    }
    .advisory-board {
      padding: 80px 0;
    }
    .teamSlider {
      width: 100%;
      position: relative;
      margin: 0;
    }
    .footerTop form {
      margin-top: 30px;
    }

  }

  @media screen and (max-width:768px) {
    .school-generate {
      min-height: 500px;
    }
    .arrowteam a img {
      max-width: 40px;
    }
  }

@media screen and (max-width: 600px) {
  .happySchoolCardUpperSection{
    height: auto;
  }
  .statsContainer{
    width: 42%;
  }
  .happySchoolCard{
    width: 90%;
  }
  .ourTeamMemberRight .close.toggle::before {
    background: white !important;
  }
  .ourTeamMemberRight .close.toggle::after {
    background: white !important;
  }
  .youtubeVideoTitle{
    margin: 0 !important;
  }
  .videoContainer{
    margin: 0 5%
  }
  .addEventHeader{
    display: none;
  }
  .addEventMargin{
    margin-bottom: 20px;
  }
  .showMob{
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #444;
  }
  .mobileView{
    display: flex;
    justify-content: center;
  }
  .mobileView i{
    font-size: 22px;
    margin-top: 0
  }
  .custom-bar {
    background: #fff;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
    width:100%;
    overflow-y: hidden;
    white-space: nowrap;
    position: relative;
    display: block;
    margin: 0;
  }
  .our-partnerImg {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 30px;
  }
  .containerLanding.containerOurTeam {
    padding-left: 15px;
  }
  .arrowteam {
    right: 30px;
    top: 30px;
  }
  .teamSlider .slick-arrow.slick-next, .teamSlider .slick-arrow.slick-prev {
    top: -18px;
  }
  .teamSlider .slick-arrow.slick-prev {
    right: 70px;
  }
  .teamSlider .slick-arrow.slick-next {
    right: 26px;
  }
  .custom-bar .col-xl-2.col-lg-2.col-md-3.col-xs-6,  .custom-bar .col-xl-4.col-lg-4.col-md-6.col-xs-12,  .custom-bar .col-xl-3.col-lg-3.col-md-6.col-xs-12 {
    display: inline-block;
  }
  .headerLanding .logo {
    width: 70px;
  }
  .imgAboutSchool {
    margin-bottom: 40px;
  }
  .headerLanding {
    padding: 7px 15px;
    padding-bottom: 0;
    border: 0;
    box-shadow: none;
  }
  .footerBot {
    padding: 25px 0;
  }
  .school-generateImg img {
    height: 300px;
  }
  .our-partner-logo {
    text-align: center;
  }
  .advisory-board-one span {
    height: 350px;
  }
  /* .advisory-board-oneNew span {
    height: 100px;
  } */
  .headerLanding .menu-icon {
    padding: 21px 5px;
  }
  .landingSlideText h2 {
    font-size: 20px;
  }
  .landingSlideText h2 br {
    display: none;
  }
  .landingSlideText p {
    font-size: 14px;
    width:100%;
    margin: 0 auto 35px;
  }
  .landingSlideText p br {
    display: none;
  }
  .landingSlide {
    height: 400px;
  }
  .landingSlideText {
    width: 80%;
  }
  .landingSlideBtn .btn {
    margin-bottom: 20px;
  }
  .school-generate {
    padding: 90px 0;
    min-height: inherit;
  }

  .headhx2 {
    font-size: 20px;
  }
  .headhx3 {
    font-size: 14px;
  }
  .happy-school-one {
    padding: 30px;
  }
  .happy-school-one span {
    height: 60px;
    width: 60px;
  }
  .advisory-board {
    padding: 50px 0;
  }
  .advisory-board-one h4 {
    font-size: 16px;
  }
  .advisory-board-oneNew h4 {
    font-size: 16px;
  }
  ul.listAdditional li img {
    width: 17px;
  }
  ul.listAdditional li {
    padding-left: 30px;
    font-size: 15px;
  }
  .arrowteam a img {
    max-width: 35px;
  }
  .footerSocial {
    display: flex;
    justify-content: start;
  }
  /* .footerLink ul li {
    white-space: normal;
  } */
  .footerLink ul li:nth-child(2), .footerLink ul li:nth-child(4), .footerLink ul li:nth-child(6) {
    display: none;
  }
  .footerBot {
    text-align: center;
  }
  .footerBot .footerLink {
    margin: 10px 0;
  }
  .footerSocial {
    display: flex;
    justify-content: center;
    margin: 15px 0;
  }
  .footerBot .footerLink {
    margin: 10px 0 20px 0;
  }
  .headerLanding li.loginBtn a.btn.btn-orange {
    margin-left: 0;
  }
  .headerLanding li a {
    padding: 10px 3px;
  }
  .headerLanding .menu-btn:checked ~ .menu {
    margin-top: 60px;
    padding-bottom: 20px;
  }
  .ourTeamMember {
    grid-template-columns: 1fr;
}
.ourTeamMemberScroll {
  padding: 0px 10px;
}
.ourTeamMemberLeft img {
  object-fit: cover;
  object-position: top;
}
.ourTeamMemberLeft {
  height: 230px;
}
.ourTeamMemberRight {height: 350px;padding: 30px 0px;}
.ourTeamMemberRight h4 {
	font-size: 20px;
}
.ourTeamMemberRight span {
	font-size: 13px;
}
.ourTeamMemberRight p {
	font-size: 13px;
}
.video-board {
  padding: 0 0;
}


  }

