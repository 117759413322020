/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 20, 2021 */



@font-face { font-family: 'nexabold'; src: url('fonts/nexabold.woff2') format('woff2'),  url('fonts/nexabold.woff') format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'nexaregular'; src: url('fonts/nexaregular.woff2') format('woff2'),  url('fonts/nexaregular.woff') format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'nexaxbold'; src: url('fonts/nexaxbold.woff2') format('woff2'),  url('fonts/nexaxbold.woff') format('woff'); font-weight: normal; font-style: normal; }
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 14, 2021 */



@font-face { font-family: 'nexa_boldregular'; src: url('fonts/nexa_bold.woff2') format('woff2'), url('fonts/nexa_bold.woff') format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'nexaheavyuploaded_file'; src: url('fonts/nexa_heavy.woff2') format('woff2'), url('fonts/nexa_heavy.woff') format('woff'); font-weight: normal; font-style: normal; }
@font-face { font-family: 'nexa_lightregular'; src: url('fonts/nexa_light.woff2') format('woff2'), url('fonts/nexa_light.woff') format('woff'); font-weight: normal; font-style: normal; }
