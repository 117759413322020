@media screen and (max-width:1370px) {
	
	.sch-add-img.ifnotline h3 p {
		width: 100%;
		display: inline-block !important;
		margin: 0 !important;
	  }
	  .sch-add-img.ifnotline h3 {
		position: relative;
	  }
	  .sch-add-img.ifnotline .add-inve.nameadd {
		position: absolute;
		right: 0;
		top: 0;
		margin: 0;
	}
	.onboarding-bar.sliderbar {
		width: 100%;
	}
	.calendar-day a.cale-today {
		padding: 5px 8px;
	  }
	  .sliderbar.updatebar.no_highlight.brandbar {
        width: 100%;
    }
	}

@media screen and (max-width:1290px) {
.sliderbar { padding: 25px 20px; width: 90%; }
.avera-anual { width: 100%; }
.backbtn { margin: 10px 0 25px 0; width: 100%; }
.rightPart.noBack .margTop{margin-top: 10px;}
.noBack .backBtn{display: none;}
.onboding { width: 100%; }
.create-top ul li {
	margin: 0 40px 30px 0;
}
.table-inventory {
	overflow: scroll;
	/* min-height: 400px; */
}


.tabbed-content.tabs-side .tabs {
	width: 160px;
	float: left;
}
.tabbed-content.tabs-side .item {
	margin-left: 150px;
}
.tabbed-content .tabs li a {
	min-height: 100px;
}
.facili-avai1 {
	min-height: 95px;
}
.tol-built {
    grid-template-columns: 220px 2fr 150px 100px;
}
.htx1 {
	font-size: 25px;
}
.imgSchool {
    right: 0;
}
.addAdmininput {
    overflow-x: auto;
}
.create-top ul li:last-child {margin-bottom: 0px;}
}
@media screen and (max-width:1199px) {
	select#units {
		width: 90px;
	}
	.loginbox {
		grid-template-columns: 1fr 1.5fr;
	  }
	  .fosbtnmain {
		  grid-template-columns: 1fr;
		  grid-column-gap: 25px;
		  margin-top: 50px;
		  grid-row-gap: 10px;
	  }
	  .login-form {
		width: 85%;
		padding: 50px 50px;
		margin: auto;
	}
	.sc-ove-list span {
		height: 54px;
		width: 54px;
	}	
	.onboarding-bar.sliderbar {
		width: 350px;
	}
	
.mnone {display: none !important;}	
.invetory-table-list {
overflow-x: scroll;
width: 100%;
white-space: nowrap;
}	
	
	
.avera-textlogo .faq-logo {
	margin-top: 0;
}
.student-imgbg {
	height: 440px;
	width: 70%;
}
.sliderbar { margin-top: 0; height: calc(100vh); position: fixed; left: -100%; top: 0; width: 350px; transition: 0.5s; padding: 25px; z-index: 6; padding-top: 10px; }
.rightPart { padding: 10px 10px; height: auto; margin: 0; }
.hummob img {
	width: 22px;
	float: left;
	height: 22px;
	margin: 0;
	display: block;
}
.hummob {
    float: left;
    margin: -2px 15px 10px 0;
    transition: 1s;
}
.backbtn {
    margin: 0px 0 5px 0;
    width: 100%;
}
.sliderbar.no_highlight { left: 0; }
.hummob.no_highlight { position: fixed; left: 350px; background: #fff; padding: 6px; top: -1px; border-radius: 0px 10px 0px 0px; width: 40px; height: 40px; z-index: 6; margin: 0; }
.hummob.no_highlight img { display: none; }
.hummob.no_highlight::before { content: ""; position: absolute; top: 17px; left: 9px; height: 4px; width: 19px; background: #070707; transform: rotate(45deg); }
.hummob.no_highlight::after { content: ""; position: absolute; top: 17px; right: 12px; height: 4px; width: 19px; background: #070707; transform: rotate(-45deg); }
.ovelap.active { background: rgba(0, 0, 0, 0.5); position: fixed; left: 0; top: 0; width: 100%; height: 100%; z-index: 1; }
.w80 { width: 100%; margin: 0; padding: inherit; }
.fixhight {
	height: auto;
	padding: 10px 0;
	overflow: visible;
}
.sliderbar.updatebar.no_highlight {
	left: 0;
	width: 350px !important;
}
.tol-built {
	grid-template-columns: 170px 2fr 150px 100px;
}
.ovelap.active {
	z-index: 1;
}
#side_img::before { display: none; }
.avera-anual { width: 100%; padding: 0px 15px; }
.onboding .htx1.mb40 { margin: 15px 0; }
#image-gallery { grid-template-columns: repeat(5, 1fr); }
.searchinput {
    width: 210px;
}
.create-top ul li {
    margin: 0 15px 0px 0;
}
.create-top ul li:last-child {
    margin-bottom: 0;
}
.stepul li a {
    font-size: 11px;
    padding-left: 32px;
}
.stepul li a span {
    width: 22px;
    height: 22px;
}
.htx1 {
    font-size: 20px;
}
.stepul {
    margin: 0px 0 40px 0;
}

}
@media screen and (max-width:999px) {
	.student-imgbg {
		height: 440px;
		width: 70%;
		background-size: 65%;
	}
	ul.tabsinventory {
		width: 100%;
		margin: 20px 0 5px 0;
	}
	.faq-view ul.tabsinventory {
		width: calc(100% - 270px);
	}
	.mypro-disable .forminpt {
		z-index: 0;
	}
	.forminpt.disabled {
		z-index: 0;
	}
	#amenitiesModal {
		width: 90%;
	}

	/* #galleryModal {
		width: 90%;
	} */
	/* .wisr-react-select__single-value {
		top: 11px !important;
	} */
	.inventory-overTabs01L img {
		height: auto;
	}
	.inventory-overTabs02 svg {
		width: 100%;
	}
	.addAdmininput table.table {
		white-space: nowrap;
	}
	.tableInner .table td span {
		white-space: nowrap;
	}
	
	.table-inventory table.table {
		white-space: nowrap;
	}
	.table-inventory table.table table.table {
		white-space: normal;
	}
	.table-container.table-inventory .table tbody tr td svg {
		margin: 0 15px 0 0;
		float: none;
	}
	.loginbox.fospage .login-form {
		width: 90%;
		margin: auto;
	}
	.table-container.table-inventory .table tbody tr td.placementSize .forminpt input {
		width: 40px;
	}
	.inventorySearch {
		top: -50px;
	}
	select#units {
		width: 90px;
		padding-right: 25px;
	}
	.loginbox {
		grid-template-columns: 1fr 1.5fr;
	}
	.login-form {
		margin: auto;
		width: 75%;
		min-height: 100vh;
		display: grid;
		justify-content: center;
		align-items: center;
		align-content: center;
		height: auto;
	}
	}
@media screen and (max-width:812px) {
	.login-wave {
		display: none;
	}	

	.login-form, .signup-form {
		padding: 30px;
		justify-content: start;
align-items: start;
align-content: start;
	}
	.loginbox {
		grid-template-columns: 1fr;
		justify-content: center;
		align-items: center;
		align-content: center;
	}
	.login-form {
		align-content: start;
		margin: 45px auto 0 !important;
		width: 100%;
	}
	.tabremove img {
		width: 12px;
		height: 11px;
	}
	.remove-icon {
		width: 11px;
		height: 11px;
	}
}
@media screen and (max-width:999px) {
.student-imgbg {
    height: 440px;
    width: 70%;
    background-size: 65%;
}
}

 @media screen and (max-width:500px) {
.facili-avai1.complete .checkcom {
	width: 18px;
	height: 18px;
}
.checkbox input:checked + label::after {
    top: -2px;
}
.email-pop {
	width: 300px;
}
.email-pop .htx2 {
	font-size: 18px;
}
.email-pop .ptx2 {
	font-size: 14px;
}
.email-veri label {
	font-size: 13px;
}
.ava-brad.ava-school strong {
	justify-content: center;
	align-content: center;
	align-items: center;
}
#exitButton {
	right: 6px;
}
#exitButton::before {
	right: 8px;
	top: 3px;
}
#exitButton::after {
	right: 8px;
	top: 3px;
}
#closePopup input.btn, #closePopup a.btn {
	margin-bottom: 10px;
}

.nu-in-dic {
	grid-template-columns: 12% 70% 12% !important;
}
.bottom-error-btn {
	margin: 0;
	/* margin-top: 15px; */
}
.imgSchool {
	width: 80px;
	height: 40px;
}
#addMore .ptx2 {
	margin-bottom: 10px;
}
.onboarding-terms.popup .termsBox p {
	font-size: 14px;
}
.activies-box {
	min-height: 250px;
}
.activi-first-box .ava-brad .content {
	width: auto;
	float: right;
}
/* .ava-brad.ava-school strong a {
	margin: 6px 0 0 6px;
} */
.onboding .htx1.mb40 {
	margin: 0;
}
.htx2 {
	font-size: 16px;
}
.editbtn {
	font-size: 12px;
}
.sch-add-img #image-gallery .image {
	width: 60px;
	margin-right: 10px;
	float: left;
}
.sch-add-img #image-gallery {
	display: inline-block;
}
.imgSchool {
	right: 0;
	top: -10px;
}
.moutableview {
	margin-top: 15px;
	overflow-x: auto;
}
.onboarding-terms.popup .table {
	white-space: nowrap;
}
.newMvp-pages-css .facili-avai {
	margin-top: 20px;
}
.facili-avai .class-grade-mid {
	padding: 20px 10px;
}
.newMvp-pages-css .class-grade-mid .nu-in-dic {
	grid-template-columns: 15% 60% 15% !important;
}
.rc-slider-handle {
	margin: -5px 0 0 10px;
}
.fosbtnmain {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 15px;
}
.avgAnnualFeeMobile {
	width: 100%;
}
.avgAnnualFeeMobile .static-value {
	top: 16px;
}
.facili-avai1 {
	margin: 0 0 10px 0;
}
.loginbox {
	height: 100vh;
}
.popup {
	width: 95%;
}

.login-form {
	margin: auto;
	width: 90%;
	padding: 0;
}
.wavebot {
    max-width: 190px;
}
.sliderbar ul {
    height: calc(100vh - 240px);
}
.popup-body .facili-avai-in .facili-avai1 {
    min-height: 80px;
}
.popup-body .facili-avai-in .facili-avai1 h3 {
    font-size: 13px;
}
.popup {
	width: 90% !important;
	padding: 15px !important;
}

.tabblank { background: #F3F3F3; }
.tabbed-content.tabs-side .item { display: none;  margin-left: 0;border-radius: 0; }
.school-specify { margin-top: 15px; }
#image-gallery { grid-template-columns: repeat(3, 1fr); }
.img-wrapper { margin-top: 5px; }
.item.active .item-content { padding: 0; }
.facili-avai-in { grid-template-columns: 1fr 1fr; }
.class-grade-top ul {
	padding:0px;
	grid-column-gap: 0;
	white-space: nowrap;
	overflow-x: auto;
	-webkit-overflow-scrolling: touch;
	width: 100%;
}
.sele-check .dropdown dd ul, .sele-check .dropdown1 dd ul {
	top: 0px;
	width: 100%;
}

#side_img {
	display: none;
}
.item::before {
	padding: 15px 10px;border-radius: 0; 
}
.class-grade-top {
	display: inline-grid;
}
.class-grade-top ul li {
	/* width: 33%; */
	/* display: inline-flex; */
	/* overflow: hidden; */
	white-space: initial;
}
.class-grade-top ul li a { padding: 5px 18px; }
.thank-sign { width: 90%; }
.thank-sign p { margin-bottom: 25px; font-size: 14px; }
.w80 { width: 100%; margin: 20px 0 0 0; padding: 0; height: auto; }
.forminpt { width: 100%; }

.backbtn { margin: 6px 0 20px 0; }
.type-sch { display: grid; margin: 6px 0 0 0; grid-template-columns: 1fr 1fr; grid-row-gap: 10px; }
.sch-add-img1 { display: grid; grid-template-columns: repeat(3, 1fr); grid-column-gap: 10px; margin-top: 35px; width: 100%; grid-row-gap: 5px; }
.stutablemain {
	display: inline-grid;
}
.stuta-overflow {
	white-space: nowrap;
	overflow-x: auto;
	width: 100%;
	/* display: inline-table; */
}
.stutable {
	width: 550px;
}
.tabset > label { padding: 10px 12px; font-size: 14px; }
.scho-spec { border-radius: 0; border: 1px solid #ddd; border-top: 0; }
.tol-built {
	grid-template-columns: 1fr;
	grid-column-gap: 15px;
	grid-row-gap: 25px;
	margin-bottom: 20px;
}
.tol-built span.valError {
	position: absolute;
	bottom: -25px;
	font-size: 13px;
	font-weight: normal;
}
.tol-built span.valError img {margin: -3px 4px 0 0;}
.valError img {
	width: 12px;
	height: 12px;
	margin: -1px 4px 0 0;
}
.valError {
	font-size: 13px;
}
.checkbox { margin-top: 20px; }
.facili-avai { margin-top: 30px; }
.htx1 { font-size: 20px; }
.htx1 img { margin: -5px 8px 0 0; width: 28px; }
.mb40 { margin-bottom: 30px; }
.facili-avai1 { min-height: 80px; }
.facili-avai1 h3 { font-size: 14px; }
.class-grade-mid .nu-in-dic { grid-template-columns: 1fr 1fr 1fr; grid-column-gap: 13px; width: 100%; padding: 0; margin-top: 6px; }
.nu-in-dic a { height: 18px; }
.avefee { width: 100%; margin-top: 6px; }
.student-img { margin-top: 20px; width: 100%; }
.mt-40 { margin-top: 10px; }
.activi-first-box { padding: 20px 0 10px 0; }
.activi-first-box .ava-brad { margin-top: 10px;display: inline-block; width: 100%; float: left; }
.content { margin-top: -4px; }
.thank-sign img { margin-bottom: 20px; width: 100px; }
.thank-sign h4 { font-size: 18px; margin-bottom: 15px; }
.poximg { max-width: 190px; }
.btn {
	/* padding: 10px; */
	width: 100%;
	margin-left: 0;
}
.thank-sign .btn { width: 90%; font-size: 15px; }
.sele-calss1 { padding: 10px; }
.cust-btn .btn { font-size: 14px; }
.activies-box { margin-bottom: 15px; }
.sele-check .dropdown a, .sele-check .dropdown a:visited { width: 100%; max-width: 100%; min-width: 100%; margin-top: 7px; }
.sele-check .dropdown dd, .sele-check .dropdown dt { margin: 0px; padding: 0px; float: left; width: 100%; }
.sliderbar { left: -200%;width: 100%; }
.logo { width: 90%; }
.hummob.no_highlight { background: #000; width: 40px; height: 40px; z-index: 2; right: 0; left: auto; border-radius: 0; }
.hummob.no_highlight::before, .hummob.no_highlight::after { background: #fff; }
.sele-check .dropdown1 dd, .sele-check .dropdown1 dt { float: left; width: 100%; margin-top: 6px; }
.sele-check .dropdown1 a, .sele-check .dropdown1 a:visited { min-width: 100%; }
}
