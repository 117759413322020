/* @media only screen and (max-width: 390px) { */

.crButtons {
  width: auto !important;
  min-width: none !important;
  height: 35px;
  opacity: 1;
  line-height: 5px;
  padding: auto !important;
  margin: 0 10px 0 0;
}

.cr td{
  border: none !important;
}
.cr .filtercheckbox .checkbox {
  margin-bottom: 10px;
  width: 100% !important;
  display: grid;
}

.campaign-reporting-btn-approve {
  background: transparent url('../../../../assets/images/approve.svg') 15% 50% no-repeat padding-box;
  border: 2px solid #2EC193;

  background-color: #2EC1931A;

}

.campaign-reporting-btn-reject {
  background: transparent url('../../../../assets/images/reject.svg') 20% 50% no-repeat padding-box;
  background-color: #FE6E001A;
  border: 2px solid #FE6E00;

}

.campaign-reporting-btn-delete {
  background: transparent url('../../../../assets/images/Vector.svg') 20% 50% no-repeat padding-box;
  border: 2px solid #FF4A4A;
  background-color: #FF4A4A1A;

}

.crCheckBox .checkbox label::before{
  background-color: #ffffff;
}

.crImageView{
  background: rgba(128, 128, 128, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 5px;
  width: 100%;
  height: 65% !important;
}

#overlay.crImageView img{
  margin: 0;
  object-fit: contain;
  width: 90% !important;
  height: auto !important;
  /* padding: 5% !important; */
  background-color: transparent !important;
}

.crInnerTablePadding{
  padding: 0 30px 20px 30px !important;
}

.crCustomInput{
  border: 1px solid #ddd !important;
}
.crCustomInput:focus{
  border-color: #070707 !important;
  color: #070707 !important;
}
.crFosDetailTabMobileView{
  display: none;
}

@media only screen and (max-width: 600px) and (min-width: 320px) {

  .crFosDetailTab{
    display: none;
  }
  .crFosDetailTabMobileView{
    display: flex;
  }
  body {
    /* background-color: green!important; */
    font-family: "nexabold", sans-serif;
  }

  .clear {
    clear: both;
    float: none;
  }

  .cl-row {
    margin-right: -15px;
    margin-left: -15px;
  }
  /* .crImg{
    float: right !important;
  } */
  .col-xs-10.crImg img {
    width: 38px;
    height: 38px;
    margin: 0 5px 5px 0;
  }
  .rejectedBorder{
    border: dashed #FF4A4A !important;
  }
  .approvedBorder{
    border: dashed #2EC193 !important;
  }
  .flexBoxImg{
    display: flex !important;
    flex-direction: row-reverse !important;
  }
  /* .uploadImgDesign{
    float: right !important;
  } */

  /* input,
  select,
  textarea {
    border: 1px solid #ddd !important;
    height: 45px !important;
    border-radius: 5px !important;
    padding: 5px 10px;
    margin-top: 20px !important;
    font-size: 14px !important;
    width: 100% !important;
    color: #171717 !important;
    font-family: "nexaregular" !important;
    font-weight: 400 !important;
    outline: none !important;
  } */
  .cr input, .cr select, .cr textarea{
    border: 1px solid #ddd !important;
    height: 45px !important;
    border-radius: 5px !important;
    /* padding: 5px 10px; */
    margin-top: 20px !important;
    font-size: 14px !important;
    width: 100% !important;
    color: #171717 !important;
    font-family: "nexaregular" !important;
    font-weight: 400 !important;
    outline: none !important;
  }

  /********************** bootstrap ******************************/

  /*     
      .cl-row {
        margin-right: -15px;
        margin-left: -15px;
      } */
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }

  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left;
  }

  .col-xs-12,
  .col-md-12,
  .col-sm-12 {
    width: 100%;
  }

  .col-xs-11,
  .col-md-11,
  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-xs-10,
  .col-md-10,
  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-xs-9,
  .col-md-9,
  .col-sm-9 {
    width: 75%;
  }

  .col-xs-8,
  .col-md-8,
  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-xs-7,
  .col-md-7,
  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-xs-6,
  .col-md-6,
  .col-sm-6 {
    width: 50%;
  }

  .col-xs-5,
  .col-md-5,
  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-xs-4,
  .col-md-4,
  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-xs-3,
  .col-md-3,
  .col-sm-3 {
    width: 25%;
  }

  .col-xs-2,
  .col-md-2,
  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-xs-1,
  .col-md-1,
  .col-sm-1 {
    width: 8.33333333%;
  }

  .panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  }

  .panel-body {
    padding: 15px;
  }

  .panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .panel-heading>.dropdown .dropdown-toggle {
    color: inherit;
  }

  .panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 16px;
    color: inherit;
  }

  .panel-set-list {
    margin-top: 5px;
  }

  .panel-title strong {
    font-family: "nexaxbold", sans-serif;
    font-size: 18px;
  }

  .panel-title>a,
  .panel-title>small,
  .panel-title>.small,
  .panel-title>small>a,
  .panel-title>.small>a {
    color: inherit;
  }

  .panel-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .panel>.list-group,
  .panel>.panel-collapse>.list-group {
    margin-bottom: 0;
  }

  .panel>.list-group .list-group-item,
  .panel>.panel-collapse>.list-group .list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }

  .panel>.list-group:first-child .list-group-item:first-child,
  .panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
    border-top: 0;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .panel>.list-group:last-child .list-group-item:last-child,
  .panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .panel-heading+.list-group .list-group-item:first-child {
    border-top-width: 0;
  }

  .list-group+.panel-footer {
    border-top-width: 0;
  }

  .panel>.table,
  .panel>.table-responsive>.table,
  .panel>.panel-collapse>.table {
    margin-bottom: 0;
  }

  .panel>.table caption,
  .panel>.table-responsive>.table caption,
  .panel>.panel-collapse>.table caption {
    padding-right: 15px;
    padding-left: 15px;
  }

  .panel>.table:first-child,
  .panel>.table-responsive:first-child>.table:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .panel>.table:first-child>thead:first-child>tr:first-child,
  .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
  .panel>.table:first-child>tbody:first-child>tr:first-child,
  .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  .panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
  .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
  .panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
  .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
  .panel>.table:first-child>thead:first-child>tr:first-child th:first-child,
  .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
  .panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
  .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }

  .panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
  .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
  .panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
  .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
  .panel>.table:first-child>thead:first-child>tr:first-child th:last-child,
  .panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
  .panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
  .panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }

  .panel>.table:last-child,
  .panel>.table-responsive:last-child>.table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .panel>.table:last-child>tbody:last-child>tr:last-child,
  .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
  .panel>.table:last-child>tfoot:last-child>tr:last-child,
  .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  .panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
  .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
  .panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
  .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
  .panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
  .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
  .panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
  .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
  }

  .panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
  .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
  .panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
  .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
  .panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
  .panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
  .panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
  .panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
  }

  .panel>.panel-body+.table,
  .panel>.panel-body+.table-responsive,
  .panel>.table+.panel-body,
  .panel>.table-responsive+.panel-body {
    border-top: 1px solid #ddd;
  }

  .panel>.table>tbody:first-child>tr:first-child th,
  .panel>.table>tbody:first-child>tr:first-child td {
    border-top: 0;
  }

  .panel>.table-bordered,
  .panel>.table-responsive>.table-bordered {
    border: 0;
  }

  .panel>.table-bordered>thead>tr>th:first-child,
  .panel>.table-responsive>.table-bordered>thead>tr>th:first-child,
  .panel>.table-bordered>tbody>tr>th:first-child,
  .panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
  .panel>.table-bordered>tfoot>tr>th:first-child,
  .panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
  .panel>.table-bordered>thead>tr>td:first-child,
  .panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
  .panel>.table-bordered>tbody>tr>td:first-child,
  .panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
  .panel>.table-bordered>tfoot>tr>td:first-child,
  .panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child {
    border-left: 0;
  }

  .panel>.table-bordered>thead>tr>th:last-child,
  .panel>.table-responsive>.table-bordered>thead>tr>th:last-child,
  .panel>.table-bordered>tbody>tr>th:last-child,
  .panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
  .panel>.table-bordered>tfoot>tr>th:last-child,
  .panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
  .panel>.table-bordered>thead>tr>td:last-child,
  .panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
  .panel>.table-bordered>tbody>tr>td:last-child,
  .panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
  .panel>.table-bordered>tfoot>tr>td:last-child,
  .panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child {
    border-right: 0;
  }

  .panel>.table-bordered>thead>tr:first-child>td,
  .panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
  .panel>.table-bordered>tbody>tr:first-child>td,
  .panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
  .panel>.table-bordered>thead>tr:first-child>th,
  .panel>.table-responsive>.table-bordered>thead>tr:first-child>th,
  .panel>.table-bordered>tbody>tr:first-child>th,
  .panel>.table-responsive>.table-bordered>tbody>tr:first-child>th {
    border-bottom: 0;
  }

  .panel>.table-bordered>tbody>tr:last-child>td,
  .panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
  .panel>.table-bordered>tfoot>tr:last-child>td,
  .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
  .panel>.table-bordered>tbody>tr:last-child>th,
  .panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
  .panel>.table-bordered>tfoot>tr:last-child>th,
  .panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0;
  }

  .panel>.table-responsive {
    margin-bottom: 0;
    border: 0;
  }

  .panel-group {
    margin-bottom: 20px;
  }

  .panel-group .panel {
    margin-bottom: 0;
    border-radius: 4px;
  }

  .panel-group .panel+.panel {
    margin-top: 5px;
  }

  .panel-group .panel-heading {
    border-bottom: 0;
  }

  .panel-group .panel-heading+.panel-collapse>.panel-body,
  .panel-group .panel-heading+.panel-collapse>.list-group {
    border-top: 1px solid #ddd;
  }

  .panel-group .panel-footer {
    border-top: 0;
  }

  .panel-group .panel-footer+.panel-collapse .panel-body {
    border-bottom: 1px solid #ddd;
  }

  .panel-default {
    border-color: #ddd;
  }

  .panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
  }

  .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ddd;
  }

  .panel-default>.panel-heading .badge {
    color: #f5f5f5;
    background-color: #333;
  }

  .panel-default>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ddd;
  }

  .panel-primary {
    border-color: #337ab7;
  }

  .panel-primary>.panel-heading {
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
  }

  .panel-primary>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #337ab7;
  }

  .panel-primary>.panel-heading .badge {
    color: #337ab7;
    background-color: #fff;
  }

  .panel-primary>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #337ab7;
  }

  .panel-success {
    border-color: #d6e9c6;
  }

  .panel-success>.panel-heading {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }

  .panel-success>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #d6e9c6;
  }

  .panel-success>.panel-heading .badge {
    color: #dff0d8;
    background-color: #3c763d;
  }

  .panel-success>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #d6e9c6;
  }

  .panel-info {
    border-color: #bce8f1;
  }

  .panel-info>.panel-heading {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }

  .panel-info>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #bce8f1;
  }

  .panel-info>.panel-heading .badge {
    color: #d9edf7;
    background-color: #31708f;
  }

  .panel-info>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #bce8f1;
  }

  .panel-warning {
    border-color: #faebcc;
  }

  .panel-warning>.panel-heading {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
  }

  .panel-warning>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #faebcc;
  }

  .panel-warning>.panel-heading .badge {
    color: #fcf8e3;
    background-color: #8a6d3b;
  }

  .panel-warning>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #faebcc;
  }

  .panel-danger {
    border-color: #ebccd1;
  }

  .panel-danger>.panel-heading {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  .panel-danger>.panel-heading+.panel-collapse>.panel-body {
    border-top-color: #ebccd1;
  }

  .panel-danger>.panel-heading .badge {
    color: #f2dede;
    background-color: #a94442;
  }

  .panel-danger>.panel-footer+.panel-collapse>.panel-body {
    border-bottom-color: #ebccd1;
  }

  /********************** bootstrap end **********************/



  .campaignList {
    color: red;
    margin-top: 20px !important;
    /* margin-bottom:100px!important; */
  }

  .searchinput-fos {
    width: 45px !important;
    height: 40px;
  }

  .searchinput-backendAdmin {
    width: 150px !important;
    float: left;
    height: 40px;
  }

  button.close-icon.serachClose {
    position: absolute;
    right: 13px;
    top: 8px;
    z-index: 1;
    line-height: normal;
  }


  /* .searchinput {
        margin-bottom:11px!important;
        width: 30px!important;
        height: 30px!important;
    } */

  /* .searchinput img{
        position: absolute;
  left: 8px;
  top: 10px;
  width: 10px;
  pointer-events: none;
  height: 10px;
    } */

  /* .logo-header{
        
        width: 100%;
    height: 55px;
    background-color: #FE6E02;
    
    margin-top: -1px;
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    justify-content: center;
    } */

  .logos {
    display: inline-block;
    vertical-align: middle;
  }

  .logos .wisrLogo {
    margin-left: 5px;
    margin-top: -4px
  }

  /* .logos .maskgrouplogo{
        padding-right: 4px;
    } */

  .heading {
    margin-top: 20px;
  }

  .headTitle {
    display: inline-block;
    vertical-align: middle;
  }

  .headTitle h2 {
    font-family: "nexaxbold", sans-serif;
    font-weight: 900;
    font-size: 23px;
    margin-top: 9px;
    /* margin-left: -15px; */
  }

  .h3 {
    color: #4A4A4A;
    font-family: "nexaregular";
    font-size: 16px;
  }

  .searchBox {
    width: 31px !important;
    height: 34px !important;
    /* background-color: #ffffff; */
    border: 2px solid #E9E9E9;
    border-radius: 4px !important;
    /* margin: auto; */
    display: block !important;
    margin-right: -12px !important
  }

  .searchBoxContainer {
    float: right;
    /* margin-right: 12px; */
  }

  .headTitleContainer {
    margin-left: 16px;
    padding-bottom: 10px;
  }

  .searchBox .searchIcon {
    padding: 10px;
  }

  .back-arrow-logo {
    margin-left: 10px;
  }

  .back-arrow {
    padding-left: 10px
  }

  /* Card Box Css starts */

  .panel-title {
    position: relative;
    font-size: 20px !important;
  }

  /* .panel-title p {
  font-size: 12px!important;
} */

  panel-title-pg {
    font-size: 12px !important;
  }

  .panel-title-school::after {
    content: "" !important;
    color: #333;
    top: -2px;
    right: 0px;
    position: absolute;
    font-family: "FontAwesome";
  }

  /* .panel-title::after {
    content: "\f107";
    color: #333;
    top: -2px;
    right: 0px;
    position: absolute;
    font-family: "FontAwesome"
  } */

  .panel-title[aria-expanded="true"]::after {
    content: "\f106";
  }

  /*
   * Added 12-27-20 to showcase full title clickthrough
   */

  .panel-heading-full.panel-heading {
    padding: 0;
  }

  .panel-heading-full .panel-title {
    padding: 10px 15px;
  }

  .panel-heading-full .panel-title::after {
    top: 10px;
    right: 15px;
  }

  .panel-heading {
    padding: 15px 15px !important;
  }

  /* Card Box Css ends */

  .tag {
    background-color: #FFFCE1;
    color: #B4A51F;
    padding: 3px;
    font-size: 14px;
    /* position: absolute;
    top: 0; 
    margin-left: 5px; */
  }

  .mt10 {
    margin-top: 10px;
  }


  /* Panel Css Starts  */

  .big-box {
    /* background-color: #10BCFF; */
    color: white;
    /* text-align: center; */
    /* margin: 2px; */
    font-size: 1.5em;
    height: auto;
  }

  .mini-box {
    background-color: #10BCFF;
    color: white;
    /* text-align: center; */
    margin: 2px;
    font-size: 1.5em;
  }

  /* .big-box {
    height: 65px;
    line-height: 65px;
} */
  .mini-box {
    height: auto;
    /* line-height: 30px; */
  }

  /* Panel Css ends  */


  /* .divPadding{
    padding-left: 0px;
    padding-right: 0px;
} */

  .firstImagePadding {
    padding-left: 10px;
    padding-right: 5px;
  }

  .fl {
    float: left;
    width: 100% !important;
  }

  .fr {
    float: right;
  }

  /* .padding-0{
    padding-right:0;
    padding-left:0;
} */

  .column {
    display: inline-block;
    width: 50%;
    padding: 2px
  }

  /* Clearfix (clear floats) */
  .rw::after {
    content: "";
    clear: both;
    display: table;
  }



  .col-sm-10 {
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 16px
  }

  /* Extra css starts  */

  .themed-grid-col {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    /* background-color: rgba(86, 61, 124, .15);
    border: 1px solid rgba(86, 61, 124, .2); */
  }

  .panel-group .panel-default {
    border: none !important;
  }


  .createSetButton {
    background: white;
    color: #FE6E00;
    /* border: 1px solid #FE6E00; */
    font-size: 20px !important;
    border: solid 2px #FE6E02 !important;
    width: auto !important;
    position: fixed;
    bottom: 60px;
    right: 24px;
  }

  .invButton {
    color: #FFFFFF;
    /* border: 1px solid #FE6E00; */
    font-size: 20px !important;
    border: solid 2px #FE6E02 !important;
    width: auto !important;
    /* 
    bottom: 60px; */
    position: fixed;
    bottom: 190px;
    right: 15px;
  }

  .eventButton {
    color: #FFFFFF;
    font-size: 20px !important;
    border: solid 2px #FE6E02 !important;
    width: auto !important;
    position: fixed;
    bottom: 115px;
    right: 15px;
  }
  .invButtonBottom{
    bottom: 115px;
  }

  .closeButton {
    background: transparent;
    color: #FE6E02;
    font-size: 20px !important;
    border: solid 2px #FE6E02 !important;
    width: auto !important;
    position: fixed;
    bottom: 39px;
    right: 15px;
  }


  .campaignDescription {
    font-size: 12px;
  }

  .text-head {
    font-size: 12px !important;
    color: #8A8A8A !important;
  }

  .text-head-bold {
    font-size: 16px !important;
    font-family: "nexaxbold";
  }

  .status-gray {
    color: #8A8A8A;
  }

  .title-text-head {
    font-size: 18px !important;
    font-family: 'nexabold' !important;
  }

  .title-text-head span {
    font-family: "nexaxbold";
    font-size: 16px;
  }

  .title-text-head .tag {
    font-family: "nexaxbold";
    font-size: 12px;
  }

  .status-orange {
    color: #FFAC4A;
  }

  .status-green {
    color: #2EC193;
  }

  .status-red {
    color: #FF4A4A;
  }

  .camp-list-hr {
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    border: 0 !important;
    border-top: 1px solid #E9E9E9 !important;
  }

  .ws-container {
    padding: 10px;
  }

  .img {
    max-width: none !important;
    /* max-height: none */
  }

  /* .sc-list{
  position: relative; 
    min-height: 1px;
    margin-right: -8px;
} */

  .no-margin {
    padding-left: 0;
    padding-right: 5px;
  }

  .s-bg {
    background-color: #FFFFFF;
    /* padding-left: 10px;
  padding-right: 10px; */
    padding: 10px;
    border: dashed #CCCCDD;
    margin-top: 5px;
    /* border-width: 3px; */
  }

  /* Extra css ends  */

  /* Header Flex Container Starts */

  .header-flex-container {
    display: flex !important;
    background-color: #FE6E02;
    justify-content: space-between;
    /* align-content: flex-start; */
    align-items: center;
    height: 55px;

  }

  .header-flex-container .wisrLogo {
    margin-right: auto;
    padding-left: 8px;
  }

  .title-flex-container {
    display: flex !important;
    justify-content: space-between;
    flex-wrap: wrap;
  }


  label.retake {
    width: 100% !important;
    height: auto !important;
    padding: 15px 20px !important;
    font-size: 16px !important;
    font-family: nexaxbold !important;
    /* margin-bottom: 0px !important; */
  }

  #overlay.imageView {
    background: #000000;
    width: 100%;
    height: 55%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    -webkit-user-select: none;
    user-select: none;
    border-radius: 5px;
  }

  /* Header Flex Container Ends */

  /* Media Query Ends */
}