@charset "utf-8";

/* CSS Document */
body {
	font-family: 'nexaregular', sans-serif;
	padding: 0px;
	margin: 0px;
	box-sizing: border-box;
	font-size: 16px;
	background: #fff;
}

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

ul,
ol {
	list-style: none;
	margin: 0px;
	padding: 0px;
}

a {
	text-decoration: none;
	color: #3e8bff;
}

.container {
	width: 100%;
	max-width: 100%;
}

.mb10 {
	margin-bottom: 10px !important;
}

.mt-20 {
	margin-top: 20px;
}

.mb-30 {
	margin-bottom: 30px;
}

.b0 {
	border: 0 !important;
}

::-webkit-scrollbar {
	width: 5px;
	height: 5px;
}

.clearfix {
	display: block;
	content: "";
	clear: both;
}

.mb0 {
	margin-bottom: 0 !important;
}

.ptx2 {
	color: #8A8A8A;
	font-size: 15px;
	font-weight: normal;
}

.ptx3 {
	color: #070707;
	font-size: 15px;
}

::-webkit-scrollbar-track {
	background: #f8f8f8;
}

::-webkit-scrollbar-thumb {
	background: rgba(62, 66, 94, 0.25);
	border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
	background: rgba(62, 66, 94, 0.35);
}

img {
	max-width: 100%;
}

.valError {
	font-size: 14px;
	font-weight: normal;
	display: block;
	margin-top: 7px;
	color: #ff4a4a;
}

.valError img {
	width: 14px;
	height: 14px;
	float: left;
	margin: -1px 4px 0 0;
}

.onFocus {
	border-color: #ffcbcb;
}

input,
select {
	border: 1px solid #ddd;
	height: 45px;
	border-radius: 5px;
	padding: 5px 10px;
	font-size: 14px;
	width: 100%;
	color: #171717;
	font-family: 'nexaxbold', sans-serif;
	font-weight: normal;
	outline: none;
}

input:focus,
select:focus,
textarea:focus {
	border-color: #070707;
	color: #070707;
	/* border-radius: 5px; */
}

.btn:focus {
	border: none;
	border: 0px;
	color: #fff;
}

.btn-outline:focus {
	border-color: #ff9406;
	background: #ff9406;
	color: #fff;
}

select {
	background: url(../../images/down-arrow.png) 98% 44% no-repeat #fff;
	background-size: 12px;
	-webkit-appearance: none;
	-moz-appearance: none;
}

.mb40 {
	margin-bottom: 40px;
}

.mb20 {
	margin-bottom: 20px;
}

.mt-10 {
	margin-top: 10px;
}

.mt-30 {
	margin-top: 30px;
}

.mt-40 {
	margin-top: 40px;
}

.htx1,
.htx2 {
	font-family: 'nexaxbold', sans-serif;
	/* font-weight: normal; */
}

.htx3 {
	font-family: 'nexabold', sans-serif;
	font-size: 18px;
}

.htx1 {
	font-size: 25px;
}

.htx2 {
	font-size: 18px;
}

.ptx {
	font-size: 13px;
	color: #8A8A8A;
	margin-top: 5px;
	font-weight: normal;
}

.ptx1 {
	font-size: 14px;
	color: #8A8A8A;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: #b9b9b9;
}

::-moz-placeholder {
	/* Firefox 19+ */
	color: #b9b9b9;
}

:-ms-input-placeholder {
	/* IE 10+ */
	color: #b9b9b9;
}

:-moz-placeholder {
	/* Firefox 18- */
	color: #b9b9b9;
}

.mandate {
	color: red;
	font-size: 21px;
	line-height: 0;
	vertical-align: sub;
	margin-left: 2px;
}

.pl {
	padding-left: 0;
}

.pr {
	padding-right: 0;
}

.btn {
	transition: 0.5s;
	width: auto;
	text-transform: uppercase;
	min-width: 130px;
	font-family: 'nexabold', sans-serif;
	cursor: pointer;
	padding: 14px 20px;
	border-radius: 5px;
	text-align: center;
	display: inline-block;
	height: 45px;
}

.btn-orange {
	background: #FE6E00;
	color: #fff;
	border: 1px solid #FE6E00;
}

.btn-orange:hover,
.btn-orange:focus {
	background: #ff9406;
	color: #fff;
	border: 1px solid #ff9406;
}

.btn-outline {
	border: 1px solid #FE6E00;
	color: #FE6E00;
	background: transparent;
}

.tab-panels .btn {
	width: 100%;
}

.btn-disabled {
	color: #fff;
	background: #fed2b1;
	border-color: #fed2b1;
	pointer-events: none;
}

/******top part step css*******/

.loginbox {
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.login-wave {
	background: #fff5e9;
	height: 100vh;
	overflow: hidden;
}

.login-wave img {
	width: 100%;
}

.login-form,
.signup-form {
	margin: 0;
	width: 100%;
	height: calc(100vh - 0px);
	overflow-x: hidden;
	padding: 50px 150px 20px 150px;
	display: grid;
	justify-content: center;
	align-items: center;
	align-content: center;
}

.forminpt {
	margin-bottom: 30px;
	position: relative;
	display: inline-block;
	width: 100%;
}

.forminpt label {
	width: 100%;
	display: inline-block;
	font-size: 15px;
	margin-bottom: 5px;
	font-weight: normal;
	color: #8A8A8A;
	font-family: 'nexaregular', sans-serif;
}

.login-form .htx1 {
	margin: 40px 0 10px 0;
}

.forminpt em {
	position: absolute;
	left: 11px;
	top: 34px;
	width: 28px;
	text-align: center;
}

.login-form .forminpt input {
	padding: 10px 10px 10px 48px;
}

.eyeshow {
	position: absolute;
	right: 12px;
	top: 35px;
}

.forgot {
	float: right;
	color: #FE6E00;
	text-transform: uppercase;
	font-size: 13px;
	margin-top: 8px;
}

.login-form .forminpt input.btn {
	width: 100%;
	text-align: center;
	min-width: 100%;
	padding: 10px 15px;
}

.login-form p strong {
	color: #070707;
}

.optbox {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: 15px;
}

.optbox input {
	padding: 10px 15px !important;
	text-align: center;
}

/* Gopal CSS start */
.login-form.bm-details-form .forminpt input {
	padding: 10px 10px 10px 10px !important;
}

/* Gopal CSS end */

.fosInactiveUser {
	background: #ffebee;
	padding: 15px;
	text-align: center;
	border-radius: 5px;
	color: #ff2c5c;
}

.fosInactiveUser h4 {
	font-weight: 500;
	font-size: 18px;
}

.fosInactiveUser p {
	font-size: 14px;
	font-weight: normal;
	font-family: 'nexaregular';
	margin-top: 5px;
}

.fosNoActivityText {
	background-color: #fff5e9;
	color: #646464;
	text-align: center;
	font-weight: 500;
	padding: 15px;
	border-radius: 5px;
}