@charset "utf-8";
/* CSS Document */
body {font-family: 'nexaxbold', sans-serif; padding: 0px; margin: 0px; box-sizing: border-box; font-size: 16px; background: #fff; }
* { margin: 0px; padding: 0px; box-sizing: border-box; }
ul, ol { list-style: none; margin: 0px; padding: 0px; }
a { text-decoration: none; color: #3e8bff; }
.container { width: 100%; max-width: 100%; }

:placeholder-shown {
  color: #d0d0d0;
  opacity: 1;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #d0d0d0;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #d0d0d0;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #d0d0d0;
}

.dnone {display: none !important;}

::-webkit-scrollbar-track {
 background: #f8f8f8;
}

::-webkit-scrollbar-thumb {
 background: rgba(62,66,94,0.25);
 border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
 background: rgba(62,66,94,0.35);
}
.btn-grey:hover, .btn-grey:focus {
	color: #070707 !important;
	background: #d0d0d0;
}
#closePopup .forminpt {
	margin-bottom: 0;
}
.valError {
  font-size: 14px;
  font-weight: normal;
  display: block;
  margin-top: 7px;
  color: #ff4a4a;
  /* float: left; */
  text-align: left;
}
.valError img { width: 14px; height: 14px; float: left; margin: -1px 4px 0 0; }
.onFocus { border-color: #ffcbcb !important; }
/* input, select { border: 1px solid #ddd; height: 45px; border-radius: 5px; padding: 5px 15px; font-size: 14px; width: 100%; color: #171717; font-family: 'nexaxbold'; font-weight: normal; outline: none; } */
/* input:not(.wisr-react-multi-select__input input), select{
  height: 45px;
} */
input, select {
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  padding: 5px 15px;
  font-size: 14px;
  width: 100%;
  color: #171717;
  font-family: 'nexaregular', sans-serif;
  font-weight: normal;
  outline: none;
  background: #fff;
  font-size: 14px;
}
input:focus, select:focus { border-color: #070707; color: #070707;/* border-radius: 5px; */ }
.btn:focus { border: none; border: 0px; color: #fff; }
.btn-outline:focus { border-color: #ff9406; background: #ff9406; color: #fff; }
.ptx2 {
  color: #8A8A8A;
  font-size: 15px;
  font-weight: normal;
  font-family: 'nexaregular';
}
select { background: url(../images/down-arrow.png) 96% 50% no-repeat #fff; background-size: 13px;-webkit-appearance: none; -moz-appearance: none;  }
.mt10 { margin-top: 10px; }
.mt20 { margin-top: 20px; }
.mt30 { margin-top: 30px; }
.mt40 { margin-top: 40px; }
.mt50 { margin-top: 50px; }

.mb10 {margin-bottom: 10px;}
.mb20 { margin-bottom: 20px; }
.mb30 {margin-bottom: 30px;}
.mb40 { margin-bottom: 40px; }
.mb50 { margin-bottom: 50px; }

.caps{text-transform: capitalize;}

.htx1, .htx2 { font-family: 'nexaxbold', sans-serif;/* font-weight: normal; */ }
.htx1 {
  font-size: 30px;
}
.htx2 { font-size:20px; }
.ptx { font-size: 13px; color: #8A8A8A; margin-top: 5px; font-weight: normal;font-family: 'nexaregular'; }
.ptx1 { font-size: 14px; color: #8A8A8A; }

.btn {
  transition: 0.5s;
  width: auto;
  text-transform: uppercase;
  min-width: 130px;
  font-family: 'nexabold', sans-serif;
  cursor: pointer;
  padding: 15px 20px;
  border-radius: 5px;
  text-align: center;
  display: inline-block;
  margin: 0 0 0 10px;
  text-align: center !important;
  height: auto;
  font-size: 13px;
}
.mandate
{
	color: red;
    font-size: 21px;
    line-height: 0;
    vertical-align: sub;
    margin-left: 2px;
}
.btn-orange { background: #FE6E00; color: #fff; border: 1px solid #FE6E00; }
.btn-orange:hover, .btn-orange:focus { background: #ff9406; color: #fff; border: 1px solid #ff9406; }
.comp-seting.btn-orange:focus, .loaderFocus.btn-orange:focus { color:transparent; }
.btn-outline { border: 1px solid #FE6E00; color: #FE6E00; background: transparent; }
.tab-panels .btn { width: 100%; }
.btn-disabled { color: #fff; background: #fed2b1; border-color: #fed2b1; pointer-events: none; }
/*onboarding sidebar start*/

.onboarding-bar.sliderbar { background: #fff; padding: 20px 20px 35px 20px; margin-top: 0; height: calc(100vh - 0px); border-right: 1px solid #E9E9E9; width: 320px; }
.onboarding-bar.sliderbar ul {
  margin: 40px 0 0 0;
  height: calc(100vh - 260px);
  overflow-x: auto;
  padding-top: 10px;
  font-family: 'nexa_boldregular', sans-serif;
}
.onboarding-bar.sliderbar ul li { margin: 0 0 45px 0; font-size: 16px; position: relative; display: inline-block; width: 100%; }
.onboarding-bar.sliderbar ul li a { color: #9c9c9c; padding-left: 45px; transition: 0.5s; text-transform: uppercase; /* font-weight: 500; */ display: inline-block; width: 100%; font-size: 14px; font-family: 'nexabold', sans-serif; cursor:default;}
.onboarding-bar.sliderbar ul li a.complete { color: #2EC193 !important; }
.onboarding-bar.sliderbar ul li a.complete span {
  background: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
  z-index: 0;
  font-size: 0;
  border: 0px solid #2EC193 !important;
  /* background-color: #2EC193 !important; */
  border-radius: 100%;
}
.onboarding-bar.sliderbar ul li a span { position: absolute; left: 0; top: -6px; width: 30px; height: 30px; border: 2px dashed #9c9c9c; border-radius: 100%; background: #fff; display: grid; justify-content: center; align-items: center; font-weight: 500; transition: 0.5s; padding: 5px 0; font-size: 15px; }
.onbodingbar.sliderbar ul li a.active { color: #2b2b2b; }
.onbodingbar.sliderbar ul li a.active span { background: #070707; color: #fff; border-color: #070707; z-index: 1; }
.onboarding-bar.sliderbar ul li a.complete span img { width: 30px; height: 30px; float: left; z-index: 0; position: relative; margin: -7px 0 0 1px; display: block !important; }
.onboarding-bar.sliderbar ul li a span img { display: none; }
.onboarding-bar.sliderbar ul li ul { margin: 10px 0 0 0; padding: 0; height: auto; }
.onboarding-bar.sliderbar ul li ul li { margin: 15px 0 0px 0; }
.onboarding-bar.sliderbar ul li ul li a { text-transform: none; font-size: 15px; font-weight: normal; font-family: 'nexaxbold', sans-serif; }
.onboarding-bar.sliderbar ul li a:hover span img, .sliderbar ul li a.active span img { display: none; }

.onboarding-bar.sliderbar ul li a.active span {
  background: #070707;
  border-color: #070707;
  color: #fff;
}

.helpText{
  font-size: 13px;
    color: #949494;
    display: inline-block;
    font-family: 'nexaregular';
}
.sliderbar.onboarding-bar .logo a {
  color: #070707;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 600;
}
.onboarding-bar.sliderbar ul li a.active  {
  color: #070707;
}
.hep { /* text-align: center; */ /* display: block; */ color: #070707; /* text-decoration: underline; */ text-transform: uppercase; font-size: 15px; font-family: 'nexabold', sans-serif; }
.wavebot {
  max-width: 250px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.rightPart {
	background: #fff;
	padding-top: 32px;
	height: calc(100vh - 10px);
	overflow-x: auto;
	padding-left: 30px;
	padding-right: 30px;
}
.avefee { width: 114px; }
.rtlText {
  text-align: right;
}
.fixhight {
	height: calc(100vh - 4px);
	padding: 20px 0;
	overflow-x: auto;
	z-index: 0;
	/* position: relative; */
}
.w80 { width: 66%;/* margin: 30px 0 0 0; *//* padding: 0 0px 0 0px; */ }
.forminpt label {
  width: 100%;
  display: inline-block;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: normal;
  color: #8A8A8A;
  font-family: 'nexaregular', sans-serif;
}
.forminpt {
  margin-bottom: 30px;
  position: relative;
}
.forminpt.disabled::before {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  content: "";
  opacity: .6;
}
.vertext { position: absolute; right: 18px; font-size: 13px; /* font-weight: normal; */ text-transform: uppercase; top: 39px; color: #FE6E00; background: transparent; cursor: pointer; font-weight: normal;}
.vertext.active { color: #FE6E00; font-weight: 600; }
.vertext img { width: 16px; height: 16px; display: none; margin: -2px 0 0 0; }
.vertext.complete img { display: block; }
.vertext.complete {
  font-size: 0;
  background: transparent;
}
.formset { border: 1px solid #E9E9E9; height: 45px; border-radius: 5px; padding: 0; font-size: 15px; width: 100%; }
.formset select { height: 100%; width: 60px; border: 0; background-color: #f5f5f5; border-radius: 5px 0px 0px 5px; padding: 5px 7px; float: left; color: #070707; font-weight: normal; }
.formset input { width: calc(100% - 60px); float: left; border-radius: 0; border: 1px solid #fff; height: 100%; padding: 5px 8px; /*background: url(../images/down-arrow.png) 84% 50% no-repeat #fff; background-size: 12px;*/}
.formset.mobileNoIcon {
  padding-left: 35px;
}
.formset.mobileNoIcon input {
  padding: 10px;
}
.hummob img { display: none; }
.updatebar.sliderbar ul li a.complete { color: #2EC193; }
.updatebar.sliderbar ul li a.complete span { background: transparent; color: #fff; border-color: transparent; z-index: 1; font-size: 0; }
/*.updatebar.sliderbar ul li a.complete span::before { content: ""; position: absolute; left: 3.7px; top: 3.7px; width: 24px; height: 24px; background: #01ac12; border-radius: 100%; z-index: -1; transition: 0.5s; }*/
.backbtn {
  float: left;
  margin: 0px 0 25px 0;
  color: #2b2b2b;
  font-family: 'nexa_boldregular', sans-serif;
  font-weight: 500;
  display: flex;
  justify-content: start;
  text-transform: uppercase;
  font-size: 13px;
}
.backbtn img {
  /* float: left; */
  width: 13px;
  height: 15px;
  margin: -1px 10px 0 0;
}
.clearfix { display: block; content: ""; clear: both; }
.updatebar.sliderbar ul li a.complete span img { width: 30px; height: 30px; float: left; z-index: 0; position: relative; margin: -7px 0 0 1px; display: block !important; }
.updatebar.sliderbar ul li a span img { display: none; }
/*cutome css radio btn*/
.type-sch [type="radio"]:checked, .type-sch [type="radio"]:not(:checked) { position: absolute; left: -9999px; }
.type-sch [type="radio"]:checked + label, .type-sch [type="radio"]:not(:checked) + label { position: relative; padding-left: 28px; cursor: pointer; line-height: 20px; display: inline-block; color: #B6B6B6; font-family: 'nexa_lightregular', sans-serif; font-weight: bold; }
.type-sch [type="radio"]:checked + label:before, .type-sch [type="radio"]:not(:checked) + label:before { content: ""; position: absolute; left: 0; top: 0; width: 18px; height: 18px; border: 1px solid #ddd; border-radius: 100%; background: #fff; }
.type-sch [type="radio"]:checked + label::after, .type-sch [type="radio"]:not(:checked) + label::after { content: ""; width: 8px; height: 8px; background: #fff; position: absolute; top: -1px; left: -1px; border-radius: 100%; -webkit-transition: all 0.2s ease; transition: all 0.2s ease; border: 7px solid #FE6E00; }
.type-sch [type="radio"]:not(:checked) + label:after { opacity: 0; -webkit-transform: scale(0); transform: scale(0); }
.type-sch [type="radio"]:checked + label:after { opacity: 1; -webkit-transform: scale(1); transform: scale(1); }
/*cutome css radio btn*/	
.type-sch { display: inline-flex; margin: 6px 0 0 0; }
.type-sch .type1 { margin: 0 20px 0 0; }
.updatebar.sliderbar ul li ul { margin: 10px 0 0 0; padding: 0; height: auto; }
.updatebar.sliderbar ul li ul li { margin: 17px 0 0px 0; }
.updatebar.sliderbar ul li ul li a { text-transform: none; font-size: 15px; font-weight: normal; font-family: 'nexaxbold', sans-serif; }
/*School Infrastructure*/
.mobileVery { position: fixed; right: -100%; top: 0; width: 350px; background: #fff; padding: 50px 30px; height: calc(100vh - 0px); z-index: 9; transition: 0.5s; }
.inf-stru-img { /* background: #f5f5f5; */ /* min-height: 250px; */ margin-top: 10px; border-radius: 5px;/* display: inline-block; *//* width: 100%; */ }
.inf-stru-img img { width: 100%; }
.tol-built {
  margin-top: 25px;
  display: grid;
  width: 100%;
  grid-template-columns: 155px 2fr 152px;
  justify-content: center;
  align-items: center;
  grid-column-gap: 15px;
  position: relative;
}
.tol-built.clearfix { grid-template-columns: 1fr 1fr; }
.tol-built p { float: left; font-size: 15px; color: #8A8A8A; font-weight: normal; }
.tol-built span { font-size: 14px; border: 1px solid #ddd; height: 45px; border-radius: 5px; padding: 0; font-weight: 600; display: flex; justify-content: center; align-items: center; }
.tol-built span input {
  border: 0;
  height: 100%;
  width: calc(100% - 45px);
  padding: 5px 0px 5px 10px;
}
.tol-built span b {
  font-size: 14px;
  font-family: 'nexabold', sans-serif;
  width: 45px;
  text-align: center;
}
.tol-built-in { margin: 0; display: inline-block; width: 100%; }
.tol-built-in span { width: 100%; float: left; background: #e5e5e5; height: 4px; position: relative; border-radius: 50px; border: 0; padding: 0; }
.tol-built-in span::before { content: ""; position: absolute; left: 0; top: -1px; background: #070707; width: 40%; height: 6px; border-radius: 50px; }
.tol-built-in span b { width: 27px; height: 27px; position: absolute; top: -11px; left: 39%; background: #fff; border-radius: 100%; cursor: pointer; border: 4px solid #070707; }
.nu-in-dic { /* float: right; */ display: grid; grid-template-columns: 10px 50px 10px; justify-content: end; align-items: center; grid-column-gap: 13px; /* width: 110px; */ justify-items: center; padding: 0 16px; /* background: #000; */ border: 1px solid #ddd; border-radius: 5px; background: #fff; }
.nu-in-dic a { float: left; /* width: 22px; */ /* height: 22px; */ /* background: #2b2b2b; */ border-radius: 100%; padding: 2px 0px; text-align: center; }
.nu-in-dic a img { width: 100%; height: 100%; margin: auto; display: block; }
.nu-in-dic input { text-align: center; border: 0; }
.facili-avai { margin-top: 60px; }
.checkbox { float: right; margin-bottom: 0; }
.checkbox input { padding: 0; height: initial; width: initial; margin-bottom: 0; display: none; cursor: pointer; }
.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
  /* padding-left: 25px; */
  /* float: left; */
  display: inline-flex;
  justify-content: start;
  align-items: start;
  font-family: 'nexabold';
  word-break: break-word;
  font-weight: normal;
}
.checkbox label::before {
	content: '';
	-webkit-appearance: none;
	background-color: transparent;
	border: 1px solid #070707;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
	padding: 8px;
	display: inline-block;
	position: relative;
	vertical-align: middle;
	cursor: pointer;
	margin-right: 6px;
	margin-top: -4px;
	border-radius: 4px;
}

.facili-avai-in { display: inline-block; width: 100%; margin-top: 15px; }
.facili-avai1 { min-height: 110px; text-align: center; background: #fff; border: 1px solid #E9E9E9; margin-top: 15px; border-radius: 5px; display: grid; justify-content: center; align-items: center; transition: 0.5s; align-content: center; position: relative; cursor: pointer; }
.facili-avai1.complete { border-color: #2EC193 !important; background: #f7fdfb !important; }
.facili-avai1.active, .facili-avai1:hover { background: #f0f0f0; border: 1px solid #222; }
.htx1 img { float: left; margin: -5px 10px 0 0; }
/********Classrooms General Page*********/

.class-grade { background: #fff; border-radius: 5px; padding-bottom: 0; margin-top: 20px; border: 1px solid #E9E9E9; }
.class-grade-top ul { padding: 0 0 2px 0; border-bottom: 0; display: inline-block; grid-column-gap: 0; white-space: nowrap; overflow-x: auto; -webkit-overflow-scrolling: touch; width: 100%; }
.class-grade-top ul li a {
  padding: 7px 16px;
  display: inline-block;
  width: 100%;
  color: #cdcdcd;
  position: relative;
  border: 1px solid #E9E9E9;
  font-weight: normal;
  font-size: 14px;
}
.class-grade-top {
  display: inline-block;
  width: 100%;
}
.class-grade-top ul li:first-child a { border-radius: 5px 0 0 0; }
.class-grade-top ul li:last-child a { border-radius: 0px 5px 0 0px; }
.class-grade-top ul li a.complete { border-right: 2px solid #2EC193;background: #f7fdfb; border: 1px solid #2EC193; margin: 0; /* border-right: 0; */ z-index: 0; color: #2EC193; }
.class-grade-top ul li { margin: 0;display: inline-block; }
.class-grade-top ul li a.active, .class-grade-top ul li a:hover {
  color: #070707;
  border-top: 1px solid #070707;
}
.class-grade-top ul li a:hover { color: #070707; border: 1px solid #070707; }
.class-grade-top ul li a.active::before { content: ""; position: absolute; left: 0; background: #070707; height: 4px; top: -1px; width: 100%; border: 0; }
.facili-avai .class-grade-mid { padding: 30px 10px; }
.class-grade-mid { padding: 30px 20px; }
.class-grade-mid .tol-built { margin: 10px 0 0px 0; }
.class-grade-mid .tol-built p { font-size: 16px; }
.class-grade-top ul li a:hover.complete { color: #000; }
.facili-avai1 h3 { font-size: 15px; font-weight: normal; font-family: 'nexabold', sans-serif; }
.facili-avai1 p { font-size: 15px; }
.class-grade-mid .nu-in-dic { grid-template-columns: 12px 30px 12px; grid-column-gap: 13px; width: auto; }
.class-grade-mid .nu-in-dic input { padding: 0; }
/********thank you page*********/
.thank-sign img { margin-bottom: 20px; width: 130px; }
.poximg { position: absolute; left: 0; bottom: 0; max-width: 280px; }
.thank-sign { display: grid; align-items: center; justify-content: center; align-content: center; justify-items: center; height: 100vh; max-width: 750px; margin: auto; text-align: center; width: 100%; }
.thank-sign h4 { font-size: 28px; margin-bottom: 15px; }
.thank-sign p { margin-bottom: 25px; font-size: 17px; color: #8C8C8C; }
.thank-sign .btn { width: 230px; font-size: 15px; }
/*school amenitiees page*/
.dele-invetry { border: 1px solid #e7e7e7; border-radius: 5px; padding: 15px; position: relative; margin-bottom: 11px; }
.dele-invetrybox { height: calc(100vh - 210px); overflow-x: auto; }
.dele-invetry h3 { font-size: 16px; margin-bottom: 5px; }
.dele-invetry p { font-size: 12px; font-weight: 500; }
.dele-invetry p b { margin: 0 0 0 10px; }
.remove-invetry { position: absolute; right: 16px; top: 20px; width: 18px; }
.star-icon .checkcom.star { display: block; width: 16px; position: absolute; top: 9px; left: 10px; }
.remove-icon {
  width: 14px;
  position: absolute;
  right: 9px;
  top: 13px;
  height: 14px;
}
.facili-avai1 .remove-icon { display: none; }
.facili-avai1.complete.remove .remove-icon { display: block; }
.facili-avai1.complete.remove .checkcom { display: none; }
.facili-avai1.complete.remove .checkcom.remove-icon { display: block; width: 20px; }
.facili-avai1.complete.remove .checkcom.star { display: block; right: auto; left: 10px; width: 17px; }
.facili-avai1.complete .checkcom.remove-icon, .facili-avai1.complete .checkcom.star { display: none; }

.tabbed-content.tabs-side .item.active { display: block; }
.tabbed-content.tabs-side .item { margin-left: 200px; display: none; }
.tabblank { background: #F9FFF8; text-align: center; min-height: 680px; border-radius: 5px; display: grid; justify-content: center; align-items: center; }
.avera-anual { padding-top:160px; height: calc(100vh - 60px); width: 85%; }
.avera-anualbox {
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  border: 2px dashed #dadada;
}
.avera-anualbox h3 {
	font-size: 15px;
	font-weight: normal;
	font-family: 'nexabold';
}
.avera-school {
	font-family: 'nexa_lightregular', sans-serif;
	margin-top: 25px;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-column-gap: 10px;
	justify-content: end;
	align-items: end;
	width: 100%;
}
.avera-youschol span { font-weight: 600; width: 100%; display: block; font-size: 13px; font-family: 'nexaregular', sans-serif; }
.avera-youschol b { font-size: 17px; margin-top: 6px; display: block; font-family: 'nexabold', sans-serif;/* font-weight: normal; */ }
.avera-youschol {
	background: #FF9406;
	color: #fff;
	padding: 15px;
	border-radius: 5px;
	word-wrap: break-word;
}
/* .avera-youschol:nth-child(2) { background: #F8F8F8; min-height: 200px; } */
.avera-youschol:nth-child(2) span { color: #8A8A8A; }
.avera-youschol:nth-child(2) b { color: #070707; }
.avera-anualbox p { color: #8F8F8F; font-size: 13px; margin-top: 20px; /* font-family: 'nexa_lightregular'; */ font-weight: 600; }
.avera-anualbox p b {
	color: #070707;
	font-weight: normal;
}
.facili-avai1.add { border-color: #FE6E00; }
.facili-avai1.add img { width: 22px; height: 21px; }
.facili-avai1 .checkcom { display: none; }
.facili-avai1.complete .checkcom { display: block; width: 22px; position: absolute; right: 10px; top: 6px; height: 22px; }
/****************Amenities School Specific**************/
.tabremove img {
	width: 12px;
	display: inline-block;
	margin: 0 0 0 5px;
	height: 11px;
}
.tabremove .remove-icon {
	position: initial;
	display: none;
	transition:0.5s;
}
.attri-add .tabremove .remove-icon
{
  display: inline-block;
}

.tabset > label.tabremove:hover .remove-icon {
	display: block;
	position: absolute;
	right: 0;
	top: 10px;
}

.school-specify { margin-top: 25px; display: inline-block; width: 100%; }
.attbox-btn { background: transparent; border: 1px solid #FF9406; color: #FF9406; text-transform: uppercase; font-size: 16px; cursor: pointer; font-weight: normal; }
.attbox { display: grid; grid-template-columns: 1fr 130px; justify-content: center; align-items: center; grid-column-gap: 20px; margin-bottom: 15px; }
.attbox select { background-position: 85% 50%; }
.attbox label { color: #8A8A8A; font-family: 'nexaregular', sans-serif; font-weight: normal;    font-size: 14px; }
.add-inve { float: right; color: #FE6E00; text-transform: uppercase; font-size: 13px; margin-top: 4px; font-weight: 500; }
.scho-spec { background: #F8F8F8; padding: 15px; border-radius: 5px; }
.add-inve.adbrose { width: auto; position: relative; text-align: right;font-size: 13px; }
.add-inve.adbrose input { position: absolute; right: 0; top: 0; height: 100%; opacity: 0; cursor: pointer; z-index: 1; }
.sch-add-img1 { display: grid; grid-template-columns: repeat(5, 1fr); grid-column-gap: 15px; margin-top: 10px; width: 100%; }
.sch-add-pic img { width: 100%; height: 55px; border-radius: 5px; }
/****************Student Life Activities**************/
.sele-calss1.complete a { color: #FE6E00; }
.sele-calss1.add-activitie .remove-icon {
  width: 14px;
  right: 9px;
  top: 13px;
}
.sele-calss1.add-activitie { border: 0px; padding: 0; position: relative; }
.sele-calss1.add-activitie .star { width: 15px; position: absolute; right: 30px; top: 12px; }
.sele-calss1.add-activitie input { text-align: center; padding: 0 55px 0 15px; }
.cust-btn .btn { width: 100%; }
.updatebar.sliderbar ul li a:hover span img, .updatebar.sliderbar ul li a.active span img { display: none; }
.checkbox.checkbox1 { float: left; margin: 35px 0 25px 0; }
.sele-calss { display: grid; width: 100%; grid-template-columns: 1fr 1fr; grid-column-gap: 13px; grid-row-gap: 13px; }
.sele-calss1 { border: 1px solid #E9E9E9; background: #fff; padding: 13px; border-radius: 5px; text-align: center;position: relative; }
.sele-calss1 h4 { font-weight: 500; font-family: 'nexabold', sans-serif; overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; }
.sele-calss1.active { border: 1px solid #2EC193; color: #2EC193; background: #f7fdfb;position: relative; }
.sele-calss1.complete { border: 1px solid #FE6E00; color: #FE6E00; background: #fff; }
/*.student-img { float: right; width: 90%; }*/
.activies-box { border: 1px solid #E9E9E9; border-radius: 5px; min-height: 585px; }
.activies-top .htx2 { font-size: 17px; font-weight: normal; }
.activies-top { padding: 15px 20px; display: inline-block; width: 100%; }
.activies-top .checkbox { margin: 0; }
.activies-mid ul li { word-break: break-all;padding: 15px 20px; border-top: 1px solid #ddd; font-size: 14px; font-family: 'nexabold', sans-serif; font-weight: 500; color: #8C8C8C; transition: 0.5s; position: relative; }
.activies-mid ul li.active .star {
  position: absolute;
  right: 52px;
  width: 15px;
  top: 26px;
}
.attr-error {
  border-bottom: 4px solid #f44336;
  color: #f44336 !important;
}
.activies-mid ul li .addacbtn {
	float: right;
	color: #FE6E00;
	text-transform: uppercase;
	font-size: 13px;
	display: none;
	transition: 0.5s;
	position: absolute;
	right: 10px;
	top: 17px;
}
.activies-mid ul li:hover, .activies-mid ul li.active { background: #FFF8F2; color: #070707; }
/*.activies-mid ul li.active .addacbtn img {
	width: 17px;
	position: absolute;
	right: 30px;
}*/
.activies-mid ul li.active .addacbtn { display: block; right: 40px; position: absolute; top: 15px; }
.activies-mid ul li:hover .addacbtn { display: block; }
.activies-mid { display: inline-block; width: 100%; }
.activies-mid label { font-size: 14px; background: #fff; border: 1px solid #E9E9E9; border-radius: 5px; padding: 8px 10px; display: inline-block; margin: 0 0 8px 5px; font-family: 'nexabold', sans-serif; font-weight: normal; color: #8A8A8A; word-break: break-word;}
.activies-mid.activies-mid1 { padding: 0 13px; border-top: 1px solid #ddd; padding-top: 15px; }
.activies-mid label img {
  width: 12px;
  height: 10px;
}
.activies-mid label a { float: right; margin: 0 0 0 10px; }
/****************Student Activity Details**************/
/*.ava-brad .content {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 400px;
}*/
.activies-mid ul li.active .remove-icon {
  right: 29px;
  top: 27px;
  width: 14px;
  height: 14px;
}
.forminpt .checkbox label {
  font-weight: 600;
  color: #070707;
  font-size: 15px;
  display: flex;
  justify-content: start;
  align-items: start;
}
.rightPart.onboding .forminpt .checkbox label{
  white-space: nowrap;
}
.tablestar img { width: 17px; height: 17px; float: left; margin: -3px 8px 0 0; }
.ava-brad .switch { position: relative; display: inline-block; width: 35px; height: 24px; }
.ava-brad .switch input { opacity: 0; width: 0; height: 0; }
.ava-brad .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #E4E4E4; transition: 0.4s; }
.ava-brad .slider::before { position: absolute; content: ""; height: 16px; width: 16px; left: 4px; bottom: 4px; background-color: white; transition: 0.4s; }
.ava-brad input:checked + .slider { background-color: #2EC193; }
/*.ava-brad input:focus + .slider {
  box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
  outline: none;
}*/

.ava-brad input:checked + .slider::before { transform: translateX(11px); }
.ava-brad .slider.round { border-radius: 34px; }
.ava-brad .slider.round:before { border-radius: 50%; }
.activi-first-box h3 { float: left; font-size: 18px; }
.activi-first-box .ava-brad { float: right; display: flex; align-items: center; }
.ava-brad b { margin: 0 14px 0 0; font-size: 14px;font-family: 'nexaxbold', sans-serif;font-weight: normal; }
.activi-first-box { display: inline-block; width: 100%; border-top: 1px solid #ddd; padding: 20px 0;    word-break: break-all; }
.stutable.stutable1 { background: #fff; border-radius: 0px; margin-top: -1px; }
.stutablemain { margin: 25px 0; }
.stutable { border: 1px solid #E9E9E9; border-radius: 5px 5px 0 0; display: grid; grid-template-columns: 1fr 1fr 1fr; background: #F8F8F8; position: relative; }
.stutable .remove-icon { top: 12px; right: 12px; }
.stutablebox { padding: 15px 20px; }
.stutablebox h4 { text-transform: uppercase; font-size: 13px; color: #5C5C5C; line-height: 17px; font-weight: 500; font-family: 'nexabold', sans-serif; }
.stutablebox h4 img { width: 31px; height: 15px; }
.tooltip { display: inline; position: relative; }
.tooltip:hover::after {
  display: flex;
  justify-content: center;
  background: #070707;
  border-radius: 8px;
  color: #fff;
  content: attr(title);
  margin: 0;
  font-size: 13px;
  padding: 8px 13px;
  width: 135px;
  position: absolute;
  /* right: 0; */
  left: 0;
  bottom: 31px;
  text-transform: none;
  /* text-align: center; */
  z-index: 1;
  line-height: 17px;
  font-weight: 600;
  top: auto;
  white-space: normal;
}
.tooltip:hover::before {
  border: solid;
  border-color: #070707 transparent;
  border-width: 8px 6px 0 6px;
  content: "";
  left: 34%;
  bottom: 23px;
  position: absolute;
}
.breadcrumb li { font-size: 14px; display: inline-flex; margin: 0 9px 0px 0; }
.breadcrumb li span, .breadcrumb li b, .breadcrumb li strong { color: #8A8A8A; }
.breadcrumb { margin-bottom: 25px; }
.breadcrumb li a { color: #070707; }
.create-top { display: inline-block; width: 100%; }
.create-top ul { display: inline-block; }
.create-top ul li { display: inline-block; margin: 0 40px 0 0; }
.stepul li a { color: #9c9c9c; text-transform: uppercase; font-size: 13px; position: relative; padding-left: 40px; transition: 0.5s; }
.stepul li a span { width: 28px; height: 28px; position: absolute; border-radius: 100%; left: 0; top: -9px; background: #fff; border: 2px dashed #9c9c9c; display: grid; justify-content: center; align-items: center; font-size: 14px; padding: 6px 0; transition: 0.5s; }
.stepul li a:hover, .stepul li a.active { color: #070707; }
.stepul li a:hover span, .stepul li a.active span { background: #070707; color: #fff; border-color: #070707; }
.stepul { margin: 20px 0 40px 0; }
.invetory-list .stepul li a.active.complete span, .invetory-list .stepul li a.complete span {
  background: transparent;
  color: #fff;
  border: 0px solid #2EC193;
  font-size: 0;
  margin: 0;
  padding: 0;
}
.invetory-list .stepul li a.active.complete span img, .invetory-list .stepul li a.complete span img {
  margin-top: -1px;
  display: block;
  width: 30px;
  height: 30px;
  background-size: 70px;
}
.stepul li a.active.complete span, .stepul li a.complete span { background: #2EC193; color: #fff; border-color: #2EC193; font-size: 0; }
.stepul li a img { display: none; }
.stepul li a.active.complete span img, .stepul li a.complete span img { margin-top: 1px; display: block; }
.steptabs li a { color: #c9c9c9; border-bottom: 2px solid transparent; padding: 9px 0; text-transform: uppercase; font-size: 15px; transition: 0.6s; }
.steptabs { margin-bottom: 30px; }
.steptabs li { display: inline-flex; margin: 0 40px 0 0; }
.steptabs li a:hover, .steptabs li a.active { border-bottom: 3px solid #070707; color: #070707; }
.stutablebox .checkbox.checkbox1 { margin: 0; }
.stutablebox .add-inve { float: none; }
.stutablebox .checkbox input:checked + label::before { background: #FE6E00; border-color: #FE6E00; }
.stutablebox .checkbox input:checked + label::after { top: -1px; left: 7px; width: 4px; height: 9px; border-color: #fff; }
.checkbox input:checked + label::after {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 7px;
  width: 2px;
  height: 7px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.checkbox input:checked + label::before { background: #fe6e00; border-color: #fe6e00; }
.forminpt .checkbox label a { color: #fe6e00;margin: 0 0 0 5px;}
/*.sele-check .dropdown {
  position: absolute;
  top:50%;
  transform: translateY(-50%);
}*/

.sele-check a { color: #fff; }
.sele-check .dropdown dd, .sele-check .dropdown dt { margin: 0px; padding: 0px; float: right; }
.sele-check .dropdown ul { margin: -1px 0 0 0; }
.sele-check .dropdown dd { position: relative; }
.sele-check .dropdown a, .sele-check .dropdown a:visited { color: #070707; text-decoration: none; outline: none; font-size: 12px; }
.sele-check .dropdown dt a { display: block; padding: 8px 20px 5px 10px; min-height: 25px; line-height: 24px; overflow: hidden; width: 240px; border: 1px solid #ddd; border-radius: 5px; font-size: 15px; background: url(../images/down-arrow.png) 93% 49% no-repeat #fff; }
.sele-check { position: relative; z-index: 1;font-family: 'nexaxbold', sans-serif !important; }
.sele-check .dropdown dt a span, .sele-check .multiSel span { cursor: pointer; display: inline-block; padding: 0 3px 2px 0; }
.sele-check .dropdown dd ul { background-color: #fff; color: #070707; display: none; left: 0px; padding: 5px 15px 5px 5px; position: absolute; top: 45px; width: 240px; list-style: none; height: 150px; overflow: auto; border: 1px solid #ddd; border-radius: 5px; }
.sele-check .dropdown dd ul li { padding: 7px 10px; font-size: 14px; }
.sele-check .dropdown dd ul li input { width: auto; height: auto; margin: 0 7px 0 0; }
.sele-check .dropdown dt a p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; }
.sele-check .dropdown span.value { display: none; }
.sele-check .dropdown dd ul li a { padding: 5px; display: block; }
.sele-check .dropdown dd ul li a:hover { background-color: #fff; }
.sele-check .dropdown1 dd, .sele-check .dropdown1 dt { margin: 0px; padding: 0px; float: right; }
.sele-check .dropdown1 ul { margin: -1px 0 0 0; }
.sele-check .dropdown1 dd { position: relative; }
.sele-check .dropdown1 a, .sele-check .dropdown1 a:visited { color: #070707; text-decoration: none; outline: none; font-size: 12px; }
.sele-check .dropdown1 dt a {display: block; padding: 8px 20px 5px 10px; min-height: 25px; line-height: 24px; overflow: hidden; width: 240px; border: 1px solid #ddd; border-radius: 5px; font-size: 15px; background: url(../images/down-arrow.png) 93% 49% no-repeat #fff; }
.sele-check .dropdown1 dt a span, .sele-check .multiSe1n span { cursor: pointer; display: inline-block; padding: 0 3px 2px 0; }
.sele-check .dropdown1 dd ul { background-color: #fff; color: #070707; display: none; left: 0px; padding: 5px 15px 5px 5px; position: absolute; top: 45px; width: 240px; list-style: none; height: 150px; overflow: auto; border: 1px solid #ddd; border-radius: 5px; }
.sele-check .dropdown1 dd ul li { padding: 7px 10px; font-size: 14px; }
.sele-check .dropdown1 dd ul li input { width: auto; height: auto; margin: 0 7px 0 0; }
.sele-check .dropdown1 dt a p { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; width: 100%; }
.sele-check .dropdown1 span.value { display: none; }
.sele-check .dropdown1 dd ul li a { padding: 5px; display: block; }
.sele-check .dropdown1 dd ul li a:hover { background-color: #fff; }
.sele-check button { background-color: #6BBE92; width: 302px; border: 0; padding: 10px 0; margin: 5px 0; text-align: center; color: #fff; font-weight: bold; }
/******************Onboarding Summary*****************/
.onboding {
  width: 70%;
  overflow: visible;
}
.personal-box-top .htx2.mb20 { margin: 0; }
.personal-box { background: #F8F8F8; padding: 15px; border-radius: 5px; margin-bottom: 25px; }
.personal-box-bot .inf-stru-img {
  width: 100%;
  margin-bottom: 20px;
  background: #e0f0ff;
  padding: 30px;
}
.editbtn { font-size: 14px; text-transform: uppercase; color: #070707; font-weight: 500; font-family: 'nexa_boldregular', sans-serif; display: flex; width: auto; align-content: center; align-items: center; justify-content: end; }
.editbtn img {
  margin: -3px 5px 0 0;
  width: 14px;
}
.personal-box-top { border-bottom: 1px solid #eee; padding: 10px 0 15px 0; }
.personal-bottom { padding: 20px 0; }
.personal-box-bot p, .personal-box-bot b { padding: 10px 0; width: 100%; display: inline-block; font-size: 14px; }
.personal-box-bot p { color: #8A8A8A; font-family: 'nexaxbold', sans-serif; }
.personal-box-bot b { color: #070707; font-family: 'nexaxbold', sans-serif; font-weight: normal;}
.activi-box-open { display: none; }
.personal-bottom .class-grade { margin-top: 15px; }
.personal-bottom .class-grade .htx2 { margin-bottom: 10px; }
.personal-box-bot { font-family: 'nexa_boldregular', sans-serif; font-weight: 300; }
.ovelap.active { position: fixed; top: 0; left: 0; right: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.5); z-index: 6; }
.popup-body .facili-avai-in { max-height: calc(100vh - 250px); overflow-x: auto; margin: 0; padding: 0; }
.popup-header h3 { padding: 0 8px; }
.popup-body .facili-avai-in h3 { font-size: 16px; color: #070707; font-weight: 500; line-height: 1.5;}
.popup-body .facili-avai-in .facili-avai1 h3 { font-size: 15px; }
.popup-body .facili-avai-in .facili-avai1 { min-height: 100px; }
.popup-header .htx2 { text-transform: none; color: #070707; font-size: 19px; }
/******pop css start********/

.hide { visibility: hidden; opacity: 0; transform: translateX(-50%) translateY(-50%) scale(0.8) !important; -moz-transform: translateX(-50%) translateY(-50%) scale(0.8) !important; -o-transform: translateX(-50%) translateY(-50%) scale(0.8) !important; -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8) !important; }
.close {
  position: absolute;
  right: 4px;
  top: 3px;
  width: 25px;
  height: 25px;
  font-size: 0;
  cursor: pointer;
  padding: 0;
}
.close:before, .close:after { content: ''; position: absolute; background: #767676; -moz-border-radius: 1px; -webkit-border-radius: 1px; -o-border-radius: 1px; border-radius: 1px; left: auto; right: 6px; top: 12px; height: 1.5px; width: 17px; -webkit-transform: rotate(45deg); -moz-transform: rotate(45deg); -o-transform: rotate(45deg); transform: rotate(45deg); transition: all 100ms ease-in; -moz-transition: all 100ms ease-in; -webkit-transition: all 100ms ease-in; -o-transition: all 100ms ease-in; }
.close:after { -moz-transform: rotate(-45deg); -webkit-transform: rotate(-45deg); -o-transform: rotate(-45deg); transform: rotate(-45deg); }
.popup { position: fixed; top: 50%; left: 50%; -webkit-transform: translateX(-50%) translateY(-50%); -moz-transform: translateX(-50%) translateY(-50%); -o-transform: translateX(-50%) translateY(-50%); transform: translateX(-50%) translateY(-50%); background: #fff; -moz-border-radius: 5px; -o-border-radius: 5px; -webkit-border-radius: 5px; border-radius: 5px; max-width: 700px; box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.09), 0px 11px 12px 7px rgba(0, 0, 0, 0.02); -moz-transition: all 120ms; -webkit-transition: all 120ms; -o-transition: all 120ms; transition: all 120ms; z-index: 99; padding: 25px; width: 100%; }
/*added new css 26 july*/
.sele-calss1.active .star {
  width: 15px;
  position: absolute;
  right: 30px;
  top: 12px;
}

.sele-calss1 .star {
  width: 15px;
  position: absolute;
  right: 30px;
  top: 12px;
}
.css-yk16xz-control {
  /* height: 45px; */
  font-size: 14px;
}
.css-g1d714-ValueContainer {
  height: 100%;
}
.stutablebox.activeadd-custome {
  grid-column: 1 / span 3;
  width: 100%;
}
.bottom-error-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 15px;
}
.bottom-error-btn .forminpt {
  margin: 0;
  width: auto;
}
.class-grade-top ul li a.active.pending {
  border: 1px solid #ff4a4a;
}
.class-grade-top ul li a.active.pending::before {
  background: #ff4a4a;
}

.class-grade-top ul li a.pending {
  border: 1px solid #ff4a4a;
}

.class-grade-top ul li a.pending::before {
  content: "";
  position: absolute;
  left: 0;
  background: #ff4a4a;
  height: 4px;
  top: -1px;
  width: 100%;
  border: 0;
}

.tabbed-content.tabs-side .tabs li .pending-amenity::before {
  border-left: 8px solid #ff4a4a;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.tabbed-content.tabs-side .tabs li .pending-amenity::after {
  border-left: 8px solid #fff1f1;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}
.sele-calss1.active.complete {
  padding: 0;
}
.sele-calss1.active.complete a {
  padding: 13px;
  display: grid;
}
.checkbox.onboardcheck {
  float: left;
  font-size: 15px;
  font-weight: normal;
}
.checkbox.onboardcheck b {
	font-weight: normal;
	font-family: 'nexabold';
}
.activies-mid ul li.cust-btn a.btn.btn-outline {
  margin: 0;
}
.edit-custom-input {
  padding: 0;
  border: 0 !important;
}
.class-grade-top ul li a.active.complete {
  color: #2EC193;
  border: 1px solid #2EC193 !important;
}
.edit-custom-input input {
  text-align: center;
}
.forminpt .checkbox label b {
  display: inline-block;
  width: auto;
  padding-left: 2px;
}
input#email {
  padding-right: 70px;
}
#image-gallery .image {
  position: relative;
  margin-bottom: 10px;
  border-radius: 7px;
  background: #2b2b2b;
}
#image-gallery .image .removeImg {
  position: absolute;
  right: -5px;
  top: -5px;
  color: #070707;
  transform: rotate(45deg);
  z-index: 1;
  background: #fff;
  height: 15px;
  width: 14px;
  display: none;
  border-radius: 100%;
}
#image-gallery .image:hover .removeImg {
  display: block;
}
#image-gallery .image .img-wrapper {
  margin: 0 0 10px 0;
  border-radius: 5px;
}


  .loginButt:hover {background-color: #3466de;}  
.loader-imgupload::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  background-color: #2b2b2b;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.loader-imgupload {
  color: #4274EA !important;
  position: absolute !important;
  top: 1;
  z-index: 1;
  width: 100%;
  bottom: 0;
  height:100%;
  padding: 0;
  margin: 0;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.sele-calss1.active h4 {
  padding-right: 36px;
  width: 100%;
}
.sele-calss1.active.edit-custom-input input {
  padding-right: 50px;
  
}
/*css start for terms onboarding page*/
/*.rightPart.onboding .forminpt .checkbox label a {
	margin: 0 7px;
}*/
.onboarding-terms.popup {
  max-width: 1100px;
  width: 90%;
}
.onboarding-terms.popup .termsBox h2 {
	font-size: 16px;
	color: #000000;
  font-family:'nexaxbold', sans-serif;
	text-align: center;
	text-decoration: underline;
	margin-bottom: 25px;
}
.onboarding-terms.popup .termsBox p {
	font-size: 15px;
  font-family:'nexabold', sans-serif;
	font-weight: normal;
	line-height: 23px;
	margin-bottom: 12px;
	color: #000000;
}
.onboarding-terms.popup .termsBox p b {font-family:'nexaxbold', sans-serif;}
.onboarding-terms.popup.brand .termsBox p{
  position: relative;
}
.onboarding-terms.popup .termsBox .bullet, .onboarding-terms.popup .termsBox .bullet-heading {
  display: flex;
}
.onboarding-terms.popup .termsBox .bullet p:nth-child(1) {
  margin-right: 24px;
  display: inline-block;
}
.onboarding-terms.popup .termsBox .bullet-heading p:nth-child(1) {
  margin-right: 30px;
  display: inline-block;
}
.onboarding-terms.popup .termsBox h3 {
	font-weight: 600;
  font-family:'nexaxbold', sans-serif;
	font-size: 16px;
	margin-bottom: 6px;
	margin-top: 15px;
	display: inline-block;
	width: 100%;
  color: #000000;
}

.onboarding-terms.popup .table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  border-collapse: collapse;
  font-family:'nexaxbold', sans-serif;
}
span.notetable {
  font-family:'nexaxbold', sans-serif;
  font-size: 13px;
  font-weight: normal;
}
.moutableview .table {
  margin: 0 0 5px 0 !important;
  padding: 0;
}
.moutableview h3 {
  font-size: 16px !important;
  margin: 0 0 10px 0 !important;
}
.onboarding-terms.popup .table thead th, .onboarding-terms.popup .table td.head {
  vertical-align: bottom;
  border-left: 1px solid #777;
  font-size: 11px;
  text-align: left;
  color: #000;
  font-weight: bold;
  padding: 6px;
}
.onboarding-terms.popup.brand .table td, .onboarding-terms.popup.brand .table thead th, .onboarding-terms.popup.brand .table td.head
{
  border: 1px solid #c4c4c4;
}
.onboarding-terms.popup.brand .table td:nth-child(1){
  width: 20%;
}
.onboarding-terms.popup .table td {
  font-weight: normal;
}
.onboarding-terms.popup .table td, .table th {
  vertical-align: top;
  border: 1px solid #777;
  font-size: 13px;
  padding: 8px 6px;
}
.onboarding-terms.popup .table td .customeStar {
    font-size: 14px;
    padding-left: 1px;
    line-height: 0;
    font-weight: 500;
    color: #121212;
}
.onboarding-terms.popup .customeStar {
  font-size: 14px;
  padding-left: 1px;
  line-height: 0;
  font-weight: 500;
  color: #121212;
}
.moutableview {
  margin-top: 15px;
}
.yellow-text {
	text-align: left;
	font-weight: normal;
	font-size: 14px;
	padding: 3px 0px;
	border-radius: 3px;
	color: #070707;
}
.yellow-text span{
	font-size: 15px;
  font-family:'nexaxbold', sans-serif;
}
.onboarding-terms.popup .close.toggle {
  top: -40px;
  right: -5px;
  height: 25px;
}
.onboarding-terms.popup .close.toggle::before, .onboarding-terms.popup .close.toggle::after {
	background: #fff;
}
.onboarding-terms.popup .close.toggle.left-back {
	left: -10px;
	right: auto;
}
.toggle.left-back {
	position: absolute;
	left: 0;
	top: -30px;
	font-size: 0;
}
.blurBackground {
	background-color: black;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.6;
	right: 0;
	z-index: 1;
}

.moutableview .table.signature{
  margin-top: 20px !important;
  padding: 0;
}
.moutableview .table.signature td:nth-child(1){
  width:50%;
  padding-left: 50px;
}
.moutableview .table.signature td:nth-child(2){
  width:10%;
}
.moutableview .table.signature td:nth-child(3){
  width:40%;
  padding-left: 50px;
}
.moutableview .table.signature td div:nth-child(1){
  padding-bottom: 10px;
}


/*css end for terms onboarding page*/
/*.rc-slider-track {
  width: 100% !important;
}*/
.rc-slider {
  width: 95% !important;
}
.rc-slider-step {
  width: 95% !important;
}
.onboarding-terms.popup .table td.p0 {
  padding: 0;
}
.onboarding-terms.popup .table td.p0 .table {
  padding: 0px;
  margin: 0 !important;
  border: 0;
}
.onboarding-terms.popup .table td.p0 tr td {
  /* margin: 0; */
  border: 0;
  border-bottom: 1px solid #777;
}
.onboarding-terms.popup .termsBox .bullet ul {
  display: flex;
}
.onboarding-terms.popup .termsBox .bullet ul li:nth-child(1) {
  display: inline-block;
  margin-right: 24px;
}
.onboarding-terms.popup .termsBox .bullet ul li {
  display: inline-block;
  width: auto;
}

/*FAQ POPUP CSS START*/

.faq-logo {
  background: url(../images/faq-logo.svg) 0% 0% no-repeat #fff;
  height: 100px;
  width: 110px;
  display: inline-block;
}
.faq-qu-logo {
  margin-bottom: 20px;
}
.faq-logo-line {
  height: 5px;
  background: #ff9406;
  width: calc(100% - 110px);
  display: inline-block;
  position: relative;
  left: -20px;
}
.faq-qu-text h4 {
  font-size: 18px;
  font-weight: 600;
  color: #727376;
  margin: 0 0 15px 0;
}
.faq-qu-text p {
  color: #737375 !important;
  font-family: 'nexaregular', sans-serif !important;
}
.faq-qu-text {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}
.faq-logo-line.w100 {
  position: inherit;
  margin: 15px 0 0 0;
}
.w100 {
  width: 100%;
}


.student-lineright {
  padding: 10px;
  border: 3px solid #ff9405;
  border-left: 0;
  padding-bottom: 0;
  margin-bottom: 30px;
  padding-left: 0;
  position: relative;
}

.avera-textlogo .faq-logo {
  background: url(../images/faq-logo.svg) 0% 0% no-repeat transparent;
  height: 140px;
    width: 160px;
    display: inline-block;
    margin-left: -30px;
    background-size: cover;
    margin-bottom: -7px;
    margin-top: -20px;
}

.student-img img {
	width: 100%;
	height: 100%;
	background-size: cover;
}


@keyframes demo {
	from {
		left:-200px;
		}
		
		to {
		left:100%;
		}
	
}
.student-imgbg img {
  height: 100%;
  border-radius: 5px;
}

/* .student-imgbg {
  background: url(../images/student-activities-img.svg) 0% 0% no-repeat #fff;
  height: 330px;
    width: 100%;
    text-align: left;
    position: relative;
    left: -40px;
} */

.student-imgbg {
  background: url(../images/student-imgbg.png) 0% 0% no-repeat #fff;
  height: 400px;
    width: 100%;
    text-align: left;
    position: relative;
    overflow: hidden;
    background-size: 100%;
    border-radius: 5px;
}
.row.align-items {
  align-items: center;
}
.student-lineright p {
  font-size: 14px;
  text-align: left;
  margin: 0 0 15px 0;
  font-family: 'nexa_lightregular', sans-serif;
  line-height: 23px;
  color: #5d5e62;
}

.student-righttext b {
  text-align: left;
  float: left;
  color: #4b4b4b;
  font-family: 'nexaxbold', sans-serif;
}
.student-lineright:before {
  content: "";
  position: absolute;
  left: 0;
  top: -3px;
  width: 70%;
  height: 3px;
  background: #fff;
}
.avera-textlogo {
  background: #F8F8F8;
  padding: 20px;
  border-radius: 5px;
  overflow: hidden;
  padding-bottom: 0px;
}
.avera-textlogo p {
    font-size: 14px;
    font-family: 'nexa_lightregular', sans-serif;
    margin: 0 0 20px 0;
    line-height: 22px;
    color: #5d5e62;
}

.student-img {
	float: right;
	width: 90%;
	overflow: hidden;
	position: relative;
	height: 100%;
	background: #ddd;
	border-radius: 5px;
}

.student-loader {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	background-image: linear-gradient(to right, #ddd, #f3f3f3, #ddd);
	border-radius: 10px;
	animation: demo 1s infinite;
}
@keyframes demo {
	from {
		left:-200px;
		}
		
		to {
		left:100%;
		}
}

.content-loader{
  animation: loader 1s linear infinite;
}
@keyframes demo {
  0%{
    background-color: hsl(200, 20%, 70%);
  }
  100%{
    background-color: hsl(200, 20%, 95%);
  }
}

/*FOS PAGE CSS START*/

.fosbtnmain {
  display: grid;
  grid-template-columns: 1fr 15px 1fr;
  grid-column-gap: 25px;
  margin-top: 50px;
}
.loginbox.fospage .login-form {
  width: 100%;
  margin: 0;
  padding: 30px 70px 30px 70px;
  /* overflow-x: scroll; */
  height: calc(100vh - 0px);
  display: inline-block;
}
.fosbtnmain .fosbtn {
  color: #070707;
  background: #fff;
  border: 1px solid #7C7C7C;
  width: 100%;
  display: grid;
  text-align: center;
  height: 60px;
  align-items: center;
  border-radius: 5px;
  font-weight: normal;
  margin-bottom: 20px;
  transition: 0.1s;
}
.fosbtnmain .fosbtn:hover {
  border-color: #FE6E00;
  color:#fff;
  background-color: #ff9406;
}
.css-1wa3eu0-placeholder {
	font-family: 'nexabold' !important;
	font-weight: normal;
}
.fosbtnmain p {
  font-size: 14px;
  color: #828282;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}
.fos-line {
  height: 100%;
  background: #E9E9E9;
  width: 2px;
  margin: auto;
}
.fos-line-horizontal {
  width: 100%;
  background: #E9E9E9;
  height: 2px;
  margin: auto;
}

/*FOS PAGE CSS END*/

.css-1rhbuit-multiValue, .css-yk16xz-control {
  font-family: 'nexaxbold', sans-serif !important;
}
.facili-avai .row {
  align-items: start;
}
.stutable.stutable1.active {
  height: 110px;
}
.stutable.stutable1.active .stutablebox {
  position: relative;
  height: 110px;
}
.stutable.stutable1.active .addnameart.active {
  position: absolute;
  left: 0;
  width: 450px;
}
input#phone {
  -moz-appearance: none;
  -webkit-appearance: none;
  background-image: none;
}
.customeShowdiv {
    border: 1px solid #ddd !important;
    border-radius: 5px;
    padding: 15px 10px;
    margin-bottom: 30px;
    margin-top: -15px;
    padding-bottom: 0;
}

.imgSchool {
  position: absolute;
  right: -110px;
  top: 0;
  background: #E0F0FF;
  width: 100px;
  border-radius: 6px;
  height: 45px;
  color: #fff;
  padding: 5px;
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.imgSchool img {
  /* height: 90%; */
  max-width: 65px;
  object-fit: contain;
  /* filter: grayscale(1); */
  /* border-radius: 5px; */
  margin: auto;
  display: block;
}
.tabblank p {
  font-size: 13px;
  color: #8A8A8A;
  font-family: 'nexabold';
}
.scho-spec .htx2 {
  font-size: 16px;
}
/*newMvp sidebar start css*/
.onboarding-bar.sliderbar.newMvp-sidebar {
  background: #fe6e00;
  padding: 20px 0px 35px 45px;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a span {
  border: 2px dashed #fea866;
  background: transparent;
  color: #fea866;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a {
  color: #fea866;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a.active {
  color: #fff;
  cursor: pointer;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a.complete {
  color: #ffd3b2 !important;
  cursor: pointer;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a span img {
  display: none !important;
}


.onboarding-bar.sliderbar.newMvp-sidebar ul li a.complete span {
  background: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
  border: 2px solid #fea460 !important;
}

.onboarding-bar.sliderbar ul li a.complete span:before {
  content: "";
  position: absolute;
  left: 7px;
  top: 13px;
  height: 2px;
  width: 6px;
  background: #fff;
  transform: rotate(45deg);
}
.onboarding-bar.sliderbar ul li a.complete span:after {
  content: "";
  left: 10px;
  top: 12px;
  height: 2px;
  width: 11px;
  background: #fff;
  position: absolute;
  transform: rotate(-45deg);
}
.onboarding-bar.sliderbar.newMvp-sidebar .logo a {
  display: none;
}
.forminpt small {
  font-size: 13px;
  font-weight: normal;
  color: #999999;
  margin-top: 7px;
  display: block;
  width: 100%;
  font-family: 'nexaregular';
}
.newMvp-pages-css .nu-in-dic {
  grid-template-columns: 10px 70px 10px;
}
.newMvp-pages-css .facili-avai {
  margin-top: 40px;
}
.newMvp-pages-css .class-grade-mid .nu-in-dic {
  grid-template-columns: 10px 35px 10px;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a.active span {
  background: #fff;
  border-color: #fff;
  color: #fe6e00;
}
.none {display: none;}
.ava-brad.ava-school {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.ava-brad.ava-school strong {
  float: left;
}
.customeShowdiv.custome-class .type-sch label {
  font-size: 14px;
}
.customeShowdiv.custome-class .type-sch [type="radio"]:checked + label:before, .type-sch [type="radio"]:not(:checked) + label:before {
  width: 15px;
  height: 15px;
}

.customeShowdiv.custome-class .type-sch [type="radio"]:checked + label::after, .type-sch [type="radio"]:not(:checked) + label::after {width: 6px;height: 6px;}
.customeShowdiv.custome-class input.btn.sc-ganral {
  border: 1px solid #fe6e00;
  color: #fe6e00;
  font-size: 13px;
  min-width: auto;
  height: 40px;
}
.customeShowdiv .forminpt {
  margin-bottom: 15px;
}
.customeShowdiv .forminpt label {
  font-size: 14px;
}
.react-tagsinput {
  border-radius: 4px;
}
#boardOfStudy {
  margin-top: -15px;
}
.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 0%) 0px 0px 1px 2px, rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}
.introjs-tooltip-header {
  display: none;
}
.introjs-tooltip {
  min-width: 350px !important;
  font-family: 'nexabold' !important;
}
.introjs-tooltip.myToolTipClass{
  min-width: 213px !important;
  font-family: 'nexabold' !important;
}
.introjs-tooltiptext {
  padding: 20px;
  font-family: 'nexabold' !important;
  font-size: 14px;
}
.introjs-bullets {
  display: none !important;
}
.introjs-tooltipbuttons {
  border-top: 0 !important;
  white-space: normal !important;
  width: auto !important;
  float: right !important;
  padding: 0 10px 10px 0 !important;
}
.introjs-helperNumberLayer {
  width: auto !important;
  float: left !important;
  padding: 0 20px !important;
  font-family: 'nexabold' !important;
  font-size: 12px !important;
  margin-top: 15px !important;
}
.introjs-nextbutton {
  float: right;
  background: #FE6E00 !important;
  color: #fff !important;
  font-family: 'nexabold' !important;
  border: 0 !important;
  text-shadow: none !important;
  text-transform: uppercase !important;
  font-size: 12px !important;
  border: 1px solid #FE6E00 !important;
  padding:12px 20px !important;
}
.introjs-prevbutton {
  float: left;
  background: #fff !important;
  border: 1px solid #FE6E00 !important;
  color: #FE6E00 !important;
  margin: 0 10px 0 0 !important;
  font-family: 'nexabold';
  text-transform: uppercase;
  font-size: 12px !important;
  padding:12px 20px !important;
}
.introjs-prevbutton:focus {border:1px solid #FE6E00 !important;
  box-shadow: none !important;}
  .introjs-button:focus {
    /* border: 0 !important; */
    box-shadow: none !important;
}
.introjs-arrow.left {
  left: -15px !important;
}
.introjs-arrow.right {
  right: -15px !important;
}
.introjs-arrow {
  border-width: 8px !important;
}
.login-form .btn {
	margin: 0;
}
#newPassword, #confirmNewPassword {
	font-size: 13px;
}

#newPassword::-webkit-input-placeholder, #confirmNewPassword::-webkit-input-placeholder {
  font-size: 18px;
  vertical-align: middle;line-height: 70px;
  opacity: 1 !important;
}
#newPassword::-moz-placeholder, #confirmNewPassword::-moz-placeholder {
  font-size: 18px;vertical-align: middle;line-height: 70px;
}
#newPassword:-ms-input-placeholder, #confirmNewPassword:-ms-input-placeholder {
  font-size: 18px;vertical-align: middle;line-height: 70px;
}
#newPassword:-moz-placeholder, #confirmNewPassword:-moz-placeholder {
  font-size: 18px;vertical-align: middle;line-height: 70px;
}
.table-inventory td.imgInventory {
  width: 160px;
}
.table-container.table-inventory .table tbody tr td.placementSize {
  padding-right: 8px !important;
  padding-left: 8px !important;
  width: 60px;
}
.table-container.table-inventory .table tbody tr td.placementSize .forminpt {
  width: 100%;
}
.table-container.table-inventory .table tbody tr td.placementSize .forminpt input {
  padding: 5px;
  text-align: center;
}
input#quantity, select#units, .table.placementTable select {
  width: 100%;
  font-size: 13px;
  margin: 0px !important;
}
/* select#units {
    width: 80px;
    padding-right: 25px;
} */
table.table.placementTable td label {
  white-space: nowrap;
  font-size: 12px;
  text-transform: uppercase;
}
img.starCard {
  position: absolute;
  right: -20px;
  top: 38px;
  width: 14px;
}
.image.uploadImgDesign {
  width: 60px;
  overflow: hidden;
  cursor: pointer;
}
.image.uploadImgDesign label.uploadImgPlus {
  background: #fff;
  width: 50px;
  display: grid;
  text-align: center;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #fe6e00;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  color: #fe6e00;
  margin: 0;
  padding: 0;
}
.image.uploadImgDesign input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
.table-container.table-inventory .table tbody tr td.imgUpImg {
  padding: 0 15px !important;
}
a.addsize {
  color: #fe6e00;
}

/*loader css*/
.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: -10px;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}
.button--loading {
  position: relative !important;
  color: transparent;
  /* font-size: 0; */
  /* height: 40px; */
}
.introjs-button {
	border-radius: 7px;
}
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

/*loader css end*/
.vertext.vrifiyotp.complete img {
  margin-top: -18px;
}
.activies-mid.activi-customeadd ul li.active.addcustomEvent .star {
  top: 27px;
}
/* .wisr-react-select-menu {z-index: 9999;position: absolute;} */
.htx2 b {
  color: #8A8A8A;
  font-size: 16px;
}
table.table.placementTable .image.uploadImgDesign {
  margin: 0;
  padding: 0;
  background: transparent !important;
  height: auto !important;
  position: relative
}
table.table.placementTable .image.uploadImgDesign.eventMargin {
  margin: 5px 0 0 0 !important;
}
#formsubmit .forminpt.disabled {
  z-index: 0;
}
.placementTable .forminpt.disabled:before {
  display: none;
}
/* .placementTable .forminpt {
    margin: 0;
} */
.forminpt.eventunits select#units {
  width: inherit;
}
.tableInner .table tr.pd0-td td {
  padding: 0px 15px !important;
}
tr.pd0-td a.addsize {
  margin-bottom: 10px;
  display: inline-block;
}
.css-1uccc91-singleValue, .wisr-react-select__menu div {
	font-family: 'nexa_boldregular', sans-serif;
}

/*#fosModal .wisr-react-select__menu {height: 50px !important;overflow: scroll !important;}*/
#image-gallery.manageImage .img-wrapper a img {
  height: 40px;
  object-fit: contain;
}
#image-gallery.manageImage {
  grid-template-columns: repeat(3, 40px);
  grid-column-gap: 5px;
}
.table-inventory .image.uploadImgDesign {
  width: 40px;
  overflow: hidden;
  cursor: pointer;
  margin-top: 10px !important;
}
.table-inventory .image.uploadImgDesign label, .table-inventory .image.uploadImgDesign input {
  width: 100%;
  height: 100%;
  padding: 3px 0px 0px 0;
}
.forminpt.customPlacement input#name {
  width: 90% !important;
}
.forminpt.customPlacement img.starCard {
  top: 13px;
  right: -10px;
}
#image-gallery.manageImage.manageImageList {
  display: inline-block;
}
#image-gallery.manageImage.manageImageList .image {
  width: 50px;
  float: left;
  margin: 0 10px 0 0px;
}
#image-gallery.manageImage.manageImageList .image.uploadImgDesign {
  height: 40px !important;
}
.forminpt.customInventory {
  margin-top: 15px;
}
.map-loader {
  display: inline-block;
  width: 100%;
  position: relative;
}
.map-loader #map {
  border-radius: 10px;
}
.map-loader .upload-image-loading.maploader.active {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  right: 0;
  height: 100%;
  width: 100%;
  display: grid;
}
/* Gopal CSS */
.anchor-disabled {
  pointer-events: none !important;
  opacity: 0.6;
}
.text-uppercase{
  text-transform: uppercase;
}
.tol-built span.valError {
	grid-column: 1/ span 3;
	border: 0;
	padding: 0;
	margin: 8px 0 0 0;
	height: auto;
	justify-content: end;
	justify-content: flex-end;
}
.slowInternetMsg{
  display: none;
}
/* Gopal CSS end */

.empty-class {
  font-size: 16px;
  height: 100%;
  padding: 0px 25px;
  display: grid;
  justify-content: center;
  align-items: center;
  min-height: 505px;
}
.empty-class p {
  font-family: 'nexabold';
  text-align: center;
}
.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  border: 5px solid #fff;
  display: block;
  margin: auto;
}
.slick-slide img.slick-loading {
  border: 0;
}
/* .slick-slider {
  margin: 30px auto 50px;
} */
.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -45px;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
}
.slick-thumb li img {
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
.thumbnail-slider-wrap {
  /* margin-top: 15px; */
  height: 15px;
}
.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 50%;
}
#amenitiesModal span.close.toggle {
  top: 5px;
  right: 6px;
}
#galleryModal span.close.toggle {
  /* top: 5px;
  right: 6px; */
}

#inventoryModal span.close.toggle {
  top: 5px;
  right: 6px;
}
#amenitiesModal span.close.toggle:before, #amenitiesModal span.close.toggle:after {
  width: 17px;
}

#galleryModal span.close.toggle:before, #galleryModal span.close.toggle:after {
  width: 17px;
}

#amenitiesModal span.close.toggle:before, #amenitiesModal span.close.toggle:after {
  width: 17px;
}

#galleryModal span.close.toggle:before, #galleryModal span.close.toggle:after {
  width: 17px;
}
#sendRequest {
  margin-top: 10px;
}
.onboarding-bar.sliderbar.newMvp-sidebar ul li a.active.complete {
  color: #fff !important;
}

.onboarding-bar.sliderbar.newMvp-sidebar ul li a.active.complete span {
  border: 2px solid #fff !important;
}
.onFocus .wisr-react-select__control.css-yk16xz-control,
.onFocus .wisr-react-multi-select__control.css-yk16xz-control {
	border-color: #ffcbcb !important;
}
.email-veri p {
  width: 100%;
  color: #8A8A8A;
  font-family: 'nexabold';
}
.email-veri p a {
  margin: 0 0 0 5px;
}
#addImages #image-gallery {
	display: inline-block;
}
#addImages #image-gallery .image {
	width: 60px;
	float: left;
	margin-right: 10px;
}
.introjs-tooltiptext {
  padding: 20px;
  font-size: 14px;
  line-height: 22px;
  font-family: 'nexabold' !important;
}

.introjs-helperNumberLayer, .introjs-tooltipbuttons a {font-family: 'nexabold' !important;}
li.active.addcustomEvent input {
  padding-right: 40px;
}
.addAdmininput .btn {
	padding: 14px 0 !important;
}
.loginBtn.loginMobile {display: none;}
@media screen and (max-width: 1370px) {
.w80 {
	width: 70%;
}
}
@media screen and (max-width: 1280px) {
  .w80 {
    width: 80%;
  }
  .login-form, .signup-form {
    padding: 50px 100px 20px 100px;
  }
  .login-wave img {
    width: 90%;
    margin: auto;
  }
  .loginbox {
    grid-template-columns: 1.3fr 1.5fr;
  }
  .tol-built {
    grid-template-columns: 160px 2fr 150px 100px;
  }
  }

@media screen and (max-width: 1000px) {
  .loginBtn.loginMobile {
    position: absolute;
    right: 50px;
    top: 14px;
    display: block;
  }
  .loginBtn.loginMobile .btn {
    width: auto;
    float: right;
    min-width: inherit;
    height: 30px;
    padding: 8px 15px;
  } 
  .loginBtn.loginDesk {
    display: none;
  }
.popup { width: 90%; }
}
.popup-header {
  text-transform: uppercase;
  font-size: 18px;
  margin-top: 0;
}
.popup-body {
  padding: 25px 0 0 0;
}
.button { padding: 10px 15px; border: 1px solid #F5F5F5; text-transform: uppercase; box-shadow: 1px 2px 3px #F5F5F5; color: #5E5E5E; cursor: pointer; transition: all 120ms ease-in; outline: none; }
.button:hover { box-shadow: 1px 3px 5px #E9E9E9; transform: translateY(-1px); }
.button:active, .button:visited { transform: translateY(1px); outline: none; box-shadow: 1px 2px 3px #F5F5F5; }
/******pop css end********/
.poz {
  position: relative;
  z-index: 0;
}
.longEnough { max-height: 220px; width: 350px; overflow: auto; }
.rightPart.w80.content .mCSB_inside > .mCSB_container { margin-right: 30px; }
.addnameart { position: relative; margin-top: 14px; display: none; }
.addnameart.active {
  display: block;
  margin: 0;
}
.addnameart .add-inve { position: absolute; right: 15px; top: 9px; z-index: 1; /* font-weight: normal; */ /* font-family: 'nexa_boldregular'; */ }
.tabbed-content.tabs-side .tabs { width: 200px; float: left; max-height: 680px; overflow-x: auto; padding-right: 15px; position: relative; z-index: 0; }
.tabs ul li a.active::before { content: ""; position: absolute; right: -8px; top: 38%; /* height: 10px; */
	/* width: 15px; */
border-left: 8px solid #FF9406; border-top: 8px solid transparent; border-bottom: 8px solid transparent; }
.tabs ul li a.active::after { content: ""; position: absolute; right: -6px; top: 38%; /* height: 10px; */
	/* width: 15px; */
border-left: 8px solid #fffbf5; border-top: 8px solid transparent; border-bottom: 8px solid transparent; }
.active.complete::before, .active.complete::after { display: none; }
.tabbed-content.tabs-side .tabs .mCSB_inside > .mCSB_container { margin-right: 0; }
.tabbed-content.tabs-side .mCustomScrollBox { padding-right: 25px; }
#image-gallery { display: grid; grid-template-columns: repeat(6, 60px); width: 100%; grid-column-gap:0px; }
.img-wrapper a img { border-radius: 5px; position: relative; height: 55px; }
.img-wrapper { position: relative; margin-top: 15px; }
.img-wrapper img { width: 100%; }
.img-overlay {
    background: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 90%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.5s;
}
#image-gallery .image .img-wrapper:hover .img-overlay {
    opacity: 1;
}
#image-gallery .img-wrapper:hover .img-overlay {
    opacity: 1;
}
 
.img-overlay i.fa.fa-plus {
  position: absolute;
  right: 6px;
  top: 4px;
  transform: rotate(45deg);
  font-size: 15px;
}

.img-overlay i { color: #fff; font-size: 18px;border-radius: 5px; }
.img-wrapper a { display: inline-block; width: 100%; }
/*#overlay { background: rgba(0, 0, 0, 0.7); width: 100%; height: 100%; position: fixed; top: 0; left: 0; display: flex; justify-content: center; align-items: center; z-index: 999; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }*/
#overlay {
  background: rgba(0, 0, 0, 0.5);
  width: 50%;
  height: 50%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  -webkit-user-select: none;
  user-select: none;
  right: 0;
  margin: auto;
  /* display: grid; */
  bottom: 0;
  max-width: 550px;
}
#overlay:before {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  z-index: -11;
}

.placementTable #overlay:before {
  background: rgba(0, 0, 0, 0.3);
}
#overlay img {
  /* margin: 0; */
  width: 100%;
  height: 100%;
  /* object-fit: contain; */
  /* padding: 5%; */
}
.placementTable #overlay.imageView {
	background: rgba(200, 200, 200, 0.7);
}
.formset .phcode .css-yk16xz-control {
  border: 0 !important;
}
.formset .phcode .css-1pahdxg-control:hover, .formset .phcode .css-1pahdxg-control:foucs {outline: none;border-color: transparent !important;box-shadow: none !important;}
.formset .phcode .css-1pahdxg-control {border: 0px !important;box-shadow: none !important;}
/*#overlay img { margin: 0; width: 80%; height: auto; -o-object-fit: contain; object-fit: contain; padding: 5%; }*/
.tabset > label a.remove-icon {
    color: #222;
}
.tabset > label.tabremove:hover {
    color: #FE6E00 !important;
}
.css-1hb7zxy-IndicatorsContainer, .css-1okebmr-indicatorSeparator
{
display:none;
}
.content.mCustomScrollbar.fixhight .rightPart {
  overflow: visible;
}
.formset .phcode input:not(.wisr-react-multi-select__input input), .formset .phcode select {
  height: auto;
}

.popup-body .facili-avai-in .facili-avai-in.clearfix {
  max-height: inherit;
}
/* #registeredUnder {
  margin-top: -10px;
} */
.popup.inventoryModal {
  max-width: 500px;
}

.popup.inventoryModal .col-xl-6.col-lg-6.col-md-6.col-sm-6.col-xs-6 {
  width: 100%;
  flex: 100%;
  max-width: 100%;
}
.verifyLoader .vertext {
    position: initial;
    /* opacity: 0.5; */
}
.verifyLoader.clickNone {
    pointer-events: none;
    opacity: 0.6;
}
/* .forminpt b.vertext.vrifiyotp.active {
  color: #FE6E00;
  opacity: 1;
} */

.attbox select#units {
  width: 100%;
}
.inventoryCustomMargin .forminpt .css-tlfecz-indicatorContainer {
  padding: 8px 5px;
}
.inventoryCustomMargin .forminpt .css-2b097c-container {
  width: 100px;
}
#amenitiesModal .tol-built {
  margin-bottom: 20px;
}
#galleryModal .tol-built {
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
/*#overlay img { width: 60%; }*/
}
@media screen and (min-width: 1200px) {
/*#overlay img { width: 450px; padding: 0; height: 330px; object-fit: cover; border-radius: 5px; }*/
}
#nextButton { color: #fff; font-size: 2em; transition: opacity 0.8s; }
#nextButton:hover { opacity: 0.7; }
@media screen and (min-width: 768px) {
  #nextButton {
    font-size: 20px;
    position: absolute;
    bottom: -39px;
    right: 0;
    cursor: pointer;
}



#nextButton:before {
  content: "";
  position: absolute;
  right: 5px;
  bottom: 12px;
  height: 12px;
  width: 2px;
  background: #fff;
  transform: rotate(-45deg);
}
#nextButton:after {
  content: "";
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 12px;
  width: 2px;
  background: #fff;
  transform: rotate(45deg);
}
#nextButton i {
  opacity: 0;
}


}
#prevButton { color: #fff; font-size: 2em; transition: opacity 0.8s; }
#prevButton:hover { opacity: 0.7; }
@media screen and (min-width: 768px) {
  #prevButton {
    font-size: 20px;
    position: absolute;
    bottom: -40px;
    right: 26px;
    cursor: pointer;
}
#prevButton:before {
  content: "";
  position: absolute;
  right: 5px;
  bottom: 12px;
  height: 12px;
  width: 2px;
  background: #fff;
  transform: rotate(45deg);
}
#prevButton:after {
  content: "";
  position: absolute;
  right: 5px;
  bottom: 5px;
  height: 12px;
  width: 2px;
  background: #fff;
  transform: rotate(-45deg);
}
#prevButton i {
  opacity: 0;
}

}
#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: -30px;
  right: 0;
  cursor: pointer;
}
#exitButton:after {
  content: "";
  position: absolute;
  right: 8px;
  top: 3px;
  height: 17px;
  width: 2px;
  background: #fff;
  transform: rotate(-44deg);
  pointer-events: none;
}
#exitButton:before {
  content: "";
  background: #fff;
  height: 17px;
  width: 2px;
  position: absolute;
  right: 8px;
  top: 2px;
  transform: rotate(47deg);
  pointer-events: none;
}
#exitButton i {
  /* display: none; */
  opacity: 0;
}
#exitButton:hover { opacity: 0.7; }
@media screen and (min-width: 768px) {
#exitButton { font-size: 22px; }
}
/**********tabs html css start**************/

.tabs ul { margin: 0; padding: 0 0 1em 0; font-weight: bold; }
.tabs ul li a { padding: 0.5em 1em; }
.tabs ul li a.active { background: #fffbf5; color: #eee; border-color: #FF9406; }
.tabs ul li a:hover { background: #fffbf5; color: #eee; border-color: #FF9406; }
.tabs ul li a.complete { background: #f7fdfb; color: #eee; border-color: #2ec193; position: relative; }
.tabbed-content .tabs li a img { display: none; }
.tabs ul li a.complete img { position: absolute; right: 7px; top: 5px; width: 19px; height: 19px; display: block; }
/* GOPAL CSS START */
.tabs ul li a.complete.custom-complete img{ position: absolute; right: 30px; top: 9px; width: 19px; height: 19px; display: block;}
/* GOPAL CSS END */
.item { margin-bottom: 2px; }
.item::before { cursor: pointer; background: #fff; padding: 10px 10px; display: block; border: 1px solid #E9E9E9; text-align: center; font-weight: 600; border-radius: 5px; }
.item.active::before { background: #fffbf5; color: #FF9406; border-color: #FF9406; border-radius: 5px 5px 0 0; }
.item.active .item-content { padding: 0 0 0 15px; -webkit-transition: opacity 0.3s ease-in-out; -moz-transition: opacity 0.3s ease-in-out; -o-transition: opacity 0.3s ease-in-out; -ms-transition: opacity 0.3s ease-in-out; transition: opacity 0.3s ease-in-out; }
@media all and (min-width:700px) {
.item.active .item-content { padding-top: 0; }
.tabs-side .tabs li { margin-bottom: 10px; }
}
/* 
The project specific CSS starts here
This is the minimum CSS that you will need in order for this to work
*/
.tabbed-content .tabs { display: none; }
.tabbed-content .item { min-height: 2em; }
.tabbed-content .item::before { content: attr(data-title); }
.tabbed-content .item .item-content { opacity: 0; visibility: hidden; height: 0; }
.tabbed-content .item.active .item-content { opacity: 1; visibility: visible; height: auto; }
/*
 CSS for the main interaction
*/

.tabset > input[type="radio"] { position: absolute; left: -200vw; }
.tabset .tab-panel { display: none; }
.tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child, .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2), .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3), .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4), .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5), .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) { display: block; }
/*
 Styling
*/
.tabset > label { position: relative; display: inline-block; padding: 10px 20px; border: 0; border-bottom: 0; cursor: pointer; font-weight: 500; text-transform: uppercase; color: #777777; font-family: 'nexabold', sans-serif; font-size: 14px; }
.tabset > label:hover, .tabset > input:focus + label { color: #FE6E00; }
.tabset > label:hover::after, .tabset > input:focus + label::after, .tabset > input:checked + label::after { background: #06c; }
.tabset > input:checked + label { border-color: #ccc; border-bottom: 4px solid #070707; margin-bottom: -2px; color: #070707; }
.attri-add { margin-top: 15px; }
.tab-panel { padding: 20px 0; border-top: 1px solid #ccc; }
/*
 Demo purposes only
*/
/*add class for tabs css*/
.tabset .tabs-scrollhori > label { position: relative; display: inline-block; padding: 7px 20px; border: 0; border-bottom: 0; cursor: pointer; font-weight: 500; text-transform: uppercase; color: #777777; font-family: 'nexabold', sans-serif; font-size: 13px; }
.tabset .tabs-scrollhori > label:hover, .tabset > input:focus + label { color: #FE6E00; }
.tabset .tabs-scrollhori > label:hover::after, .tabset > input:focus + label::after, .tabset > input:checked + label::after { background: #06c; }
.tabset .tabs-scrollhori > input:checked + label { border-color: #ccc; border-bottom: 4px solid #070707; margin-bottom: -2px; color: #070707; }

.tabset .tabs-scrollhori > input[type="radio"] { position: absolute; left: -200vw; }
.tab-panel.active {
  display: block;
}
.optbox .wrong-otp-border {
  border-color: #FFCBCB;
}
.tabs-scrollhori {
  white-space: nowrap;
  overflow: auto;
  height: auto;
  display: inline-flex;
  width: 100%;
  /* justify-content: space-around; */
  padding-bottom: 2px;
}
.activies-mid ul li.active.added-activates a.remove-icon {
  top: 16px;
  right: 13px;
}
.activies-mid ul li.active.added-activates img.star {
  top: 15px;
  right: 37px;
}
.activies-mid ul li.active.added-activates {
  background: transparent !important;padding-right: 60px;
}
.tabbed-content.tabs-side .tabs li .pending-amenity {
  border-color: #f93939;
  background: #fff1f1;
}
.tabbed-content.tabs-side .tabs li .pending-amenity h4 {
  color: #f93939;
}
.tabbed-content.tabs-side .tabs li .pending-amenity p {
  color: #f93939;
}
#boards .btn {
  margin: 0;
}
#amenitiesModal .popup-body {
  padding: 0;
  margin-top: 30px;
}

#galleryModal .popup-body {
  padding: 0;
  margin-top: 30px;
}
.proEdit {
  position: absolute;
  right: 10px;
  z-index: 2;
}
.row.mypro-disable {
  position: relative;
}
@media all and (min-width:700px) {
.tabbed-content .tabs { display: block; }
.tabbed-content .tabs li { display: inline-block; }
.tabbed-content .tabs li a { border: 1px solid #E9E9E9; background: #fff; min-height: 120px; display: grid; justify-content: center; align-content: center; align-items: center; justify-items: center; border-radius: 7px; position: relative; }
.tabbed-content .item { min-height: 0; }
.tabbed-content .item::before { display: none; }
.tabbed-content.tabs-side .tabs { width: 200px; float: left; }
.tabbed-content.tabs-side .tabs li { display: block;position: relative; }
.tabbed-content .tabs li a h4 { font-size: 16px; font-weight: 500; font-family: 'nexabold', sans-serif; color: #070707; text-align: center;}
.tabbed-content .tabs li a p { font-size: 13px; color: #8C8C8C; margin-top: 7px; font-family: 'nexaregular', sans-serif; font-weight: normal; }
.tabbed-content .tabs li a.delete-custom-inventory {
  position: absolute;
  top: 10px;
  right: 10px;
  /* background: #000; */
  z-index: 1;
  min-height: auto;
  border: 0;
  padding: 0;
}
.tabbed-content .tabs li a.delete-custom-inventory img {
  display: block !important;
  opacity: 1;
  width: 17px;
}
}
/**********tabs html css end**************/

/*email verification*/

.email-pop { position: fixed; right: -100%; top: 0; width: 350px; background: #fff; padding: 50px 30px; height: calc(100vh - 0px); z-index: 9; transition: 0.5s; }
.email-pop.active { right: 0; }
.close-pop img {
  height: 14px;
  position: absolute;
  right: 17px;
  top: 13px;
}
.email-pop .htx2 { margin-bottom: 10px; }
.email-pop .ptx2 { color: #070707; margin-bottom: 20px; }
.email-pop label {
  margin: 15px 0 20px 0;
  display: block;
  color: #8A8A8A;
  font-weight: normal;
  font-family: 'nexaregular';
  font-size: 15px;
}
.email-pop label a { color: #FE6E00; text-transform: uppercase; display: inline-block;}
.email-pop .btn {
  width: 100%;
  margin: 10px 0 0 0;
}
.mobile-verifi { position: relative; }
.mobile-verifi span { position: absolute; right: 14px; top: 14px; font-size: 14px; }

/** Added by MP **/
.email-veri a[data-rel='resendOTP'].disabled {
  cursor: not-allowed;
  color: #eee;
}
.email-pop a[data-rel='resendOTP'].disabled {
  cursor: not-allowed;
  color: #eee;
}

.mobile-verifi .valError {position: static;}

.displayBlock {
  display: block;
}

.displayNone {
  display: none !important;
}

input#city {
  text-transform: capitalize;
}
b.textColor {
  color: #8a8a8a;
  font-family: 'nexaregular';
  font-weight: normal;
  margin-bottom: 5px;
  display: inline-block;
}
/** Added by MP ends **/

/* TEJ CSS START */
.align-items-center {
  align-items: center;
}
.wisr-react-select__control {
  box-shadow: none !important;
  height: 45px;
  font-size: 14px !important;
  border: 1px solid #ddd !important;
  border-radius: 5px;
  width: 100%;
  color: #171717;
  font-family: 'nexaxbold', sans-serif;
  font-weight: normal;
  outline: none;
}
.wisr-react-select__single-value{
  position: relative !important;
  transform: translate(0) !important;
  top: inherit !important;
}
.wisr-react-select__placeholder{
  /* top: 65% !important; */
  color:#b9b9b9 !important;
}
/*.wisr-react-multi-select__menu {z-index: 99999;}*/
.wisr-react-multi-select__control {
  min-height: 45px !important;
}
.wisr-react-multi-select__input input {
  height:inherit !important;
}
.wisr-react-multi-select__control--is-focused{
  font-size: 11.9px !important;
}

.wisr-react-select__indicators, .wisr-react-select__value-container{
  height: 100% !important;
}
.wisr-react-select__input input{
  transform: translateY(-7px) !important;
}
.wisr-react-select__value-container > div:not(.wisr-react-select__single-value):not(.wisr-react-select__placeholder) {
  height: 100% !important;
}
.customSchoolType {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}
.email-veri p a {
	cursor: pointer;
	color: #FE6E00;
}

.d-flex {
  display: flex;
}

.justify-content-space-between {
  justify-content: space-between;
}

.mb-30 {
  margin-bottom: 30px;
}

.tags-input {
  background: transparent;
  border: 0;
  color: #777;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  height: 30px !important;
}

.react-tagsinput--focused {
  border-color: #000 !important;
}

.react-tagsinput-tag-custom a::before {
  content: " ×";
  color: hsl(0, 0%, 20%);
}

.react-tagsinput-tag-custom {
  background-color: hsl(0, 0%, 90%);
  border-radius: 2px;
  color: hsl(0, 0%, 20%);
  display: inline-block;
  font-family: sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.disabledLink {
  pointer-events: none !important;
}

.fullScreenLoader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #ff9406;
  width: 35px;
  height: 35px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
  margin-left: -10px;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loaderWrapper {
  width: 100px;
  height: 100px;
  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;  
}

.mt-15 {
  margin-top: 15px;
}

.imageOverlay::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 90%;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  z-index: 0;
}
/* TEJ CSS END */

/* GOPAL CSS START */
#addNewAttribute{
  position: relative;
}
.tabbed-content.tabs-side .tabs{
  overflow-y: auto;
}
/* GOPAL CSS END */

/*SARANI CSS START */
.form-text{
	position:relative;
}
.static-value{
	position: absolute;
    left: 19px;
    font-weight: bold;
    font-size: 1.2em;
    color: #444;
    top: 10px;
}
.disable-button{
  background: #c5c9cd;
  border: #c5c9cd;
}
.search-fos{
  height: 38px;
  width: 250px;
}
.filterDropDown-fos{
  width: 250px;
  position: absolute;
  right: 36%;
  top: 75px;
  background: white;
  box-shadow: 2px 1px 2px #ddd;
  border: 1px solid #ddd;
  border-radius: 7px;
  text-align: left;
  z-index: 1;
}
.filterDropDown-school{
  width: 250px;
  position: absolute;
  /* right: 60%; */
  top: 38px;
  background: white;
  box-shadow: 2px 1px 2px #ddd;
  border: 1px solid #ddd;
  border-radius: 7px;
  text-align: left;
  z-index: 1;
}
.searchinput-fos {
  width: 45px !important;
  height: 45px;
}
.searchinput-fos-expand{
  right: 110px;
  bottom: 5px;
}
.search-box {
	width: 80%;
	border: 1px solid #ccc;
  outline: 0;
  border-radius: 15px;
}
/* .search-box:focus {
	box-shadow: 0 0 15px 5px #b0e0ee;
	border: 2px solid #bebede;
} */
.close-icon {
	border:1px solid transparent;
	background-color: transparent;
	display: inline-block;
	vertical-align: middle;
  outline: 0;
  cursor: pointer;
}
.close-icon:after {
	content: "X";
	display: block;
	width: 15px;
	height: 15px;
	position: absolute;
	background-color: #97909024;
	z-index:1;
	right: 15px;
	top: 0;
	bottom: 0;
	margin: auto;
	padding: 2px;
	border-radius: 50%;
	text-align: center;
	font-weight: normal;
	font-size: medium;
	box-shadow: 0 0 10px #464545;
	cursor: pointer;
  color: #000;
}
.search-box:not(:valid) ~ .close-icon {
	display: none;
}
.forminpt-dateFilter {width: 190px; margin-bottom: 30px; position: relative; }

.slick-slide-label {
  color: #fff;
  padding: 5px 0px;
  position: absolute;
  left: 0;
  font-family: 'nexabold';
  font-size: 0.8em;
  bottom: 5px;
  margin-bottom: 0px;
  text-align: center;
  /* width: 658px; */
  width: 100% !important;
  margin:  0 auto;

  background: rgba(0, 0, 0, 0.7);
}
/* .slick-prev:before, .slick-next:before{
  color: #fe6e00;
  font-size: 32px !important;
  margin-top: 4px !important;
}

*/
/* .slick-arrow.slick-next, .slick-arrow.slick-prev{
  background: none !important;
  margin:10px !important;
}  */

/*SARANI CSS END */
