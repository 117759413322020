/*Backend Admin start*/

/* .backendlogin img {
  max-width:600px;
  width: 80%;
} */
p.fixText {
  min-height: 55px !important;
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.htx1 {
  font-size: 24px;
}

.backendlogin {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.centerTx {
  text-align: center;
  width: 100%;
  color: #8a8a8a;
}

.login-form.backendForgot a.backbtn {
  clear: both;
  width: 100%;
  margin: 45px 0 10px 0;
}

.login-form.backendForgot h2.htx1 {
  margin: 25px 0 10px 0;
}

.optsetbackend {
  width: 100%;
}

.optsetbackend a {
  color: #FE6E00;
  margin: 0 0 0 5px;
}

.optsetbackend span {
  float: right;
  background: #FE6E00;
  padding: 4px 5px;
  font-size: 14px;
  border-radius: 5px;
  margin-top: -6px;
  color: #fff;
  font-family: 'nexa_boldregular';
}

.inventoryBackAdmin .filterDropHead {
  border: 0;
}

.checkSuccess {
  display: inline-block;
  width: 27px;
  height: 27px;
  margin: 0 10px 0 0 !important;
}

.tabs4 {
  grid-template-columns: 1fr 1fr 1fr 1fr !important;
}

ul.inventory-overTabs li a h3 a {
  padding: 0;
  float: none;
  width: auto;
  display: inline-block;
  border: 0;
  margin: 0 0px 0 5px;
}

.schoolFilter {
  display: flex;
  width: auto;
  grid-column-gap: 0px;
}

/* .inventorySearch.inventoryBackAdmin div {
    margin: 0 0 0 12px;
} */
.inventorySearch.inventoryBackAdmin .css-2b097c-container,
.inventorySearch.inventoryBackAdmin .filterDropDown,
.inventorySearch.inventoryBackAdmin a {
  margin: 0 0 0 10px;
}

.schoolFilter .filterbox,
.schoolFilter .css-2b097c-container {
  margin: 0 10px 0 0;
}

.inventorySearch.schoolFilter .spaceL {
  margin: 0 0px 0 10px !important;
}

.inventorySearch.schoolFilter .btn.spaceL {
  margin: 0 0px 0 10px !important;
}

.campainFilter .filterDropHead-campaign {
  margin: 0 !important;
}

.inventorySearch.inventoryBackAdmin input {
  height: auto;
  width: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.inventory-overHead h3 span b {
  margin: 0 4px 0 0;
}

.schoolFilter .filterbox {
  display: inline-block;
  position: relative;
  background: #fff;
}

.schoolFilter select {
  display: inline-block;
  width: 140px;
  height: 40px;
  background-position: 87% 46%;
}

.inventorySearch.schoolFilter .btn {
  display: inline-block;
  border: 1px solid #ddd;
  padding: 8px 10px 5px 10px !important;
  height: auto;
  margin: 0 !important;
}

.updownarrow {
  float: right;
  cursor: pointer;
  width: 15px;
}

.inventorySearch.schoolFilter .btn.btn-outline,
.inventorySearch.schoolFilter .btn.btn-orange {
  padding: 15px 15px 5px 15px !important;
  border: 1px solid #FE6E00 !important;
  height: auto;
}

.forminpt-dateFilter p.valError {
  padding-left: 10px;
  font-size: 13px;
}

button.close-icon.serachClose::after {
  box-shadow: none;
  background: transparent;
  top: -6px;
  right: 8px;
}

.inventorySearch.inventoryBackAdmin {
  position: relative;
  float: right;
  /* width: 100%; */
  justify-content: end;
}

.backAdminDashboardRight h2.htx2 {
  float: left;
  font-size: 16px;
  margin-top: 12px;
  margin-right: 20px;
}

.arrowRight {
  font-size: 20px;
}

.down-arrow.requestArrow {
  margin: 4px 0 0 6px;
}

td.campaignRequestOepn {
  border: 0;
  padding: 0 !important;
}

td.campaignRequestOepn table.table {
  border: 0;
}

td.campaignRequestOepn table.table td {
  border: 0;
  padding-top: 0 !important;
}

.campaignRequestSlideLarge img {
  border-radius: 5px;
  width: 120px;
  height: 120px;
}

.campaignRequestSlideLarge {
  float: left;
}

.campaignRequestSlideSmall {
  float: left;
  width: 70px;
  padding-left: 10px;
}

.campaignRequestSlideSmall img {
  width: 100%;
  border-radius: 5px;
  height: 58px;
  /* margin-bottom: 10px; */
}

td.campaignRequestOepn p {
  line-height: 21px;
  font-size: 14px;
}

.table-container .table td.campaignRequestOepn a.btn {
  padding: 10px 30px !important;
  display: inline;
}

.campaignRequestSlide {
  width: 190px;
}

td.campaignRequestOepn h4 {
  color: #8a8a8a;
  font-size: 12px;
  margin-bottom: 7px;
}

#fosModal {
  max-width: 750px;
  padding: 35px 25px;
}

.table-status {
  font-size: 12px;
  padding: 4px 9px;
  border-radius: 4px;
  width: auto !important;
  font-family: 'nexaregular';
  text-transform: capitalize;
  margin: 0px;
}

.htx5 {
  font-size: 30px;
}

ul.inventory-overTabs li a h3 a.viewwLink.fR {
  float: right;
  margin-top: 5px;
  font-size: 12px;
}

.table-container.table-inventory .table thead tr th,
.table-container.table-inventory .table tbody tr td {
  white-space: nowrap;
}

.table-container.table-inventory .table tbody tr td.campaignRequestOepn table.table td {
  white-space: normal;
}

.table-container.table-inventory .table tbody tr td.campaignRequestOepn table.table td:nth-child(2) {
  width: 50%;
}

.campaignarrow {
  /* float: right; */
  display: inline-block;
  margin: 6px 10px 0 0px !important;
}

#rejectCampaignPopup {
  max-width: 500px;
}

#rejectCampaignPopup .forminpt textarea {
  height: 150px;
}

.campaignDetailsbtn .btn {
  margin: 0 0 0 10px;
}

.campaignDetailSlide .campaignRequestSlideLarge {
  width: 100%;
}

.campaignDetailSlide .campaignRequestSlideLarge img {
  width: 100%;
  height: 200px !important;
}

.campaignDetailSlide .campaignRequestSlideSmall {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0;
  grid-column-gap: 10px;
  margin-top: 2px;
}

.campaignDetailSlide .campaignRequestSlideSmall img {
  height: 75px !important;
}

.campaignRequestSlide.campaignDetailSlide {
  width: 80%;
}

.backheading img {
  margin-top: 8px;
  width: 15px;
}

/*Backend Admin end*/

th.p-relative {
  position: relative;
  /* display: table-caption;
    width: 100%; */
}

.table thead {
  background: #f8f8f8;
}

.table-container .table thead tr th {
  background: transparent;
}

.r-auto {
  right: auto;
  top: 40px;
}

.tableFilter .filterDropClose h3 {
  font-size: 14px;
  text-transform: capitalize;
}

.tableFilter .filterDropClose h3 a {
  padding: 15px;
}

.tableFilter .filtercheckbox {
  padding: 8px 16px;
}

.tableFilter .filtercheckbox .radioBtn {
  margin-bottom: 13px;
}

.pl {
  padding-left: 0 !important;
}

.backAdminDashboard .line-full {
  width: 90%;
}

th.p-relative .filterDropDown {
  min-width: 250px;
  width: auto;
}

th.p-relative .filterDropDown {
  top: 40px !important;
}

.arrowRight img {
  float: right;
  transform: rotate(180deg);
  margin: 3px 0 0 10px;
  display: inline-flex;
}

.backendDahboardTabs ul.tabsinventory {
  width: 100%;
}

.backAdminDashboard .inventory-overHead {
  margin-bottom: 5px;
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  min-height: 60px;
}

.backendDahboardTabs ul.tabsinventory {
  margin-bottom: 0;
}

.backAdminDashboard .inventory-overHead h3 span {
  display: inline-block;
  width: auto;
  margin: 0 0 0 5px;
}

.backAdminDashboard .inventory-overHead h3 {
  font-size: 20px;
  margin: 0 0 6px 0;
  font-family: 'nexaxbold';
  margin-left: 60px;
}

.backAdminDashboard .imgSvgDahboard {
  width: 45px;
  height: 45px;
  position: absolute;
  left: 0;
  top: -3px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.backAdminDashboard .inventory-overHead p {
  padding-left: 60px;
  text-transform: capitalize;
}

.backAdminDashboardRight .schoolFilter select {
  width: auto;
  height: 40px;
  background-position: 87% 46%;
  padding-right: 40px;
}

.downloadSvg img {
  width: 42px;
  max-width: initial;
}

.detailsFosList h2 {
  font-size: 14px;
  margin: 0;
  color: #8A8A8A;
}

.detailsFosList h2 b {
  font-weight: normal;
  color: #070707;
}

.schoolNameTable.detailsFosList span {
  margin: 0 7px 0 0;
  font-weight: normal;
  font-family: 'nexabold';
  color: #070707;
  font-size: 14px;
}

.detailsFosList h2 select {
  width: auto;
  padding: 5px 35px 5px 15px;
  min-width: 130px;
  margin: 0 0 0 10px;
  height: 30px;
}

#inventoryMore .rightPart.noBack {
  padding: 0;
  height: auto;
}

#inventoryMore .row.schools-list-popup {
  width: 100%;
  max-width: 100%;
  flex: 100% 1;
  height: auto;
}

#inventoryMore .row.schools-list-popup .col-xl-9.col-lg-9.col-md-12.col-xs-12 {
  width: 100%;
  max-width: 100%;
  flex: 100%;
}

/*#inventoryMore .searchinput img {
  top: 7px;
}

 #inventoryMore .inventorySearch .searchinput {
  height: 40px;
} */

.sliderbar.onboarding-bar.newMvp-sidebar.backendCampaign {
  padding: 20px 0px 35px 35px;
}

.sliderbar.onboarding-bar.newMvp-sidebar.backendCampaign .logoNew {
  padding: 0;
}

#inventoryMore .inventorySearch.schoolFilter.inventoryBackAdmin {
  margin: -2px 10px 0 0px;
}

.schoolFilter .css-2b097c-container {
  min-width: 150px;
  white-space: nowrap;
}

.campainFilter .forminpt-dateFilter {
  width: auto;
}

.campainRejectFilter {
  position: relative;
}

.campainRejectFilter .inventorySearch.schoolFilter {
  top: -10px;
  right: 7px;
}

.table-container .table tbody tr td.brandName {
  max-width: 150px;
  white-space: normal !important;
}

.table-container .table tbody tr td.companyName {
  max-width: 170px;
  white-space: normal !important;
}

.table-container .table tbody tr td.brandName a {
  margin: 0 0 0 5px;
  color: #FE6E00;
}

a.btn.btn-orange.quit-btn {
  margin: 0;
  height: auto;
  line-height: normal;
}

.textNone {
  text-transform: none !important;
}

.campaignRequestSlideSmall span {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 96px;
}

.campaignRequestSlideSmall span a {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  text-align: center;
  bottom: 0;
  display: grid;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
}

.searchinput-backendAdmin {
  width: 200px !important;
  float: left;
  height: 45px;
}

.leftAlign {
  left: 0;
  right: auto;
}

.redArrow {
  display: inline-block !important;
  transform: rotate(180deg);
}

.resetSvg {
  background: #fff;
  padding: 5px 12px;
  border-radius: 5px;
  height: 45px;
  display: grid;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
}

.resetSvg img {
  width: 20px;
  max-width: inherit;
}

.content-loader-chart {
  width: 63%;
  height: 200px;
  animation: loader 1.5s ease-out infinite alternate;
  position: absolute;
  top: 34%;

}

.loader-relative {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 250px;
}

.loader-relative .content-loader-chart {
  width: 100%;
  height: 250px;
  animation: loader 1.5s ease-out infinite alternate;
  position: absolute;
  top: 0;
  border-radius: 5px;
}

.box-container {
  display: inline-flex;
  align-items: center;
  margin-bottom: 13px;
}

.box {
  float: right;
  height: 13px;
  width: 13px;
  /* margin-bottom: 15px; */
  /* border: 1px solid black; */
  clear: both;

}

.schoolType {
  font-size: 15px;
  color: black;
}

.lingThrough {
  text-decoration-line: line-through;
}

.schoolLegend {
  display: flex;
  position: absolute;
  right: 25px;
  top: -20px;
  font-family: ui-monospace;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}


.greenTx {
  background: #e6f8f2;
  color: #31c295 !important;
}

.orangeTx {
  background: #fff1e1 !important;
  color: #fe6e00 !important;
}

.redTx {
  background: #ffeded !important;
  color: #ff4a4a !important;
}

.yellowTx {
  background: #fffce1;
  color: #b4a51f !important;
}

.grayTx {
  background: #e9e9e9;
  color: #8a8a8a !important;
}



.blueTx {
  color: blue;
}

.schoolLegend {
  margin: 20px 0 0 0;
  /* display: inline-block; */
  /* width: 100%; */
  font-family: 'nexabold';
}

.schoolLegend a {
  margin: 0 0px 0 20px;
}

.schoolLegend a div {
  border-radius: 100%;
  margin: 0 5px 0 0;
  width: 10px;
  height: 10px;
}

.schoolLegend a span {
  font-size: 11px;
  font-family: 'nexabold';
}

.top-performing-graph canvas,
.graph-dashboard canvas {
  padding-top: 30px !important;
}

.backAdminDashboardRight .inventorySearch.schoolFilter.inventoryBackAdmin.mb-20 {
  float: none;
}

/* .activityBrand {
	border-bottom: 0px;
} */
.borderlastchild:nth-last-child(2n) .line-full {
  display: none;
}

.inventorySearch.schoolFilter.campainFilter .searchinput,
.inventorySearch.schoolFilter.campainFilter .forminpt-dateFilter,
.inventorySearch.schoolFilter.campainFilter .filterDropHead-campaign {
  margin: 0 0px 0 10px;
  background: transparent;
}

.campaignHeadH3 h3 {
  margin-left: 0 !important;
}

.forminpt input[type="text"]:disabled,
.forminpt textarea:disabled {
  background: #fff;
}

.success-popup {
  max-width: 400px !important;
}

.success-popup .pophead {
  margin: 0;
  padding: 0;
  font-size: 30px;
}

.success-popup .pophead img {
  width: 27px;
  float: left;
  margin: 0px 10px 0 0;
  height: 27px;
}

.success-popup .approveActive p {
  font-family: 'nexabold';
  color: #8a8a8a;
}

.success-popup .approveActive p b {
  color: #070707;
}

.success-popup .comp-seting {
  margin: 0;
  float: right;
}

.success-popup .forminpt {
  float: right;
  width: 100%;
  margin: 0;
}

.btn-opacity {
  opacity: 0.4;
  background: transparent;
}

.arrowLBack img.left-arrow {
  float: left !important;
  display: inline-block;
}

.arrowLBack b {
  float: left;
  text-transform: none;
  font-size: 20px;
}

.arrowLBack img {
  margin: 5px 0 0 10px;
}

.flexInline {
  display: inline-block;
}

.flexInline .flexInlineLeft,
.flexInline .resetSvg,
.flexInline .downloadSvg {
  float: left;
  margin: 0px 10px 10px 0px;
}

.flexInline .resetSvg,
.flexInline .downloadSvg {
  margin: 0px 10px 10px 0px !important;
}

.flexInline .flexInlineLeft .css-2b097c-container {
  margin: 0;
  text-align: left;
}

/* .backAdminDashboardRight .wisr-react-select__control {
	height: 36px;
} */

.backAdminDashboardRight .resetSvg {
  padding: 5px 10px;
  height: 36px;
}

.upload-displayL span img {
  border: 0;
}

.backAdminDashboardRight .downloadSvg img {
  width: 43px;
  max-width: initial;
  margin: 0;
}

.redDownPercentage h3 span b img.arrowDown {
  margin: 0 0 0 4px;
}

.dashheadingSet .downloadSvg {
  margin: 0 0 0 9px;
}

#rejectCampaignPopup .popup-body {
  padding: 10px 0 0 0;
}

#rejectCampaignPopup .popup-body .forminpt label {
  font-size: 14px;
}

.trial-left p {
  font-size: 14px;
  text-transform: none;
  margin-bottom: 14px;
}

.trial-right p {
  font-size: 14px;
  text-transform: none;
  margin-bottom: 12px;
}

.trial-right h3 {
  font-size: 14px;
}

.leftFos {
  left: 0;
  right: auto;
}

.schoolDetailsTable table.table th {
  padding: 10px !important;
}

/* .schoolDetailsTable table.table th, .schoolDetailsTable table.table td {
  font-size: 14px;
  text-align: left;
} */
.schoolDetailsTable table.table th {
  font-size: 11px;
  text-align: left;
  padding: 20px 10px !important;
}

.schoolDetailsTable table.table td {
  text-align: left;
  padding: 20px 15px !important;
  font-size: 14px;
}

.trial-right .overheadOne h3 {
  font-size: 16px;
  float: left;
}

.trial-right .overheadOne .viewwLink.camp-link {
  position: initial;
  float: left;
  margin: 7px 0 0 10px;
  font-size: 12px;
}

.trial-right .overheadOne h3.descrText {
  font-size: 14px;
  margin: 5px 0 0 0 !important;
  line-height: 22px;
}

.trial-right .overheadOne p {
  font-family: 'nexaregular';
  margin: 0 0 1px 0;
}

.inventory-overHead h3 a.editSchool img {
  width: 11px;
}

.inventory-overHead h3 a.editSchool {
  font-size: 15px;
  margin: 0 0 0 13px;
  color: #070707;
}

.my-school-features ul li {
  font-size: 14px;
  display: inline-block;
  margin: 0 10px 8px 0;
  position: relative;
  font-weight: normal;
  padding-right: 15px;
  color: #8A8A8A;
  font-family: 'nexaregular';
}

.inventorydetailsbox h3 {
  font-size: 16px;
  border-bottom: 1px solid #E9E9E9;
  padding-bottom: 12px;
  font-family: 'nexaxbold';
}

.my-school-features.inventorydetailsbox ul li {
  color: #070707;
  font-family: 'nexabold';
}

.inventory-overHead p {
  font-family: 'nexabold';
}

.schoolDetailsTable table.table td {
  padding: 5px 10px !important;
}

.popup.rejectPopup h2 {
  margin: 0;
  border: 0 !important;
  padding: 0 !important;
}

.popup.rejectPopup label.lableEdit {
  margin: 0 0 10px 0;
  font-family: 'nexaregular';
}

.popup.rejectPopup {
  max-width: 500px;
}

.box-design.backadminGreybg {
  padding: 0;
}

.box-design.backadminGreybg .col-xl-3,
.box-design.backadminGreybg .col-xl-9 {
  padding: 0;
}

.box-design.backadminGreybg .backAdminDashboard {
  background: #FBFBFB;
  height: 100%;
  margin-right: 10px;
  padding: 15px;
}

.box-design.backadminGreybg .backAdminDashboardRight {
  padding: 15px;
}

.backheading {
  font-size: 20px;
}

.backheading img {
  float: left;
  margin: 2px 10px 0 0;
}

h3.htx4 {
  font-size: 24px;
}

p.discountcolor {
  color: #8a8a8a;
  font-family: 'nexabold';
}

p.discountcolor b {
  color: #070707;
}

/* input:disabled, textarea:disabled {
	background: #f5f5f5;
} */
.arrowset {
  float: right;
}

.faq-tablist-open {
  margin-top: 15px;
}

.faq-tablistView-open {
  padding-top: 20px;
}

label.labelfaqprice {
  margin-top: 13px;
}

.custom-w.open {
  top: 47px;
}

.custom-w.open input {
  margin-top: 10px;
}

.custom-w .filterDropHead {
  padding: 0;
  border: 0;
}

.custom-w.open .filterDropClose.open .filtercheckbox {
  padding: 3px 12px;
}

.custom-w .filterDropHead a.viewwLink {
  position: absolute;
  right: -6px;
  top: -10px;
  opacity: 1;
  color: #000;
  width: 16px;
  height: 16px;
  text-align: center;
  font-size: 13px;
  border-radius: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  align-self: center;
  border: 0;
}

.custom-w.open .viewwLink img {
  width: 15px;
  margin: -2px 5px 0 0;
}

button.close-icon.serachClose {
  position: absolute;
  right: 13px;
  top: 10px;
  z-index: 1;
  line-height: normal;
}

button.close-icon.serachClose img {
  position: initial;
  width: 14px;
}

#thankyouModal .popup-body {
  padding: 25px 0 0 0;
  width: 100%;
  display: inline-block;
}

button.close-icon.serachClose:after {
  display: none;
}

.dashheadingSet {
  /* display: grid; */
  /* grid-template-columns: 200px 1fr; */
  /* width: 100%; */
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.graph-dashboard {
  position: relative;
}

.dashheadingSet .htx2.clearfix {
  margin: 15px 0 0 0;
  width: 200px;
  float: left;
}

.dashheadingSet .inventorySearch.schoolFilter {
  width: auto;
  display: inline-block;
  position: inherit;
  float: right;
  text-align: right;
  justify-content: end;
}

.dashheadingSet .flexInlineLeft {
  float: left;
  margin-bottom: 10px;
  text-align: left;
}

.dashheadingSet .resetSvg,
.dashheadingSet .downloadSvg {
  height: 45px;
  width: 45px;
  float: left;
}

.fiterRight {
  width: calc(100% - 200px);
  float: right;
  text-align: right;
  display: flex;
  justify-content: end;
  justify-items: end;
}

.customRelative {
  position: relative;
}

.customRelative .custom-w.open {
  top: 47px;
}

.border-zero {
  border: 0;
}

.greyText {
  color: #8a8a8a;
  font-family: 'nexabold';
}

.foscustomeselect {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  grid-column-gap: 20px;
  align-content: center;
}

.foscustomeselect label {
  color: #8a8a8a;
  font-size: 14px;
}

.foscustomeselect .wisr-react-select__control.css-yk16xz-control {
  height: 45px;
}

.textSmall {
  text-transform: capitalize !important;
}

.textInherit {
  text-transform: inherit !important;
}

ul.inventory-overTabs li a p {
  font-size: 14px;
  font-family: 'nexabold';
  margin-bottom: 5px;
}

ul.inventory-overTabs li a h3 {
  font-size: 20px;
  color: #070707;
}

.filtercheckbox .checkbox {
  margin-bottom: 0;
  margin-bottom: 10px;
}

.schoolDetailsRight .inventory-overHead p {
  text-transform: uppercase;
}

.schoolDetailsRight .inventory-overHead h3 {
  font-size: 20px;
}

.btnactivity {
  display: inline-block !important;
  margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.bigSelect.css-2b097c-container {
  min-width: initial;
  white-space: normal;
  width: 207px;
}

.bigSelect.css-2b097c-container .wisr-react-select__value-container.wisr-react-select__value-container--has-value.css-g1d714-ValueContainer {
  overflow: visible;
  white-space: normal;
  text-overflow: initial;
}

.bigSelect.css-2b097c-container .wisr-react-select__single-value.css-1uccc91-singleValue {
  overflow: visible;
  text-overflow: initial;
}

.filterDropClose.open span.valError {
  padding: 0 12px;
  font-size: 13px;
}

.arrowset.arrowCampaign span.rowArrowUpDown svg.down-arrow.requestArrow {
  transform: rotate(0deg);
}

.campaingCretivesTwo .campaignRequestSlide.campaignDetailSlide {
  width: 90%;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 8px;
}

.campaingCretivesTwo .campaignRequestSlide.campaignDetailSlide .campaignRequestSlideLarge {
  width: 100%;
}

.campaingCretivesTwo .campaignRequestSlide.campaignDetailSlide .campaignRequestSlideSmall {
  width: 100%;
  display: grid;
  float: right;
  margin-bottom: 5px;
}

.campaingCretivesTwo .campaignDetailSlide .campaignRequestSlideSmall img {
  height: 65px !important;
}

.campaingCretivesTwo .campaignRequestSlide.campaignDetailSlide p {
  display: inline-block;
  width: 100%;
  text-align: center;
  padding: 13px 0 3px 0;
  font-size: 14px;
  color: #777;
}

.relativeInfo.tooltipActive a.tooltip:after {
  right: auto;
  left: auto;
}

.select__menu-list::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.campaingCretivesTwo {
  margin-bottom: 20px;
}

.campaingCretivesTwo.inventoryTable {
  margin-bottom: 20px;
  display: inline-flex;
  width: 100%;
}

.campaingCretivesTwo .campaignRequestSlide.campaignDetailSlide .campaignRequestSlideLarge img {
  height: 140px !important;
}

.loaderClass input.btn.btn-orange.comp-seting.btn-disabled {
  min-width: 160px;
}

.loaderClass-btn {
  width: 45%;
}

.loaderClass-btn input {
  width: 100% !important;
}

img.rupeeSymbol {
  width: 7px;
  margin: 0 6px 0 0;
}

.inventoryRelative .filterDropHead {
  border: 0;
}

.inventoryRelative {
  position: relative;
  float: right;
}

.forminpt .wisr-react-multi-select--is-disabled.css-14jk2my-container .wisr-react-multi-select__control.wisr-react-multi-select__control--is-disabled.css-1fhf3k1-control {
  background: #fff;
}

.medium-break b {
  width: auto;
  margin: 0 5px 0 0;
  padding: 0;
}

.arrowSafari .backbtn {
  display: flex !important;
  /* justify-content: start; */
  align-content: center;
  align-items: center;
}

.arrowSafari .backbtn b {
  float: none;
}

.arrowSafari .backbtn .left-arrow {
  float: none !important;
  margin: 2px 0 0 10px;
}

.pause-campaign a.btn {
  margin: 0 10px 0 0;
}

.onholdbg {
  background: #f4d8da !important;
  color: #b5121b !important;
}

#confirmRejectModal .popup-body {
  padding: 0;
}

#confirmRejectModal .popup-body h2 {
  font-size: 18px;
  margin-bottom: 20px;
  padding: 20px 0px 20px 0px;
  border-bottom: 1px solid #ddd;
}

#confirmRejectModal .btn.btn-orange {
  margin: 0;
}

.popup.rejectPopup label.lableEdit {
  margin: 0 0 10px 0;
  font-family: 'nexaregular';
}

ul.inventory-overTabs li a .viewwLink {
  min-height: inherit;
}

ul.inventory-overTabs li a {
  min-height: 105px;
}

.fillterTableHeight {
  min-height: 450px;
}

.fosListContainer li {
  padding: 5px
}

.fosListContainer .active {
  background-color: #777 !important;
}

.fosListContainer li:hover {
  /* color: #b4a51f; */
  background-color: #f1f1f1;
  cursor: pointer;
  padding: 10px 5px !important;
}

@media screen and (max-width:1280px) {
  /* .backAdminDashboardRight .resetSvg {
    height: 38px;
  } */

  .table-inventory table.table {
    white-space: nowrap;
  }

  .arrowCenter {
    display: flex;
    justify-content: space-between;
  }

  /* .table-container .table tbody tr td.companyName, .table-container .table tbody tr td.brandName {
    max-width: 170px;
    white-space: nowrap !important;
  } */
  .greyText {
    color: #8A8A8A;
    font-family: 'nexabold';
    font-size: 14px;
  }

  .border-zero {
    border: 0;
  }

  ul.tabsinventory {
    width: calc(100% - 450px);
  }

  /* .backAdminDashboardRight .downloadSvg img {
    width: 36px;
    max-width: initial;
  } */
  .dashheadingSet .resetSvg,
  .dashheadingSet .downloadSvg {
    margin: 0px 10px 10px 0;
  }

  .arrowset {
    width: initial;
    float: none;
    margin: -16px 0 0 0;
  }

  .arrowset.arrowCampaign {
    width: 130px;
    float: left;
  }

  .documentDetails .btn-general a,
  .documentDetails1 .btn-general a {
    min-width: 100%;
  }

  /* .table-container .table tbody tr td.brandName {
	white-space: nowrap !important;
} */
  .table-container.table-inventory .table thead tr th,
  .table-container.table-inventory .table tbody tr td {
    vertical-align: top;
  }

  .brandlistarrow .arrowsetTwo {
    float: right;
    /* display: inline-block; */
  }

  .brandlistarrow .arrowsetTwo .updownarrow {
    width: 18px;
    margin: -2px 0 -3px 7px;
    height: 16px;
    float: none;
  }

  .arrowCenter {
    /* display: inline-flex; */
    padding-right: 10px !important;
  }

  .brandlistarrow .arrowCenter .arrowsetTwo {
    float: none;
    /* display: inline-block; */
    margin: 1px 15px 0 0px;
    width: 18px;
  }

  .detailsFosList h2 b {
    width: 100%;
    display: inline-block;
  }

  .foscustomeselect {
    grid-template-columns: 1fr;
  }

  .foscustomeselect .css-2b097c-container {
    width: 170px;
    float: right;
  }
}

@media screen and (max-width:999px) {
  .tabs4 {
    grid-template-columns: 1fr 1fr !important;
  }

  .tableArrowRight .arrowset {
    float: right;
    margin: 0 0px 0 10px;
  }

  .inventorySearch.inventoryBackAdmin .css-2b097c-container,
  .inventorySearch.inventoryBackAdmin .filterDropDown,
  .inventorySearch.inventoryBackAdmin a {
    right: auto;
  }

  .mmt-0 {
    margin-top: 10px;
  }

  .mt-30m {
    margin-top: 20px;
  }

  .table-status {
    font-size: 11px;
    padding: 3px 7px;
  }

  .p-relative img {
    margin: 0 10px 0 10px;
  }

  .arrowset.arrowCampaign {
    width: auto;
    float: right;
    margin: 0;
  }

  .table-container .table thead tr th {
    padding: 12px 12px;
  }

  .table-container.table-inventory .table tbody tr td {
    padding: 20px 11px;
    vertical-align: middle;
  }

  .arrowsetTwo .updownarrow {
    width: 15px;
  }

  .backAdminDashboard .inventory-overHead {
    width: 50%;
  }

  ul.inventory-overTabs li a p {
    font-size: 12px;
  }

  ul.inventory-overTabs li a h3 a.viewwLink.fR {
    font-size: 10px;
  }

  .table-container.table-inventory .table tbody tr td svg {
    float: right !important;
  }

  .table-container.table-inventory .table tbody tr td .down-arrow.requestArrow {
    margin: 0 10px 0 0;
    float: none !important;
  }

  ul.inventory-overTabs li a h3 {
    font-size: 18px;
  }

  .mtt-30 {
    margin-top: 40px;
  }

  ul.inventory-overTabs li {
    margin-bottom: 10px;
  }

  .inventorySearch.inventoryBackAdmin {
    position: inherit;
    float: left;
  }

  .inventorySearch .searchinput {
    background: #fff;
  }

  #scheduleEndDate {
    margin-top: 20px;
  }

  .arrowsetOne {
    display: inline-block;
  }

  .arrowset {
    width: auto;
    display: inline-block;
    margin: -16px 10px 0 10px;
    float: none;
  }

  .table-container .table tbody tr td.companyName {
    max-width: 170px;
    white-space: nowrap !important;
  }

  .table-container .table tbody tr td.brandName {
    max-width: initial !important;
    white-space: nowrap !important;
  }

  .arrowsetTwo {
    /* float: right; */
    margin: 0px 10px 0 10px;
    display: inline-block;
  }
}

.content-loader-inventory {
  animation: loader 1s linear infinite;
  height: 30px;
  margin-bottom: 25px;
  width: 50%;
}

/* mobileView */
@media screen and (max-width:500px) {
  .mobileViewFosDetail {
    display: inline !important;

  }

  .mobilePadding {
    padding: 10px 0 0 0 !important;
  }

  .filterDrop {
    height: 42px !important
  }

  .foscustomeselect {
    text-align: left !important;
  }

  .mobileViewArrow {
    display: inline-block !important;
    /* margin: -2px 10px !important; */
  }

  .mobileViewAddSchool {
    max-width: 150px !important;
    height: 42px !important;
  }

  .mobileViewDownloadSchool {
    margin-right: 10px !important;
  }

  .fosListContainer .active {
    background-color: #fe6e00;
    color: white;
  }

  .fosListContainer li {
    padding: 10px;
    border-radius: 4px
  }

  .fosListContainer li:hover {
    background-color: #fe6e00;
    cursor: pointer;
    padding: 15px 10px !important;
    font-size: 17px;
    color: white;
  }
  .mvFosDetailContainer{
    padding: 0 5px !important;
  }
}


/* .onoffswitch {
  position: relative;
  width: 71px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
} */

/* show more text on hover effect scraped school detailed page */

.textHidden {
  position: relative;
  /* height: 15px;
  width: 100%; */
  /* left: 75px;
  top: 5px; */
  /* background: gray; */
 color: #756a6a !important;
 font-size: 12px !important;
  display: none;  /* hide text */
}

.textHover:hover .textHidden {
  display: block;    /* show text */
}

.switch-container,
.title,
.switch {
  display: inline-block;
  vertical-align: middle;
}