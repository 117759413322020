@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap');
/* CSS Document */
body { /*font-family: 'nexaregular', sans-serif;*/ padding: 0px; margin: 0px; box-sizing: border-box; font-size: 16px; background: #fff; }
* { margin: 0px; padding: 0px; box-sizing: border-box; }
ul, ol { list-style: none; margin: 0px; padding: 0px; }
a { text-decoration: none; color: #3e8bff; }
.container { width: 100%; max-width: 100%; }
.htx1, .htx2, .htx5 {
    font-family: 'nexaxbold';
    font-weight: normal;
}
.htx1 {font-size: 30px;}
.htx2 {font-size: 20px;}
.htx3 {
    font-size: 16px;
    font-family: 'nexaxbold';
}
.ptx { font-size: 13px; color: #8A8A8A; margin-top: 5px; font-weight: normal; }
.ptx1 {
    font-size: 14px;
    color: #8A8A8A;
    margin-top: 5px;
    line-height: 22px;
}
::-webkit-scrollbar { width: 5px; height: 5px;}
::-webkit-scrollbar-track { background: #f8f8f8;}
::-webkit-scrollbar-thumb {
 background: rgba(62,66,94,0.25);
 border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
 background: rgba(62,66,94,0.35);
}
.align-R {
    text-align: right !important;
    /* float: right; */
}
.dnone {display: none !important;}
.mt-0 {margin-top: 0px !important;}
.mt-10 { margin-top: 10px !important; }
.mt-20 { margin-top: 20px; }
.mt-30 { margin-top: 30px; }
.mt-40 { margin-top: 40px; }
.mt-50 { margin-top: 50px; }

.mb-10 { margin-bottom: 10px; }
.mb-20 { margin-bottom: 20px; }
.mb-30 { margin-bottom: 30px; }
.mb-40 { margin-bottom: 40px; }
.mb-50 { margin-bottom: 50px; }
.w-auto {
    width: auto !important;
}
.tx-orange {
  color: #FE6E00 !important;
  display: inline-block;
}
.pl { padding-left: 0; }
.pr { padding-right: 0; }
.ml { margin-left: 0 !important; }
.mr { margin-right: 0 !important; }
.fL { float: left;}
.fR {
  float: right !important;
  text-align: right;
}
.valError { font-size: 14px; font-weight: normal; display: block; margin-top: 7px; color: #ff4a4a !important; }
.valError img { width: 14px; height: 14px; float: left; margin: -1px 4px 0 0;font-family: 'nexabold'; }


/****BRANDS DASHBOARD CSS START*****/

a.inventoryGalleryCss {
    /* color: #000 !important; */
    color: initial !important;
    text-decoration: underline;
    padding-left: 15px;
}

.upcoming-impressions {
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 20px;
    margin-top: 30px;
}
.impressions-slider .ptx2 {
    white-space: normal;
    font-size: 14px;
    min-height: 48px;
    line-height: 22px;
}
.campaign-total p {
    font-size: 14px;
    color: #8A8A8A;
    margin-bottom: 10px;
}
.campaign-total h3 {
    font-size: 20px;
    font-family: 'nexaxbold';
    width: 75%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.impressions-slider.campaignsCreate .ptx2 {
    min-height: 72px;
}
.table-status {
    font-size: 13px;
    padding: 4px 9px;
    border-radius: 4px;
    width: auto !important;
}

.greenbg {
    background: #e6f8f2;
    color: #31c295;
}
/* SWATI CSS START */
.slideshow {
    overflow: hidden;
    min-height: 32px;
}
.slideshow.slide-relative {
    position: relative;
}
.blog-arrow.whatarrow {
    float: right;
    position: absolute;
    right: 0;
}
.blog-arrow.whatarrow a svg {
    width: 8px;
}
#map {
    margin: 0 0 10px 0 !important;
}
.slideshowSlider {
    white-space: nowrap;
    transition: ease 1000ms;
    margin-top: 4px;
    margin-bottom: -10px;
}
  .slide {
    display: inline-block;
    width: 100%;
  }
  /* SWATI CSS END */
  .impressions-slide-dot {
    margin-top: -40px;
    margin-left: 10px;
    position: relative;
    z-index: 1;
}
.impressions-slide-dot a {
    width: 7px;
    height: 7px;
    background: #E9E9E9;
    display: inline-block;
    border-radius: 100%;
    margin: 0 8px 0 0px;
    transition: 0.5s;
}
.impressions-slide-dot a:hover {
    background: #FE6E00;
}
.impressions-slide-dot a.active {
    background: #FE6E00;
}
.impressions-slide-dot a.active {
    background: #FE6E00;
    width: 15px;
    border-radius: 25px;
}
.impressions-img {
    background: #F0F6FF;
    border-radius: 5px;
    text-align: center;
    padding: 10px;
}

.box-design {
    border: 1px solid #E9E9E9;
    border-radius: 7px;
    padding: 15px;
    display: inline-block;
    width: 100%;
    position: relative;
}
.box-design1 {
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 15px;
    display: inline-block;
    width: 100%;
    position: relative;
    height: 400px;
}
.impressions-slider .btn {
    float: right;
    font-size: 13px;
    /* padding: 12px 20px !important; */
    min-width: inherit;
}
.impressions-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 25px;
    grid-row-gap: 15px;
}
.impressions-list1 {
    position: relative;
}
.impressions-list1 span {
    position: absolute;
    left: 0;
    top: 0;
    border: 1px solid #EBEBEB;
    padding: 5px;
    border-radius: 7px;
    display: grid;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
}
.impressions-list1 span svg {
    width: 24px;
}
.impressionClass {padding: 20px 20px 11px 20px;}
.impressions-list1 h2, .impressions-list1 p {
    padding-left: 62px;
}
.impressions-list1 h2 {
    font-size: 20px;
    font-family: 'nexaxbold';
    margin-bottom: 3px;
    margin-top: 3px;
}
.impressions-list1 p {
    font-size: 14px;
    color: #8A8A8A;
    font-family: 'nexaregular';
}

.center-align {
    margin: 24px 0 0 0;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 999;
    bottom: 0px;
}
td.no-going {
    text-align: center;
    padding: 100px 0;
    font-size: 14px;
    color: #8A8A8A;
}

.campaign-reach {
    background: #F8F8F8;
    height: 50px;
    position: relative;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}
.close.impressions-close {
    border: 1px solid #E9E9E9;
    border-radius: 100%;
    right: -8px;
    background: #fff;
    top: -14px;
    z-index: 1;
    width: 27px;
    height: 27px;
}
.close.impressions-close:before, .close.impressions-close:after {
    width: 12px;
}
.close.impressions-close:before {
    left: 7px;
    top: 12px;
}
.close.impressions-close:after {
    left: 7px;
    top: 12px;
}
table.table.going-campaign {
    text-align: left;
}
table.table.going-campaign th {
    padding: 13px 15px;
    font-size: 11px;
    font-family: 'nexabold';
}
table.table.going-campaign td{
    padding: 10px 15px;
    font-size: 12px;
}
.campaign-total.campaign-alerts {
    padding: 10px 20px 2px 20px;
}
table.table.going-campaign td {
	padding: 20px 15px;
	font-size: 14px;
	white-space: normal;
}
.inventory-overHead .slideshowSlider h3 {
    margin-top: 0px;
}
.inventory-overHead.campaign-alerts p  {
    margin-bottom: 0px;
}
.inventory-overHead h3 a {
    font-size: 12px;
    color: #FE6E00;
    font-family: 'nexabold';
}

.campaign-color {
    float: left;
    height: 100%;
    border-radius: 0 5px 5px 0px;
    position: relative;
    margin-right: -3px;
    width: auto;
}
.campaign-reach-100 {
    background: #59BBD6;
    width: 100%;
}
.campaign-reach-70 {
    background: #D32F27;
    width: 100%;
    z-index: 1;
    display: inline-block;
    height: 50px;
    border-radius: 5px;
}

.campaign-reach-40 {
    background: #D6DA28;
    width: 100%;
    z-index: 0;
    height: 50px;
    display: inline-block;
    border-radius: 5px;
}

.campaign-reach-15 {
    background: #362F87;
    width: 100%;
    z-index: 2;
    display: inline-block;
    height: 50px;
    border-radius: 5px;
}

/*********BRAND BAR*************/

.brandbar {
    background: #212223 !important;
}

.updatebar.sliderbar.brandbar ul li a:hover, .updatebar.sliderbar.brandbar ul li a.active {
    color: #fff;
    border-left: 5px solid #FF9406;
    background: #2A2B2C;
}

.updatebar.sliderbar.brandbar ul li a {
    color: #636465;
}

.updatebar.sliderbar.brandbar ul li a span svg path, .updatebar.sliderbar.brandbar ul li a span svg rect, .updatebar.sliderbar.brandbar ul li a span svg circle, .updatebar.sliderbar ul li a span svg ellipse {
    stroke: #636465;
}

.updatebar.sliderbar.brandbar ul li a:hover span svg path, .updatebar.sliderbar.brandbar ul li a:hover span svg rect, .updatebar.sliderbar.brandbar ul li a:hover span svg circle, .updatebar.sliderbar ul li a:hover span svg ellipse {
    stroke: #fff;
}

.updatebar.sliderbar.brandbar ul li a.active span svg path, .updatebar.sliderbar.brandbar ul li a.active span svg rect, .updatebar.sliderbar.brandbar ul li a.active span svg circle, .updatebar.sliderbar ul li a.active span svg ellipse {
    stroke: #fff;
}

.updatebar.sliderbar.brandbar ul li.line-sidebar a {
    background: #5d5e5f;
    padding: 0 !important;
    margin: 0;
}

/***********Brands Profile General**********/
.social-general span {
    border: 1px solid #E9E9E9;
    height: 100%;
    border-radius: 5px;
    display: grid;
    justify-content: center;
    align-items: center;
    height: 47px;
}
.socialmarge span img {
	width: 100%;
	height: 100%;
}
.social-general {
	display: grid;
	grid-template-columns: 50px 1fr;
	align-items: self-start;
	grid-column-gap: 10px;
	margin: 0;
}

.brand-btn {
    padding: 15px 20px 12px 20px !important;
    min-width: auto;
}
.forminpt textarea {
    height: 100px;
    padding: 20px 15px;
    resize: none;
    cursor: context-menu;
}
.arrowset a {
    cursor: pointer;
}
/*****verification page*******/

.upload-images {
    background: #F5FBFF;
    border: 2px dashed #1B9FFF;
    border-radius: 5px;
    padding: 40px;
    display: grid;
    height: 240px;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    grid-row-gap: 20px;
    position: relative;
}
.upload-images span {
    font-size: 12px;
    color: #8A8A8A;
    text-align: center;
    font-family: 'nexabold';
}
.upload-images b {
    text-transform: uppercase;
    color: #8A8A8A;
    font-size: 12px;
}
/* .upload-img {
    background: #1B9FFF;
    padding: 12px;
    width: 130px;
    position: relative;
    border-radius: 5px;
    display: grid;
    justify-content: center;
    align-items: center;
    cursor: pointer;
} */
.upload-img{
    background: #1b9fff00;
    padding: 12px;
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 5px;
    /* display: grid; */
    justify-content: center;
    /* align-items: center; */
    cursor: pointer;
    top: 0;
    left: 0;
}

.upload-img.button {
    background: #1b9fff;
    padding: 12px;
    display: grid;
    width: 130px;
    height: auto;
    justify-content: center;
    align-items: center;
    position: relative;
}
.upload-img input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100% !important;
    width: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 1;
}
.upload-img input.edit {
    position: absolute;
    width: 10%;
    min-width: 5px;
    /* height: 50px; */
    top: 0;
    left: 0;
    margin: 0;
}
.upload-img label {
    color: #fff;
    text-transform: uppercase;
    margin: 0;
    pointer-events: none;
}

.brand-profile-create {
    background: #F8F8F8;
    border-radius: 10px;
    max-width: 750px;
    margin: auto;
    display: grid;
    justify-content: center;
    align-items: center;
    align-content: center;
    justify-items: center;
    padding: 60px;
    grid-row-gap: 30px;
    width: 40%;
}
.brand-profile-create h2.htx2 {
    font-family: 'nexaxbold';
    font-size: 14px;
}

.profilemiddle {
    display: grid;
    justify-content: center;
    align-items: center;
}
.upload-display {
    display: none;
    grid-template-columns: 120px 1fr;
    grid-column-gap: 35px;
}
.upload-displayL span {
    display: block;
}
.upload-displayL span img {
    width: 100%;
    border: 2px solid #EBEBEB;
    border-radius: 5px;
}
.upload-displayL a {
    text-align: center;
    text-transform: uppercase;
    color: #FE6E00;
    margin: 10px auto;
    display: inline;
    font-size: 13px;
}
.upload-displayL .popup-body {
    padding: 0px;
    margin-top: 15px;
}

.uploaded-doc {
    background: #E6F8F2;
    color: #2EC193;
    padding: 6px 13px;
    display: inline-block;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 12px;
}
.upload-text {
    margin-bottom: 20px;
}
.upload-text h4 {
    font-size: 12px;
    color: #8A8A8A;
    margin-bottom: 8px;
    font-family: 'nexabold';
}
.upload-text p {
    font-size: 14px;
    color: #070707;
}
#social-links {
    max-width: 400px;
    width: 95%;
    min-height: 180px;
}
.upload-displayL a {
    margin: 5px 0 0 0 !important;
    font-size: 11px;
}
.social-links-popup ul {
    display: flex;
}
.social-links-popup ul li {
    border:1px solid #ddd;
    border-radius: 5px;
    display: inline-flex;
    width: 45px;
    height: 45px;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 0 10px 0 0;
}
.social-links-popup ul li a {
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
li.add-social {
    border-color: #FE6E00 !important;
    font-size: 35px;
    padding: 10px 0 0 0;
    box-sizing: border-box;
    height: 43px !important;
}
.social-links-popup ul li.add-social {
    padding: 0;
}
.social-links-popup ul li.add-social a {color: #FE6E00;}

.social-links-popup a.btn {
    float: right;
    margin: 10px 0 0 0;
}
/*tabs page*/

.profile-general-upload-img {
    display: inline-block;
    align-items: center;
    width: 100%;
    grid-column-gap: 15px;
}
.profile-general-logo-img {
    border: 1px solid #EBEBEB;
    border-radius: 5px;
    padding: 5px;
    float: left;
    width: 70px;
    margin: 0 10px 10px 0;
    height: 70px;
    display: grid;
    justify-content: center;
    align-items: center;
}
.profile-general-upload-img .viewwLink {
    float: left;
    margin-top: 33px;
    margin-left: 10px;
}
.profile-general-upload {
    border-radius: 5px;
    text-align: center;
    position: relative;
    float: left;
    height: 40px;
    padding: 10px 20px;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 15px;
    margin-right: 15px;
}
.profile-general-upload input {
    position: absolute;
    left: 0;
    top: 0;
    height: 100% !important;
    width: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
}
.profile-general-upload label {
    pointer-events: none;
    z-index: 0;
    position: relative;
}
.brand-general .inventory-overHead p {
    font-size: 12px;
}

.brand-general .inventory-overHead h3 {
    font-size: 14px;
    font-family: 'nexaxbold';
}

.brand-general .social-general {
    margin: 0 0 15px 0;
}

.btn-general a.btn {
    padding: 14px 25px !important;
    font-size: 13px ;
}
.btn-general {
    float: right;
    margin-top: 20px;
}

.line-full {
    height: 1px;
    background: #E9E9E9;
    width: 100%;
  }
  .table .table-status {
    margin: 0;
}
.campaignBudget p {
    font-family: 'nexaregular', sans-serif;
    font-size: 14px;
}
.checkboxinputList .radioBtn {
    margin: 0 45px 10px 0;
    float: left;
}
  .profile-general a.editbtn {
    float: right;
    font-size: 13px;
    font-family: 'nexaxbold';
}
.profile-general h2 {
    margin-bottom: 15px;
}
.profile-general p.ptx {
    font-size: 12px;
    line-height: 18px;
}
.profile-general {
    width: 90%;
}

.brand-general .upload-display {
    display: grid;
}
.relativeInfo a.tooltip.tooltip-auto::after {
	right: auto;
	left: auto;
	margin: 0;
}
.uploadCretiveLink {
    float: right;
    padding: 13px 20px;
}
.brand-general .upload-images {
    height: auto;
}
.upload-images-view-remove {
    display: flex;
    align-items: center;
    grid-column-gap: 0;
}
.upload-images-view-remove a {
    margin: 0 0 0 15px;
}
.upload-images-view-remove input {
    width: 40%;
}
.upload-images-view-remove .profile-general-logo-img {
    margin: 0;
    padding: 1px;
    height: 45px;
    width: 45px;
    border: 1px solid #ddd;
    display: inline-block;
}
.upload-images-view-remove .profile-general-logo-img img {
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 3px;
}
.campaign-total {
    margin: 0;
    padding: 15px 20px;
    min-height: 80px;
}
.campaign-total h3 span {
    width: auto;
    transition: ease 1000ms;
    color: #8A8A8A;
    display: block;
}
.viewwLink.camp-link {
    position: absolute;
    right: 0px;
    bottom: 22px;
}

.inventory-overHead {
    position: relative;
}

.bottom0 {
    bottom: 0 !important;
}
.camp-performance h4 {
    font-size: 14px;
    color: #8a8a8a;
}
.camp-performance b {
    float: right;
    color: #070707;
}

.camp-performance {
    width: 100%;
}

.camp-performance-bar {
    border-radius: 50px;
    height: 8px;
    width: 100%;
    background: #F6F6F6;
    margin-top: 14px;
    position: relative;
}
.overheadThree .inventory-overHead h3 {
    font-size: 16px;
    font-family: 'nexaregular';
}
.addAdmininput .checkbox.fnone label {
    float: left;
}
.camp-performance-bar span {
    background: #FF9406;
    /* width: 80%; */
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50px;
}


/*Brand User tab*/

.brandTeamMemberL {
    width: 250px;
    float: left;
}
.brandTeamMemberR {
    float: left;
    width: calc(100% - 250px);
    padding-left: 30px;
}
.brandTeamMemberL ul li a {
    display: inline-block;
    border: 1px solid #E9E9E9;
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
    transition: 0.5s;
    min-height: 80px;
}
.brandTeamMemberL ul li a span {
    width: 55px;
    height: 55px;
    background: #ebf5e0;
    position: absolute;
    left: 10px;
    top: 10px;
    border-radius: 5px;
    display: grid;
    justify-content: center;
    align-items: center;
    color: #070707;
}
.brandTeamMemberTable .forminpt label {
    font-size: 14px;
    font-family: 'nexaregular';
}
.filterDropClose.open .filtercheckbox {
	max-height: 200px;
	overflow-x: auto;
}
.brandTeamMemberTable .forminpt input {
    color: #070707;
    font-family: 'nexabold';
}
.css-2b097c-container {
	position: relative;
	box-sizing: border-box;
	text-align: left;
}
.notification-title {
    font-family: 'nexaxbold';
}
.notificationList .modal .modal-content a {
    font-family: 'nexabold';
}
.overheadTwo p {
    text-transform: uppercase;
    font-family: 'nexaxbold';
    margin-bottom: 10px;
}
.overheadTwo h3 {
    font-size: 24px;
    font-family: 'nexaxbold';
    display: flex;
    align-items: center;
    align-content: center;
    grid-column-gap: 10px;
}
.overheadOne p {
    font-size: 14px;
    margin-bottom: 11px;
    font-family: 'nexaxbold';
}
.pl-50 {
    padding-left: 50px;
}
.overheadOne h3 {
    font-size: 16px;
    font-family: 'nexaxbold';
}
.top-performing ul.tabsinventory li a {
    font-size: 13px;
}
.brandTeamMemberL ul li a h2 {
    font-size: 14px;
    color: #070707;
    padding-left: 60px;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.brandTeamMemberL ul li a p {
    font-size: 12px;
    padding-left: 60px;
    color: #8A8A8A;
    font-weight: normal;
    font-family: 'nexaregular';
}

.brandTeamMemberTable {
    background: #F8F8F8;
    border-radius: 8px;
    padding: 20px;
    width: 80%;
}

.delete-user a.viewwLink {
    float: left;
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete-user a.viewwLink svg {
    margin: -5px 0 0 5px;
}

.brandTeamMemberL ul li a:hover, .brandTeamMemberL ul li a.active {
    border: 1px solid #070707;
}

.brandTeamMemberL ul li a:hover span, .brandTeamMemberL ul li a.active span {
    background: #dbf0ee;
}

.brandTeamMemberL ul li a.active:before {
    content: "";
    border-left: 7px solid #000;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    position: absolute;
    top: 35%;
    right: -8px;
}
.brandTeamMemberL ul li a.active:after {
    content: "";
    border-left: 7px solid #fff;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    position: absolute;
    top: 35%;
    right: -7px;
}

.brandTeamMemberL ul li.addTab a {
    display: grid;
    align-items: center;
    background: #f5fbff;
    border: 2px dashed #1B9FFF;
}
.brandTeamMemberL ul li.addTab a span {
    background: #1B9FFF;
}
.brandTeamMemberL ul li.addTab a h2 {
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
}
.generalview .inventory-overHead p {
    font-size: 12px;
    margin: 0 0 9px 0;
}
.generalview .inventory-overHead h3 {
    font-size: 14px;
    margin: 0;
    word-break: break-all;
    font-family: 'nexabold';
}
.generalview .inventory-overHead {
    margin: 0;
}
a.achorCss {
    color: #FE6E00;
}
.brandTeamMemberTable h2 a {
    float: right;
    font-size: 13px;
}

span.other-arrow {
    margin: 0 0 0 10px;
}
span.other-arrow svg {
    width: 13px;
    height: 7px;
}
.creativepage p.ptx1 {
    font-family: 'nexaregular';
}
.creativepage h2.htx3 {
    font-size: 14px;
}
.wisr-option {
    width:100%;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    padding: 25px;
    margin-bottom: 30px;
}
.wisr-option-img img {
    width: 100%;
}
.wisr-option-img {
    margin-bottom: 15px;
}
.addi-reach {
    background: #f4fcf9;
    width: auto;
    padding: 12px 22px;
    display: inline-block;
    border-radius: 5px;
}
.addi-reach p {
    color: #8A8A8A;
    font-size: 12px;
    font-family: 'nexabold';
}
.addi-reach p b {
    color: #070707;
}
.inventory-overHead h3 em {
    font-style: normal;
    color: #8a8a8a;
    font-size: 14px;
    font-weight: normal;
    margin: 0 0 0 6px;
}
.generalview .inventory-overHead h3 a {
    font-size: 13px;
}
.generalview .inventory-overHead h3 a {
    font-size: 13px;
    float: none;
    margin: 0 0 0 5px;
    text-transform: capitalize;
}
.overheadThree h2.htx3 {
    font-size: 14px;
}
.overheadThree .overheadOne p {
    font-size: 12px;
    margin-bottom: 5px;
    font-family: 'nexabold';
    line-height: 19px;
}
.fnone {
    float: none;
}

.h-achor a {
    width: auto;
    float: right;
    font-size: 14px;
}
.genderSplit p {
    float: left;
    width: 50%;
    margin: 0 !important;
}
.genderSplit p b {
    float: none;
}
.genderSplit .camp-performance-bar {
    display: inline-block;
    width: 100%;
    margin-top: 10px;
    background: #c6c6c6;
}
.genderSplit .camp-performance-bar span {background: #525252;}
.genderSplit {
    margin-top: 15px;
}

.campaign-reach-scroll {
    margin-top: 25px;
    margin-bottom: 10px;
}

.campaign-reach-scroll span::before {
    background: #FE6E00;
}
.campaign-reach-scroll span strong {
    border-color: #FE6E00;
}

.available-placements h4 {
    margin-bottom: 13px;
}
.available-placements table.table {
    border: 0;
}
td.pad-border {
    border: 0;
    padding-top: 0 !important;
}

.available-placements {
    background: #F8F8F8;
    padding: 20px;
    border-radius: 5px;
}
.available-placements .table td, .available-placements .table th {
    border: 0;
    padding: 12px 13px !important;
}
.other-arrow {
    cursor: pointer;
}

a.uploadSvg {
    float: left;
    margin: 0 0px 0 15px;
    position: relative;
}
.uploadSvgSucess {
    margin-top: -5px !important;
}
.view-anchor {
    color: #070707;
}
.text-decoration {
    text-decoration: underline;
}

img.sucessCheck {
    position: absolute;
    right: -5px;
    bottom: -2px;
    width: 12px !important;
}



/*Recomended School*/

.filterDrop {
    display: flex;
    grid-column-gap: 0;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 7px 10px;
    height: 45px;
}
.filterDrop h3 {
    margin: 0 10px;
}

.filterDrop h3 {
    font-size: 14px;
    color: #070707;
}
.schoolNameTable h4 {
	font-size: 14px;
	white-space: normal;
}
.schoolNameTable span {
    color: #8a8a8a;
    font-size: 12px;
    display: inline-block;
    padding-right: 12px;
    margin: 5px 6px 0 0;
    position: relative;
    font-family: 'nexaregular';
}
.schoolNameTable span:before {
    content: "";
    position: absolute;
    right: 0px;
    top: 3px;
    width: 5px;
    height: 5px;
    background: #8a8a8a;
    border-radius: 100%;
}

.mapImg {
    width: 100%;
    height: 250px;
}

.w90 {width: 90%;float: right;}

.personal-box-bot b {
    line-height: 22px;
    font-size: 14px;
}
.personal-box-bot b em {
    font-family: 'nexaregular' !important;
}
.generalview .inventory-overHead h3 del {
    color: #8a8a8a;
    font-size: 10px;
    font-family: 'nexabold';
}
.personal-bottom .schoolNameTable span {
    font-size: 14px;
    font-family: 'nexabold';
    margin-right: 10px;
    margin-bottom: 5px;
    color: #070707;
}
.personal-bottom .schoolNameTable span:last-child:before, .my-school-features ul li:last-child:before, .schoolNameTable span:last-child:before {
    display: none;
}
.creativeImgView {
    display: grid;
    grid-template-columns: repeat(5, 120px);
    grid-column-gap: 10px;
}
.creativeImgView img {
    width: 100%;
    border-radius: 7px;
}

.confirmation-box {
    max-width: 70%;
    margin: auto;
    padding: 0 100px;
}
.confirmation-box .con-img {
    text-align: center;
    margin: 10px auto 35px;
    display: block;
}

.confirmation-box h2.htx1 {
    text-align: center;
    margin-bottom: 25px;
}

.confirmation-box p {
    text-align: center;
    line-height: 22px;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: 'nexaregular';
}

.confirmation-box p b {
    color: #070707;
    font-weight: bold;
}

.personal-bottom p {
    margin: 0;
    line-height: normal;
    text-align: left;
    font-weight: normal;
    font-family: 'nexabold';
    font-size: 14px;
}
.confirmation-box .row.personal-box-top {
    border: 0;
    padding-bottom: 0;
}
.confirmation-box .personal-box {
    padding: 15px 25px;
}

.confirmation-box .personal-bottom b em {
    font-style: normal;
    color: #8a8a8a;
    font-size: 13px;
    font-family: 'nexabold';
}
.confirmation-box .personal-bottom {
    padding-top: 0;
}
.confirmation-box .btn {
    margin: auto;
    display: block;
}

/*navbar*/

.sliderbar.updatebar.no_highlight.brandbar {
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
}

.sliderbar.updatebar.no_highlight.brandbar .wavebot {
    max-width: 230px;
}
.sliderbar.updatebar.no_highlight.brandbar ul li a {
    padding: 20px;
    padding-left: 50px;
    transition: 0.5s;
    margin-bottom: 5px;
    border-left: 5px solid #2A2B2C;
}

.sliderbar.updatebar.no_highlight.brandbar ul li a span {
	width: 30px;
	height: 30px;
	background: transparent;
	border-radius: 100%;
	border: 0;
	top: 11px;
	left: 15px;
	transition: 0.5s;
	line-height: 30px;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.active.complete span::after, .sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.active.complete span::before {
	display: none;
}

.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.active.complete span {
	font-size: 14px;
}
.updatebar.sliderbar.brandbar ul li a {
    color: #636465;
}

.sliderbar.updatebar.no_highlight.brandbar ul li a:hover, .sliderbar.updatebar.no_highlight.brandbar ul li a.active {
    color: #fff;
    background: #2a2b2c;
    border-left: 5px solid #FF9406;
}
.sliderbar.updatebar.no_highlight.brandbar ul li a:hover span, .sliderbar.updatebar.no_highlight.brandbar ul li a.active span {
border-color: #fff;
}


.sliderbar.updatebar.no_highlight.brandbar ul li a.active span {
    background: transparent;
    color: #FE6E00;
    border: 0;
}
.sliderbar.updatebar.no_highlight.brandbar ul li a.complete span {
    background: #212223;
    border: 1px solid #fff;
}

.sliderbar.updatebar.no_highlight.brandbar ul li a.complete {
    color: #fff;
}

.sliderbar.updatebar.no_highlight.brandbar ul li a.complete span:before {
    content: "";
    position: absolute;
    left: 7px;
    top: 15px;
    height: 2px;
    width: 6px;
    background: #fff;
    transform: rotate(43deg);
}
.sliderbar.updatebar.no_highlight.brandbar ul li a.complete span:after {
    content: "";
    position: absolute;
    left: 10px;
    top: 13px;
    height: 2px;
    width: 13px;
    background: #fff;
    transform: rotate(133deg);
}
.myToolTipClass  {min-width: 270px !important;}
.upload-img.editupload {
    background: #fff;
    border: 1px solid #1B9FFF;
    color: #1B9FFF;
    cursor: pointer;
}

.upload-img.editupload.end-md {
    display: inline-block;
    width: auto;
    padding: 0;
    border: 0;
}

.upload-image-loading {
    background: #eaeaea;
    height: 240px;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid #ddd;
}

.upload-image-loading .button--loading::after {
    border: 4px solid transparent;
    border-top-color: #FE6E00;
    background: #fff;
}

.file-controller {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
}

.file-controller .upload-img.editupload {
    border: 0;
    padding: 0;
    width: auto;
    text-transform: uppercase;
    font-size: 14px;
    position: relative;
}

.brandTeamMemberL ul li.addTab a:before, .brandTeamMemberL ul li.addTab a:after {
    display: none;
}
/*Filter DropDown css*/
.filterDropDown {
    width: 280px;
    position: absolute;
    right: 0;
    background: #fff;
    box-shadow: 2px 1px 2px #ddd;
    top: 50px;
    border: 1px solid #ddd;
    border-radius: 7px;
    display: none;
    text-align: left;
    z-index: 3;
}
.filterbox {
    position: relative;
}
.filterDropHead {
    padding: 12px;
    border-bottom: 1px solid #E9E9E9;
}
.filterDropHead-campaign {
    padding: 12px;
    float: left;
}
.filterDropBody {
    border-radius: 7px;
}
.filterDropHead h2 {
    font-size: 17px;
    color: #8A8A8A;
    text-transform: capitalize;
}

.filterDropHead h2 a {
    text-transform: none;
    font-size: 14px;
    margin: 0;
}
.filterDropDown.open {display: block;}
.filterDropClose {
    background: #fff;
    border-radius: 7px;
}

.filterDropClose h3 {
    font-size: 16px;
}
.filtercheckbox {
    /* background: #F8F8F8; */
    padding: 8px 14px;
    display: none;
}
.filterDropClose h3 img {
    float: right;
    margin: 5px 0 0 0;
}
.filterDropClose h3 a {
	color: #000;
	display: inline-block;
	width: 100%;
	padding: 12px;
	margin: 0 !important;
}
.filterDropClose.open .filtercheckbox {
    display: block;
}
.filterDropClose.open {
    background: #F8F8F8;
}
.filterDropClose h3 img {
    transform: rotate(0deg);
}
.filterDropClose.open h3 img {
    transform: rotate(180deg);
}
.overheadThree a.editbtn {
    font-size: 13px;
}
.overheadThree a.editbtn img {
    margin: -1px 5px 0 0;
}
.filtercheckbox .checkbox {
    margin-bottom: 10px;
}
.filtercheckbox .checkbox label::before {
    margin: -3px 10px 0 0;
}
.uploadBrose {
    width: 15px;
}
.uploadBrose img {
    width: 100%;
    margin: auto;
    display: block;
    pointer-events: none;
}
.uploadBrose input {
    position: absolute;
    left: 0;
    top: 0;
    width: 15px;
    height: 100% !important;
    opacity: 0;
    right: 0;
    padding: 0;
    cursor: pointer;
}
.genderSplit .rc-slider .rc-slider-rail {
    background: #efefee;
}

.genderSplit .rc-slider-handle {
    background: #fff !important;
    border-color: #FE6E00 !important;
    transform: translateX(-20%) !important;
}
.genderSplit .rc-slider {
    margin-top: 20px;
    width: 100% !important;
    float: right;
    margin-bottom: 10px;
}

.genderSplit .rc-slider-track {background-color: #FE6E00 !important;}
#campaignBudgetModal {
    max-width: 350px;
    padding:0px;
}
#campaignBudgetModal .popup-body {
    padding: 0;
}
#campaignBudgetModal .popup-body h2 {
    font-size: 17px;
    margin-bottom: 20px;
    padding: 20px 20px 0 20px;
}
#campaignBudgetModal .popup-body h2 a {
    float: right;
    color: #070707;
    text-transform: uppercase;
    font-size: 14px;
}
.rc-slider-body {
    margin: 0 0 20px 0;
    padding: 0 20px;
}
.rc-slider-body .rc-slider .rc-slider-handle {
    transform: translateX(-20%) !important;
}
.rc-slider-body .rc-slider {
    margin-top: 13px;
    margin-bottom: 20px;
}
.other-arrow svg {
    transform: rotate(180deg);
}
.other-arrow.other-downarrow svg {
    transform: rotate(0deg);
}

span.other-arrow {
    margin: 0 0 0 10px;
    transform: rotate(180deg);
}

/* span.other-arrow.align-R.fR.other-downarrow {transform: rotate(0deg);} */
/*Filter DropDown css*/
/*responsive css*/
/*menu style*/
.onboarding-bar.sliderbar ul li ul {
	margin: 10px 0 0 0;
	padding: 0;
	height: auto;
}
.sliderbar.updatebar.no_highlight.brandbar ul li ul li a {
    padding: 0;
    margin: 0;
    padding-left: 45px;
    border-color: #212223;
}
.sliderbar.updatebar.no_highlight.brandbar ul li ul {
    margin: 0;
}
.sliderbar.updatebar.no_highlight.brandbar ul li ul li a:hover, .sliderbar.updatebar.no_highlight.brandbar ul li ul li a.active {
    border-color: #212223;
    background: transparent;
}

/*Create Campaign SideMenu*/

.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress {
    background: #212223;
}
.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a {
    color: #7a7a7b;
}

.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a span {
    background: transparent;
    color: #7a7a7b;
    border-color: #7a7a7b;
}


.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.complete {
    color: #bcbcbd !important;
}

.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.complete span {
    border-color: #bcbcbd !important;
    color: #bcbcbd !important;
}

.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.active span {
    background: #fff;
    border-color: #fff;
    color: #FE6E00;
}
.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.active {
    color: #fff;
}
.upload-campaignImg {
    width: 100%;
}
.campaignViewimg {
    padding: 0;
    border-radius: 10px;
}
.campaignViewimg .popup-body {
	padding: 0;
	max-height: initial !important;
	margin: 15px;
}

.campaignViewimg .popup-body img.upload-campaignImg {
	width: auto;
	height: auto;
	object-fit: revert;
	display: block;
	border-radius: 3px;
	text-align: center;
	margin: auto;
	max-width: 100%;
}

.campaignViewimg .close:before, .campaignViewimg .close:after {background-color: #fff;}
tr.inventorieTableDetail .inventory-overHead {
    margin: 0;
}
tr.inventorieTableDetail .inventory-overHead p {
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
}
tr.inventorieTableDetail .inventory-overHead h3 {
    font-size: 16px;
}
tr.inventorieTableDetail .inventory-overHead h3 a.viewwLink {
    float: none;
    margin: 0 0 0 5px;
    text-transform: inherit;
    font-size: 13px;
}
tr.inventorieTableDetail td {
    border: 0;
    padding-top: 5px !important;
}
tr.inventorieTableDetail .inventorySearch.inventoryBackAdmin a.btn {
    padding: 14px 25px 10px 25px !important;
    font-size: 14px;
}


#editCampaignDetail .popup-body {
    padding: 0;
}
#editCampaignDetail .popup-body h2 {
    font-size: 18px;
    margin-bottom: 20px;
    padding: 20px 0px 20px 0px;
    border-bottom: 1px solid #ddd;
}
#editCampaignDetail .popup-body h2 a {
    float: right;
    color: #070707;
    text-transform: uppercase;
    font-size: 12px;
    margin-top: 5px;
}
label.lableEdit {
    color: #8A8A8A;
    font-size: 15px;
    font-weight: normal;
    margin-top: 16px;
    display: inline-block;
}
.inventory-overHead.my-school-features p {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 12px;
    margin: 0;
}
/*school details*/
.campaignSelect input[type="text"] {
    width: 140px;
    margin-top: -13px;
}
.campaignRequestSlide.schoolDetailsSlide {
    width: 100%;
    display: inline-block;
}
.campaignRequestSlide.schoolDetailsSlide .campaignRequestSlideLarge {
    width: calc(100% - 120px);
}
.campaignRequestSlide.schoolDetailsSlide .campaignRequestSlideLarge img {
    width: 100%;
    height: 200px;
}
.campaignRequestSlide.schoolDetailsSlide .campaignRequestSlideSmall {
    width: 120px;
}
.campaignRequestSlide.schoolDetailsSlide .campaignRequestSlideSmall img {
    height: 96px;
}
.slidearrow a {
    padding: 5px;
    float: left;
    margin: 0 0 0 5px;
}
.slidearrow a svg {
    height: 15px;
    width: 16px;
    float: left;
}
.schoolDetailsTable {
    display: inline-block;
    width: 100%;
    margin: 15px 0;
}
/* .schoolDetailsTable .campaignDetailsbtn .btn {
    padding: 15px 15px !important;
} */
.inventorydetailsbox {
    background: #F8F8F8;
    padding: 15px;
    border-radius: 5px;
}
.inventorydetailsbox h3 {
    font-size: 16px;
    border-bottom: 1px solid #E9E9E9;
    padding-bottom: 12px;
}
.inventorySearch.schoolFilter.campaignSelect a.btn {
    border: 0 !important;
    padding: 0 !important;
    margin-top: -3px !important;
}
.moulist {
    padding-left: 80px;
    margin-top: 0;
}
.moulist p {
    padding: 0px 0 0 18px;
    margin: 0;
    line-height: normal !important;
}
.moulist p span {
    width: 6px;
    height: 6px;
    background: #000;
    border-radius: 100%;
    position: absolute;
    left: 0;
    top: 6px;
}
.image.imageLoader {
    position: relative;
}
.image.imageLoader .loader-imgupload {
    width: 42px;
}
.image.imageLoader .loader-imgupload::after {
    background-color: #ff9406;
}
.w50 {
    display: inline-block;
    width: 50%;
}
.wisr-option.active {
    border: 2px solid #fe6e00;
}
.checkboxinputList .checkbox span.mandate {
    position: absolute;
    right: -13px;
    top: 15px;
}
.checkboxinputList .checkbox {
    position: relative;
}

.tooltip-relative .tooltip:hover::after {
    margin: 0;
    font-size: 11px;
    width: 185px;
    right: 0;
    bottom: 31px;
    left: -90px;
}

.tooltip-relative .tooltip:hover::before {
    left: 34%;
    bottom: 23px;
}

.tooltipdash {
    position: absolute;
    left: 0;
    bottom: 60px;
    background: rgba(0, 0, 0, 0.9);
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    min-width: 260px;
    font-size: 13px;
}
.tooltipdash h4 {
    font-size: 14px;
    margin-bottom: 7px;
}
.tooltipdash p {
    font-family: 'nexaregular';
    margin-bottom: 4px;
    font-size: 12px;
}
.tooltipdash:before {
    content: "";
    position: absolute;
    left: 15px;
    bottom: -7px;
    border-top: 7px solid #000;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    right: auto;
    width: 0px;
    margin: auto;
}
.tooltipdash p a {
    color: #FE6E00;
}
.tooltipdash.tooltipdashright::before {
    left: auto;
    right: 10px;
}
.tooltipdash.tooltipdashright {
    left: auto;
    right: 0;
}
.campaign-color.active:hover .tooltipdash {
    display: block !important;
}
.tableDropdown .filterDropDown-fos.open {
    right: 0;
    top: 38px;
    border-radius: 5px;
}
.tableDropdown .filterDropHead h2 {
    font-size: 15px;
}
.tableDropdown .filterDropHead h2 a {
    font-size: 14px;
}
.tableDropdown .filterDropBody .filterDropClose h3 {
    font-size: 14px;
}
.tableDropdown .filterDropBody .filterDropClose .filtercheckbox label {
    font-size: 13px;
    text-transform: none;
}
.tableDropdown .filterDropDown-fos {
    text-transform: none;
}
#see-location {
    max-width: 550px;
}
#see-location .popup-body {
    padding: 0;
}
#see-location .popup-body span.close {
    position: absolute;
    right: 14px;
    top: 22px;
}
#see-location .invetorypopblock {
    display: inline-block;
}
#see-location .schoolNameTable h4 {
    font-size: 19px;
    margin-bottom: 15px;
}
#see-location .schoolNameTable span {
    font-size: 14px;
    color: #070707;
    margin-bottom: 6px;
}
.documentDetails {
    float: left;
    width: 90%;
    position: relative;
}
.documentDetails:before {
    content: "";
    position: absolute;
    right: -46px;
    top: 11px;
    height: 96%;
    width: 1px;
    background: #efefef;
}
.documentDetails1 {
    float: right;
    width: 90%;
}
.documentDetails .btn-general a, .documentDetails1 .btn-general a {
    margin: 0;
    width: 100%;
    padding: 10px 10px !important;
}
.documentDetails .btn-general, .documentDetails1 .btn-general {
    display: grid;
    grid-template-columns: 1fr 1fr;
    float: left;
    grid-column-gap: 10px;
    width: 100%;
}
.documentDetails .upload-display, .documentDetails1 .upload-display {
	grid-template-columns: 40% 60%;
	grid-column-gap: 15px;
	word-wrap: break-word;
}
.documentDetails .uploaded-doc, .documentDetails1 .uploaded-doc {
    padding: 7px 15px;
}
.activityBrand h3 {
    margin: 0 0 7px 0;
}
.activityBrand {
    width: 70%;
    border-bottom: 1px solid #efefef;
    padding-bottom: 10px;
    margin-bottom: 20px;
}
.selectbrand select {
    height: 40px;
    font-size: 14px;
    background-position: 90% 50%;
    background-size: 10px;
    width: auto;
    padding: 5px 35px 5px 15px;
}
.brand-general .inventory-overHead p {
    text-transform: capitalize;
    font-family: 'nexabold';
}

.blackC {color: #070707;}
.campaign-total .blog-arrow {
    float: right;
}
.email-veri label {
    width: 100%;
    font-size: 15px;
}
span#time {
    float: right;
}
.toolTipFocus {background: #2a2b2c !important;
    opacity: 1;
    z-index: 0 !important;}
.toolTipFocus a {
    color: #fff !important;
}
.toolTipFocus a span svg path {
    stroke: #fff !important;
}

.fR.editSub {
    display: flex;
 }
 .fR.editSub a {
    margin: 0 0px 0 7px;
}
.subEditBtn {
    margin: -10px 0 20px 0;
}
.subEditBtn a {
    margin: 0 10px 0 0;
}
.forminpt.tooltip-relative a.tooltip {
    margin: 0px 0 0 6px;
}
input#mobileNo {
    width: calc(100% - 70px);
}
.tol-built.campaignBudget {
    grid-template-columns: 80px 2fr 150px;
}
.budgetExceeded h3 {
    transition: 0.5s;
    color: red;
}
.impressions-slider .blog-arrow {
    position: absolute;
    right: 11px;
    top: 14px;
    z-index: 1;
}
.overheadFour p {
    font-size: 14px;
}
.overheadFour h3 {
    font-size: 20px;
    font-family: 'nexaxbold';
}
/* .campaign-color:first-child {
    border-radius: 7px 0px 0px 7px;
} */
.campaign-color {
    border-radius: 7px;
}
.impressions-slider {
    min-height: 175px;
}
.blog-arrow a svg {
    width: 12px;
    height: 16px;
}
.blog-arrow a {
    margin: 0 0 0 10px;
    padding: 0 5px;
}

.centerText {
    text-align: center;
    width: 100%;
    font-size: 15px;
    font-family: 'nexabold';
    color: #8a8a8a;
}
.creativeImgView .creativeImg {
    height: 150px;
    background: #fff;
    /* border: 1px solid #ddd; */
    border-radius: 3px;
    /* display: grid; */
    /* justify-content: center; */
    /* align-items: start; */
    padding: 2px;
    /* overflow: hidden; */
}
.creativeImgView .creativeImg img {
    object-fit: scale-down;
    height: 100%;
}
.colorBlack {
    color: #070707 !important;
    /* font-family: 'nexaxbold' !important; */
}
.upload-displayL a {
    cursor: pointer;
    text-align: center;
    display: block;
    margin: 0 10px;
    width: 100%;
}
.upload-displayL .upload-img.editupload.end-md {
    float: right;
    color: #000;
    font-size: 12px;
    margin-top: 4px;
}
.upload-images-Btn {
    background: #FF9406;
    max-width: 200px;
    color: #fff;
    position: relative;
    border-radius: 3px;
    cursor: pointer;
    padding: 10px 25px;
}
.upload-images-Btn label {
    color: #fff;
    text-align: center;
    margin: 0;
}
.upload-images-Btn input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
}
.brand-general a.viewwLink {
    margin-bottom: 10px;
}
.brand-general .table-inventory table.table tr td {
    padding: 12px 15px;
}
.inventory-overHead.campaignaglin p, .inventory-overHead.campaignaglin h3 {
    /* color: #8a8a8a; */
    display: inline-block;
    margin: 0 6px 0 0;
    padding: 0;
    line-height: normal;
    /* font-family: 'nexaregular' !important; */
    font-weight: normal;
    /* font-size: 13px; */
    text-transform: none;
}
.wisr-option label {
    font-size: 20px !important;
}
.wisr-option.active label {
    font-size: 20px !important;
    color: #070707 !important;
}

.wisr-option .inventory-overHead p {text-transform: none;}

.custom-w{
    width: 200px;
}
.filterDropDown-inventory {
    right: auto !important;
    top: 16% !important;
}


.campaignSelect input {
    margin: 0 15px 0 0;
}
ul.tabsinventory.parentTab li a {
    text-transform: none;
    font-size: 18px;
}
ul.tabsinventory.parentTab {
	margin: 0;
	width: 100%;
}

ul.tabsinventory.parentTab li {
    margin-bottom: 10px;
}

.graphCanvas canvas {
    padding: 0px !important;
}

label.blightcolor {
    width: 100%;
    font-size: 14px;
    color: #8A8A8A;
    font-family: 'nexaregular';
}
.top-performing ul.tabsinventory {
    margin-bottom: 0;
}
.campaignViewimg {
    padding: 0;
}

.campaignViewimg {
    padding: 0;
    max-width: 500px;
}
.campaignViewimg span.close.toggle {
    top: -36px;
    opacity: 1;
    right: -12px;
}
.campaignViewimg  span.close.toggle .close:before, .campaignViewimg  span.close.toggle .close:after {
    background: #fff;
    width: 18px;
}

.campaignViewimg .popup-body .col-xl-12.col-lg-12.col-md-12.col-sm-12.col-xs-12 {
    padding: 0;
    margin: 0;
}

span.trashIcon {
    margin-top: 14px;
    float: left;
}
span.trashIcon img {
	width: 17px;
}
.social-general.forminpt.socialmarge {
	position: relative;
}
.social-general.forminpt.socialmarge .trashIcon {
	border: 0;
	padding: 0;
	position: absolute;
	right: 15px;
	top: 1px;
	z-index: 1;
	margin: 0;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a {
	color: #7a7a7b;
	border: 0;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a span {
	border: 2px dashed #7a7a7b;
	color: #7a7a7b;
	left: 0;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.active span {
	background: #fff;
	border-color: #fff;
	color: #FE6E00;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.active {
	background: transparent;
	color: #fff;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a:hover {
	background: transparent;
}
.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.complete span {
	border: 1px solid #7a7a7b;
}

.sliderbar.updatebar.no_highlight.brandbar.createBrandBar ul li a.active.complete span {
	color: #FE6E00;
	border-color: #fff;
}
.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a svg path {
	stroke: #7a7a7b;
}
img.wavebotConfirm {
    position: fixed;
    bottom: 0;
    left: 0;
}
p.txGrey {
    color: #8A8A8A;
}
.campaignViewimg {
    padding: 0;
}
table.table.going-campaign {
	text-align: left;
	white-space: nowrap;
}
.letsGo {
	height: calc(100vh - 60px);
}
.searchCustome .searchinput.searchinput-fos {
    margin: -17px 10px 0 0;
}
.searchCustome .searchinput.searchinput-backendAdmin {
    width: 180px !important;
    margin: -14px 10px 0 0;
}
.searchCustome .searchinput.searchinput-backendAdmin input.search-box {
    margin: 0;
}
table.table.going-campaign td span img {
	width: 18px;
	margin: 0 0 0 0;
	max-width: initial;
}
.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.active.complete span {
    background: #fff !important;
    border-color: #fff !important;
}
.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.active.complete span:before, .sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.active.complete span:after {
    background: #FE6E00;
}
.sliderbar.onboarding-bar.newMvp-sidebar.campaignProgress ul li a.active.complete {
    color: #fff !important;
}
.pl1::placeholder {
	font-size: 15px !important;
}
.custom-select-image {
    display: grid;
    grid-template-columns: 70px 1fr;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    height: 45px;
    position: relative;
    padding-left: 40px;
}


.custom-select-image .css-yk16xz-control {
	border: 0;
	height: 43px;
	min-height: inherit !important;
	padding: 0;
	border-radius: 5px;
}
.custom-select-image input, .custom-select-image select {border: 0px !important;}
.custom-select-image input {
	padding: 10px 75px 10px 15px !important;
	height: 43px !important;
	border: 0;
}
.custom-select-image em {
    top: 10px;
}
.custom-select-image .verifyLoader {
    top: 12px;
}
.custom-select-image .custom-select {
	height: 100% !important;
}
.disabled-grey label:before {
    background: #eeeeee !important;
    border-color: #ddd !important;
}
.popup.campaignViewimg {
    padding: 0;
    max-width: 400px;
    background: #777;
}
.forminpt-bottom .forminpt {
    margin-bottom: 40px;
}
.tableSpacingtd thead tr th:nth-child(1), .tableSpacingtd tbody tr td:nth-child(1) {
    width: 5%;
}
.tableSpacingtd thead tr th:nth-child(2), .tableSpacingtd tbody tr td:nth-child(2) {
    width: 29%;
}
.tableSpacingtd thead tr th:nth-child(3), .tableSpacingtd tbody tr td:nth-child(3) {
    width: 18%;
}
.tableSpacingtd thead tr th:nth-child(4), .tableSpacingtd thead tr th:nth-child(5), .tableSpacingtd tbody tr td:nth-child(4), .tableSpacingtd tbody tr td:nth-child(5) {
    width: 24%;
}
.faq-view ul.tabsinventory li a {
    font-size: 13px;
    padding-bottom: 8px;
}
.faq-view ul.tabsinventory li {
    margin: 0 40px 35px 0;
}
.btn.btn-orange.button--loading.btn-disabled:focus, .btn.btn-orange.button--loading.btn-disabled:hover {
	color: #ff9406;
}
#campaignBudgetModal span.valError {
    padding: 0 18px;
    font-size: 13px;
    margin: 0 0 10px 0;
    /* float: right; */
}
.createCampaingTable .table-container .table thead tr th, .createCampaingTable .table-container .table tbody tr td {
	border-right: 1px solid #e9e9e9;
}
.createCampaingTable .table-container .table td {
	font-weight: normal;
	color: #919191;
	font-family: 'nexaregular', sans-serif;
	line-height: 20px;
}
.createCampaingTable .activeTd {
	background: #fe6e00 !important;
}
.createCampaingTable .activeTd .radioBtn label {
	color: #fff !important;
}
.createCampaingTable .activeTd .radioBtn label::before {
	border: 1px solid #fe6e00 !important;
	/* background: #000 !important; */
}
.createCampaingTable .radioBtn [type="radio"]:checked + label::before {
	border-color: #fe6e00;
	background: #fff;
	border: 1px solid #fff !important;
}
.createCampaingTable .radioBtn [type="radio"]:checked + label::after {
	background: #fe6e00;
}
.createCampaingTable .table-container .table tbody tr td .tdTx {
	color: #070707;
    font-weight: 600;
    font-family: 'nexabold';
}
.tdTx b {
	font-size: 10px;
}
.optionWisr {
	color: #070707;
	font-weight: 600;
	font-family: 'nexabold';
	font-size: 19px;
	line-height: 28px;
}
.optionWisr span {
	color: #53cca7;
}
.createCampaingTable .table-container .table tbody tr td .tdTx b {color: #67d2b1;}
.w60 {
	display: inline-block;
	width: 60%;
}
.tdTg {
	color: #67d2b1;
	font-size: 10px;
}
.rc-slider-handle-click-focused {display: none !important;}
@media screen and (max-width:1280px) {
    ul.tabsinventory.parentTab li a {
        font-size: 18px;
    }

    .htx2 {
        font-size: 18px;
    }
    .confirmation-box {
        padding: 0;
        max-width: 90%;
    }
.tableScroll {
	overflow-x: scroll;
}
table.table.going-campaign td span img {
	width: 18px;
	margin: 0 0 0 0;
	max-width: initial;
}
table.table.going-campaign {
	text-align: left;
	white-space: nowrap;
}
.table-inventory {
	width: 100%;
}
.close::before, .close::after {
	background: #fff;
}
.creativeImgView {
	grid-template-columns: repeat(2, 1fr);
}
.w90 {
	width: 100%;
	float: right;
}
.campaign-total h3 {
	font-size: 20px;
	width: 100%;
	white-space: normal;
}
span.other-arrow {
	margin: 0 0 0 4px;
}
.table-container .table thead tr th {
	white-space: nowrap;
}
.addAdmininput .tooltip {
	display: initial;
	white-space: nowrap !important;
}
.addAdmininput {
	overflow-x: visible !important;
}
.impressions-slider {
	margin-bottom: 20px;
}
.slick-slide .image {
    padding: 10px;
  }
  .slick-slide img {
    border: 5px solid #fff;
    display: block;
    margin: auto;
  }
  .slick-slide img.slick-loading {
    border: 0;
  }
  /* .slick-slider {
    margin: 30px auto 50px;
  } */
  .slick-dots {
    margin-left: 0;
  }
  .slick-thumb {
    bottom: -45px;
  }
  .slick-thumb li {
    width: 60px;
    height: 45px;
  }
  .slick-thumb li img {
    filter: grayscale(100%);
  }
  .slick-thumb li.slick-active img {
    filter: grayscale(0%);
  }


/* .inventory-overHead.box-design.campaign-total {
	margin-bottom: 20px;
} */
}

@media screen and (max-width:768px) {
    .taegetMobile .col-xl-6.col-lg-6.col-md-12.col-xs-12 {
        padding: 0 !important;
        margin-bottom: 10px;
    }
    .brand-general {
        margin-top: 20px;
        padding: 0;
    }
    .inventorySearch .searchinput {
        width: 200px;
    }
    .faq-view ul.tabsinventory {
        width: calc(100% - 200px);
    }
    .mm-b0 .forminpt {
        margin: 0;
    }
    .brandTeamMemberTable {
        width: 100%;
    }

}