@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,500;1,700&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";

/* CSS Document */
body {
  /*font-family: 'nexaregular', sans-serif;*/
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-size: 16px;
  background: #fff;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

ul,
ol {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

a {
  text-decoration: none;
  color: #3e8bff;
}

.container {
  width: 100%;
  max-width: 100%;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: #f8f8f8;
}

::-webkit-scrollbar-thumb {
  background: rgba(62, 66, 94, 0.25);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(62, 66, 94, 0.35);
}

.dnone {
  display: none !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.tx-orange {
  color: #FE6E00 !important;
  display: inline-block;
}

.w100 {
  width: 100% !important;
}

input[type=date]:invalid::-webkit-datetime-edit {
  color: red;
  background-color: red;
}

.pl {
  padding-left: 0;
}

.pr {
  padding-right: 0;
}

.ml {
  margin-left: 0 !important;
}

.mr {
  margin-right: 0 !important;
}

.fL {
  float: left;
}

.fR {
  float: right;
  text-align: right;
}

.fN {
  float: none !important;
}

.valError {
  font-size: 14px;
  font-weight: normal;
  display: block;
  margin-top: 7px;
  color: #ff4a4a;
}

.valError img {
  width: 14px;
  height: 14px;
  float: left;
  margin: -1px 4px 0 0;
}

.onFocus {
  border-color: #ffcbcb !important;
}

input,
select,
textarea {
  border: 1px solid #ddd;
  height: 45px;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  width: 100%;
  color: #171717;
  font-family: 'nexaregular';
  font-weight: normal;
  outline: none;
}

input:focus,
select:focus {
  border-color: #070707;
  color: #070707;
  /* border-radius: 5px; */
}

.btn:focus {
  border: none;
  border: 0px;
  color: #fff;
}

.btn-outline:focus {
  border-color: #ff9406;
  background: #ff9406;
  color: #fff;
}

.ptx2 {
  color: #8A8A8A;
  font-size: 15px;
  font-weight: normal;
}

.impressions-slider .ptx2 {
  white-space: normal;
}

select {
  background: url(../../images/down-arrow.png) 96% 50% no-repeat #fff;
  background-size: 13px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.mb40 {
  margin-bottom: 40px;
}

.mb20 {
  margin-bottom: 20px;
}

.mt-0 {
  margin-top: 0px;
  ;
}

.mt-10 {
  margin-top: 10px;
  ;
}

.mt-20 {
  margin-top: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.htx1,
.htx2 {
  font-family: 'nexaxbold', sans-serif;
  /* font-weight: normal; */
}

.htx1 {
  font-size: 20px;
}

.htx3 {
  font-size: 18px;
}

.btn-red-light {
  background: #FFEDED;
  color: #FF4A4A;
}

.btn-orange-light {
  background: #FFF1E1;
  color: #FE6E00;
}

.htx2 {
  font-size: 18px;
}

.ptx {
  font-size: 13px;
  color: #8A8A8A;
  margin-top: 5px;
  font-weight: normal;
}

.ptx1 {
  font-size: 14px;
  color: #8A8A8A;
}

:placeholder-shown {
  color: #d0d0d0;
  opacity: 1;
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #b9b9b8;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #b9b9b8;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #b9b9b8;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #b9b9b8;
}

/* .btn { transition: 0.5s; width: auto; text-transform: uppercase; min-width: 130px; font-family: 'nexabold', sans-serif; cursor: pointer; padding:10px 15px 7px 15px !important; border-radius: 5px; text-align: center; display: inline-block; font-weight: normal; font-size: 14px;height: 33px;
} */
.table-bordered td .btn.btn-red-light,
.table-bordered td .btn.btn-green-light,
.table-bordered td .btn.btn-orange-light {
  font-size: 13px !important;
}

.btn-orange {
  background: #FE6E00;
  color: #fff;
  border: 1px solid #FE6E00;
}

.btn-orange:hover,
.btn-orange:focus {
  background: #ff9406;
  color: #fff;
  border: 1px solid #ff9406;
}

.btn-outline {
  border: 1px solid #FE6E00;
  color: #FE6E00;
  background: transparent;
}

.tab-panels .btn {
  width: 100%;
}

.btn-disabled {
  color: #fff;
  background: #fed2b1;
  border-color: #fed2b1;
  pointer-events: none;
}

/******top part step css*******/
.sliderbar {
  background: #fff;
  padding: 20px 20px 35px 20px;
  margin-top: 0;
  height: calc(100vh - 0px);
  border-right: 1px solid #E9E9E9;
  width: 100%;
}

.sliderbar ul {
  margin: 50px 0 0 0;
  height: calc(100vh - 291px);
  overflow-x: auto;
  padding-top: 10px;
  font-family: 'nexa_boldregular', sans-serif;
}

.sliderbar ul li {
  margin: 0 0 30px 0;
  font-size: 16px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.sliderbar ul li a {
  color: #9c9c9c;
  padding-left: 35px;
  transition: 0.5s;
  text-transform: uppercase;
  /* font-weight: 500; */
  display: inline-block;
  width: 100%;
  font-size: 13px;
  font-family: 'nexabold', sans-serif;
}

.sliderbar ul li a span {
  position: absolute;
  left: 0;
  top: 0px;
  width: auto;
  height: auto;
  /* border-radius: 100%; */
  background: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: 0.5s;
}

.sliderbar ul li a span img {
  width: 35px;
  height: 35px;
  display: none;
}

.sliderbar ul li a:hover,
.sliderbar ul li a.active {
  color: #070707;
}

.sliderbar ul li a:hover span svg path,
.sliderbar ul li a:hover span svg rect,
.sliderbar ul li a:hover span svg circle {
  stroke: #FE6E00;
}

.sliderbar ul li a.active span svg path,
.sliderbar ul li a.active span svg rect,
.sliderbar ul li a.active span svg circle {
  stroke: #FE6E00;
}

.sliderbar ul li a:hover span,
.sliderbar ul li a.active span {
  /* background: #070707; */
  /* color: #fff; */
  /* border-color: #070707; */
  /* z-index: 1; */
}

/***on onboarding sidebar***/
.onboarding-bar.sliderbar {
  background: #fff;
  padding: 20px 20px 35px 20px;
  margin-top: 0;
  height: calc(100vh - 0px);
  border-right: 1px solid #E9E9E9;
  width: 80%;
}

.onboarding-bar.sliderbar ul {
  margin: 50px 0 0 0;
  height: calc(100vh - 291px);
  overflow-x: auto;
  padding-top: 10px;
  font-family: 'nexa_boldregular', sans-serif;
}

.onboarding-bar.sliderbar ul li {
  margin: 0 0 45px 0;
  font-size: 16px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.onboarding-bar.sliderbar ul li a {
  color: #9c9c9c;
  padding-left: 45px;
  transition: 0.5s;
  text-transform: uppercase;
  /* font-weight: 500; */
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-family: 'nexabold', sans-serif;
}

.onboarding-bar.sliderbar ul li a.complete {
  color: #2EC193 !important;
}

.onboarding-bar.sliderbar ul li a.complete span {
  background: transparent !important;
  color: #fff !important;
  border-color: transparent !important;
  z-index: 1;
  font-size: 0;
}

.onboarding-bar.sliderbar ul li a span {
  position: absolute;
  left: 0;
  top: -6px;
  width: 30px;
  height: 30px;
  border: 2px dashed #9c9c9c;
  border-radius: 100%;
  background: #fff;
  display: grid;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: 0.5s;
  padding: 6px 0;
  font-size: 15px;
}

.onbodingbar.sliderbar ul li a.active {
  color: #2b2b2b;
}

.onbodingbar.sliderbar ul li a.active span {
  background: #070707;
  color: #fff;
  border-color: #070707;
  z-index: 1;
}

.onboarding-bar.sliderbar ul li a.complete span img {
  width: 30px;
  height: 30px;
  float: left;
  z-index: 0;
  position: relative;
  margin: -7px 0 0 1px;
  display: block !important;
}

.onboarding-bar.sliderbar ul li a span img {
  display: none;
}

.onboarding-bar.sliderbar ul li ul {
  margin: 10px 0 0 0;
  padding: 0;
  height: auto;
}

.onboarding-bar.sliderbar ul li ul li {
  margin: 17px 0 0px 0;
}

.onboarding-bar.sliderbar ul li ul li a {
  text-transform: none;
  font-size: 15px;
  font-weight: normal;
  font-family: 'nexaxbold', sans-serif;
}

.onboarding-bar.sliderbar ul li a:hover span img,
.sliderbar ul li a.active span img {
  display: none;
}

.scho-spec.scho-spec-img {
  min-height: 750px;
  display: grid;
  justify-content: center;
  align-items: center;
}

/***on onboarding sidebar***/
/******top part step css*******/
.updatebar.sliderbar {
  background: #FE6E00;
  padding: 20px 0px 35px 0px;
  margin-top: 0;
  height: calc(100vh - 0px);
  border-right: 1px solid #E9E9E9;
  width: 350px;
}

.updatebar.sliderbar ul {
  margin: 50px 0 0 0;
  height: calc(100vh - 291px);
  overflow-x: auto;
  padding-top: 10px;
  font-family: 'nexa_boldregular', sans-serif;
}

.updatebar.sliderbar ul li {
  margin: 0;
  font-size: 16px;
  position: relative;
  display: inline-block;
  width: 100%;
}

.updatebar.sliderbar ul li a {
  color: #ffb780;
  padding: 17px 20px;
  padding-left: 50px;
  transition: 0.5s;
  text-transform: uppercase;
  /* font-weight: 500; */
  display: inline-block;
  width: 100%;
  font-size: 14px;
  font-family: 'nexabold', sans-serif;
}

.updatebar.sliderbar ul li a span {
  position: absolute;
  left: 20px;
  top: 14px;
  width: auto;
  height: auto;
  /* border-radius: 100%; */
  background: transparent;
  display: grid;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  transition: 0.5s;
}

.updatebar.sliderbar ul li a span img {
  width: 35px;
  height: 35px;
  display: none;
}

.updatebar.sliderbar ul li a:hover,
.updatebar.sliderbar ul li a.active {
  color: #fff;
  background: #e36200;
}

.updatebar.sliderbar ul li a span svg path,
.updatebar.sliderbar ul li a span svg rect,
.updatebar.sliderbar ul li a span svg circle,
.updatebar.sliderbar ul li a span svg ellipse {
  stroke: #ffb780;
}

.updatebar.sliderbar ul li a:hover span svg path,
.updatebar.sliderbar ul li a:hover span svg rect,
.updatebar.sliderbar ul li a:hover span svg circle,
.updatebar.sliderbar ul li a:hover span svg ellipse {
  stroke: #fff;
}

.updatebar.sliderbar ul li a.active span svg path,
.updatebar.sliderbar ul li a.active span svg rect,
.updatebar.sliderbar ul li a.active span svg circle,
.updatebar.sliderbar ul li a.active span svg ellipse {
  stroke: #fff;
}

.updatebar.sliderbar ul li a:hover span,
.updatebar.sliderbar ul li a.active span {
  /* background: #070707; */
  /* color: #fff; */
  /* border-color: #070707; */
  /* z-index: 1; */
}

.updatebar.sliderbar ul li a.complete {
  color: #2EC193;
}

.updatebar.sliderbar ul li a.complete span {
  background: transparent;
  color: #fff;
  border-color: transparent;
  z-index: 1;
  font-size: 0;
}

.updatebar.sliderbar ul li a.complete span img {
  width: 30px;
  height: 30px;
  float: left;
  z-index: 0;
  position: relative;
  margin: -7px 0 0 1px;
  display: block !important;
}

.updatebar.sliderbar ul li ul {
  margin: 10px 0 0 0;
  padding: 0;
  height: auto;
}

.updatebar.sliderbar ul li ul li {
  margin: 17px 0 0px 0;
}

.updatebar.sliderbar ul li ul li a {
  text-transform: none;
  font-size: 15px;
  font-weight: normal;
  font-family: 'nexaxbold', sans-serif;
}

.updatebar.sliderbar ul li a:hover span img,
.sliderbar ul li a.active span img {
  display: none;
}

.hep {
  /* text-align: center; */
  /* display: block; */
  color: #070707;
  /* text-decoration: underline; */
  text-transform: uppercase;
  font-size: 15px;
  font-family: 'nexabold', sans-serif;
}

.wavebot {
  max-width: 240px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.rightPart {
  background: #fff;
  padding-top: 32px;
  height: calc(100vh - 10px);
  overflow-x: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.avefee {
  width: 114px;
}

.fixhight {
  height: calc(100vh - 4px);
  padding: 20px 0;
  overflow-x: auto;
}

.w80 {
  width: 60%;
  /* margin: 30px 0 0 0; */
  /* padding: 0 0px 0 0px; */
}

.forminpt label {
  /* width: 100%; */
  display: inline-block;
  font-size: 15px;
  margin-bottom: 5px;
  font-weight: normal;
  color: #8A8A8A;
  font-family: 'nexaregular', sans-serif;
}

.vertext {
  position: absolute;
  right: 18px;
  font-size: 13px;
  /* font-weight: normal; */
  text-transform: uppercase;
  top: 39px;
  color: #EF9E30;
  background: #fff;
  cursor: pointer;
}

.vertext.active {
  color: #FE6E00;
  font-weight: 600;
}

.vertext img {
  width: 16px;
  height: 16px;
  display: none;
  margin: -2px 0 0 0;
}

.vertext.complete img {
  display: block;
}

.vertext.complete {
  font-size: 0;
}

.formset {
  border: 1px solid #eee;
  height: 45px;
  border-radius: 5px;
  padding: 0;
  font-size: 15px;
  width: 100%;
}

.hummob img {
  display: none;
}

.sliderbar ul li a.complete {
  color: #2EC193;
}

.sliderbar ul li a.complete span {
  background: transparent;
  color: #fff;
  border-color: transparent;
  z-index: 1;
  font-size: 0;
}

/*.sliderbar ul li a.complete span::before { content: ""; position: absolute; left: 3.7px; top: 3.7px; width: 24px; height: 24px; background: #01ac12; border-radius: 100%; z-index: -1; transition: 0.5s; }*/
.backbtn {
  float: left;
  margin: 0 0 20px 0;
  color: #2b2b2b;
  font-family: 'nexa_boldregular', sans-serif;
  font-weight: 500;
}

.rightPart.noBack .backbtn {
  display: none;
}

.backbtn img {
  float: left;
  width: 20px;
  height: 20px;
  margin: -1px 10px 0 0;
}

.clearfix {
  display: block;
  content: "";
  clear: both;
}

.sliderbar ul li a.complete span img {
  width: 30px;
  height: 30px;
  float: left;
  z-index: 0;
  position: relative;
  margin: -7px 0 0 1px;
  display: block !important;
}

/*cutome css radio btn*/
.type-sch [type="radio"]:checked,
.type-sch [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.type-sch [type="radio"]:checked+label,
.type-sch [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #B6B6B6;
  font-family: 'nexa_lightregular', sans-serif;
  font-weight: bold;
}

.type-sch [type="radio"]:checked+label:before,
.type-sch [type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}

.type-sch [type="radio"]:checked+label::after,
.type-sch [type="radio"]:not(:checked)+label::after {
  content: "";
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: -1px;
  left: -1px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  border: 7px solid #FE6E00;
}

.type-sch [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.type-sch [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*cutome css radio btn*/
.type-sch {
  display: inline-flex;
  margin: 6px 0 0 0;
}

.type-sch .type1 {
  margin: 0 20px 0 0;
}

.sliderbar ul li ul {
  margin: 10px 0 0 0;
  padding: 0;
  height: auto;
}

.sliderbar ul li ul li {
  margin: 17px 0 0px 0;
}

.sliderbar ul li ul li a {
  text-transform: none;
  font-size: 15px;
  font-weight: normal;
  font-family: 'nexaxbold', sans-serif;
}

/*School Infrastructure*/
.mobileVery {
  position: fixed;
  right: -100%;
  top: 0;
  width: 350px;
  background: #fff;
  padding: 50px 30px;
  height: calc(100vh - 0px);
  z-index: 9;
  transition: 0.5s;
}

.inf-stru-img {
  /* background: #f5f5f5; */
  /* min-height: 250px; */
  margin-top: 10px;
  border-radius: 5px;
  /* display: inline-block; */
  /* width: 100%; */
}

.inf-stru-img img {
  width: 100%;
}

.tol-built {
  margin-top: 25px;
  display: grid;
  width: 100%;
  grid-template-columns: 220px 2fr 131px;
  justify-content: center;
  align-items: center;
  grid-column-gap: 15px;
}

.tol-built.clearfix {
  grid-template-columns: 1fr 1fr;
}

.tol-built p {
  float: left;
  font-size: 15px;
  color: #8A8A8A;
  font-weight: normal;
}

.tol-built span {
  font-size: 14px;
  border: 1px solid #ddd;
  height: 45px;
  border-radius: 5px;
  padding: 0;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tol-built span input {
  border: 0;
  height: 100%;
  width: calc(100% - 45px);
  padding: 5px 10px;
}

.tol-built span b,
.tol-built span strong {
  font-size: 14px;
  font-weight: 600;
  width: 45px;
  text-align: center;
}

.tol-built-in {
  margin: 0;
  display: inline-block;
  width: 100%;
}

.tol-built-in span {
  width: 100%;
  float: left;
  background: #e5e5e5;
  height: 4px;
  position: relative;
  border-radius: 50px;
  border: 0;
  padding: 0;
}

.tol-built-in span::before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  background: #070707;
  width: 40%;
  height: 6px;
  border-radius: 50px;
}

.tol-built-in span b,
.tol-built-in span strong {
  width: 27px;
  height: 27px;
  position: absolute;
  top: -11px;
  left: 39%;
  background: #fff;
  border-radius: 100%;
  cursor: pointer;
  border: 4px solid #070707;
}

.nu-in-dic {
  /* float: right; */
  display: grid;
  grid-template-columns: 15px 40px 15px;
  justify-content: end;
  align-items: center;
  grid-column-gap: 13px;
  /* width: 110px; */
  justify-items: center;
  padding: 0 16px;
  /* background: #000; */
  border: 1px solid #ddd;
  border-radius: 5px;
  background: #fff;
}

.nu-in-dic a {
  float: left;
  /* width: 22px; */
  /* height: 22px; */
  /* background: #2b2b2b; */
  border-radius: 100%;
  padding: 2px 1px;
  text-align: center;
}

.nu-in-dic a img {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
}

.nu-in-dic input {
  text-align: center;
  border: 0;
}

.facili-avai {
  margin-top: 60px;
}

.checkbox {
  float: right;
  margin-bottom: 0;
}

.checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox label {
  position: relative;
  cursor: pointer;
  font-size: 14px;
}

.checkbox label::before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #070707;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 8px;
  margin-top: -3px;
  border-radius: 4px;
}

.checkbox input:checked+label::after {
  content: '';
  display: block;
  position: absolute;
  top: -1px;
  left: 8px;
  width: 5px;
  height: 11px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.facili-avai-in {
  display: inline-block;
  width: 100%;
  margin-top: 15px;
}

.facili-avai1 {
  min-height: 110px;
  text-align: center;
  background: #fff;
  border: 1px solid #D9D9D9;
  margin-top: 15px;
  border-radius: 5px;
  display: grid;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  align-content: center;
  position: relative;
  cursor: pointer;
}

.facili-avai1.complete {
  border-color: #2EC193 !important;
  background: #f7fdfb !important;
}

.facili-avai1.active,
.facili-avai1:hover {
  background: #f0f0f0;
  border: 1px solid #222;
}

.htx1 img {
  float: left;
  margin: -5px 10px 0 0;
}

/********Classrooms General Page*********/

.class-grade {
  background: #fff;
  border: 1px solid #E9E9E9;
  border-radius: 4px;
  padding-bottom: 0;
  margin-top: 20px;
}

.class-grade-top ul {
  padding: 0px 0px 10px 0px;
  grid-column-gap: 0;
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  display: inline-flex;
}

.class-grade-top ul li a {
  padding: 10px 16px;
  display: inline-block;
  width: 100%;
  color: #cdcdcd;
  position: relative;
  border: 1px solid #E9E9E9;
  font-weight: normal;
}

.class-grade-top ul li:first-child a {
  border-radius: 5px 0 0 0;
}

.class-grade-top ul li:last-child a {
  border-radius: 0px 5px 0 0px;
}

.class-grade-top ul li a.complete {
  background: #f7fdfb;
  border: 1px solid #2EC193;
  margin: 0;
  /* border-right: 0; */
  z-index: 1;
  color: #2EC193;
}

.class-grade-top ul li {
  margin: 0;
}

.class-grade-top ul li a.active,
.class-grade-top ul li a:hover {
  color: #070707;
  border: 1px solid transparent;
}

.class-grade-top ul li a:hover {
  color: #070707;
  border: 1px solid #070707;
}

.class-grade-top ul li a.active::before {
  content: "";
  position: absolute;
  left: 0;
  background: #070707;
  height: 4px;
  top: -1px;
  width: 100%;
  border: 0;
}

.facili-avai .class-grade-mid {
  padding: 30px 10px;
}

.class-grade-mid {
  padding: 30px 20px;
}

.class-grade-mid .tol-built {
  margin: 10px 0 0px 0;
}

.class-grade-mid .tol-built p {
  font-size: 16px;
}

.class-grade-top ul li a:hover.complete {
  color: #000;
}

.facili-avai1 h3 {
  font-size: 16px;
  font-weight: normal;
  font-family: 'nexabold', sans-serif;
}

.facili-avai1 p {
  font-size: 15px;
}

.class-grade-mid .nu-in-dic {
  grid-template-columns: 12px 30px 12px;
  grid-column-gap: 13px;
  width: auto;
}

.class-grade-mid .nu-in-dic input {
  padding: 0;
}

/********thank you page*********/
.thank-sign img {
  margin-bottom: 20px;
  width: 130px;
}

.poximg {
  position: absolute;
  left: 0;
  bottom: 0;
  max-width: 280px;
}

.thank-sign {
  display: grid;
  align-items: center;
  justify-content: center;
  align-content: center;
  justify-items: center;
  height: 100vh;
  max-width: 750px;
  margin: auto;
  text-align: center;
  width: 100%;
}

.thank-sign h4 {
  font-size: 28px;
  margin-bottom: 15px;
}

.thank-sign p {
  margin-bottom: 25px;
  font-size: 17px;
  color: #8C8C8C;
}

.thank-sign .btn {
  width: 230px;
  font-size: 15px;
}

/*school amenitiees page*/
.dele-invetry {
  border: 1px solid #e7e7e7;
  border-radius: 5px;
  padding: 15px;
  position: relative;
  margin-bottom: 11px;
}

.dele-invetrybox {
  height: calc(100vh - 210px);
  overflow-x: auto;
}

.dele-invetry h3 {
  font-size: 16px;
  margin-bottom: 5px;
}

.dele-invetry p {
  font-size: 12px;
  font-weight: 500;
}

.dele-invetry p b {
  margin: 0 0 0 10px;
}

.remove-invetry {
  position: absolute;
  right: 16px;
  top: 20px;
  width: 18px;
}

.star-icon .checkcom.star {
  display: block;
  width: 16px;
  position: absolute;
  top: 9px;
  left: 10px;
}

.remove-icon {
  width: 18px;
  position: absolute;
  right: 10px;
  top: 7px;
}

.tabremove .remove-icon {
  position: initial;
  display: none;
  transition: 0.5s;
}

.attri-add .tabremove .remove-icon {
  display: inline-block;
}

.tabremove img {
  width: 14px;
  display: inline-block;
  margin: 0 0 0 5px;
  height: 14px;
}

.tabset>label.tabremove:hover .remove-icon {
  display: block;
  position: absolute;
  right: 0;
  top: 10px;
}

.facili-avai1 .remove-icon {
  display: none;
}

.facili-avai1.complete.remove .remove-icon {
  display: block;
}

.facili-avai1.complete.remove .checkcom {
  display: none;
}

.facili-avai1.complete.remove .checkcom.remove-icon {
  display: block;
  width: 20px;
}

.facili-avai1.complete.remove .checkcom.star {
  display: block;
  right: auto;
  left: 10px;
  width: 17px;
}

.facili-avai1.complete .checkcom.remove-icon,
.facili-avai1.complete .checkcom.star {
  display: none;
}

.class-grade-top {
  display: inline-grid;
}

.tabbed-content.tabs-side .item.active {
  display: block;
}

.tabbed-content.tabs-side .item {
  margin-left: 200px;
  display: none;
}

.tabblank {
  background: #F8F8F8;
  text-align: center;
  min-height: 680px;
  border-radius: 5px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.avera-anual {
  padding-top: 45px;
  height: calc(100vh - 60px);
  width: 85%;
}

.avera-anualbox {
  background: #F8F8F8;
  padding: 25px;
  border-radius: 5px;
}

.avera-anualbox h3 {
  font-size: 15px;
  /* font-weight: normal; */
}

.avera-school {
  font-family: 'nexa_lightregular', sans-serif;
  margin-top: 25px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  justify-content: end;
  align-items: end;
}

.avera-youschol span {
  font-weight: 600;
  width: 100%;
  display: block;
  font-size: 13px;
  font-family: 'nexaregular', sans-serif;
}

.avera-youschol b {
  font-size: 17px;
  margin-top: 6px;
  display: block;
  font-family: 'nexabold', sans-serif;
  /* font-weight: normal; */
}

.avera-youschol {
  background: #FF9406;
  color: #fff;
  padding: 15px;
  border-radius: 5px;
}

.avera-youschol:nth-child(2) {
  background: #f8f8f8;
  min-height: 200px;
}

.avera-youschol:nth-child(2) span {
  color: #8A8A8A;
}

.avera-youschol:nth-child(2) b {
  color: #070707;
}

.avera-anualbox p {
  color: #8F8F8F;
  font-size: 14px;
  margin-top: 20px;
  /* font-family: 'nexa_lightregular'; */
  font-weight: 600;
}

.avera-anualbox p b {
  color: #070707;
}

.facili-avai1.add {
  border-color: #FE6E00;
}

.facili-avai1.add img {
  width: 22px;
  height: 21px;
}

.facili-avai1 .checkcom {
  display: none;
}

.facili-avai1.complete .checkcom {
  display: block;
  width: 22px;
  position: absolute;
  right: 10px;
  top: 6px;
  height: 22px;
}

/****************Amenities School Specific**************/
.school-specify {
  margin-top: 25px;
  display: inline-block;
  width: 100%;
}

.attbox-btn {
  background: transparent;
  border: 1px solid #FF9406;
  color: #FF9406;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  font-weight: normal;
}

.attbox {
  display: grid;
  grid-template-columns: 1fr 130px;
  justify-content: center;
  align-items: center;
  grid-column-gap: 20px;
  margin-bottom: 15px;
}

.attbox select {
  background-position: 85% 50%;
}

.attbox label {
  color: #8A8A8A;
  font-family: 'nexaregular', sans-serif;
}

.add-inve {
  float: right;
  color: #FE6E00;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 4px;
  font-weight: 600;
}

.scho-spec {
  background: #F8F8F8;
  padding: 15px;
  border-radius: 5px;
}

.add-inve.adbrose {
  width: auto;
  position: relative;
  text-align: right;
}

.add-inve.adbrose input {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.sch-add-img1 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 15px;
  margin-top: 10px;
  width: 100%;
}

.sch-add-pic img {
  width: 100%;
  height: 55px;
  border-radius: 5px;
}

/****************Student Life Activities**************/
.sele-calss1.complete a {
  color: #FE6E00;
}

.sele-calss1.add-activitie .remove-icon {
  width: 15px;
  right: 5px;
  top: 5px;
}

.sele-calss1.add-activitie {
  border: 0px;
  padding: 0;
  position: relative;
}

.sele-calss1.add-activitie .star {
  width: 15px;
  position: absolute;
  right: 30px;
  top: 12px;
}

.sele-calss1.add-activitie input {
  text-align: center;
  padding: 0 55px 0 15px;
}

.cust-btn .btn {
  width: 100%;
}

.sliderbar ul li a:hover span img,
.sliderbar ul li a.active span img {
  display: none;
}

.checkbox.checkbox1 {
  float: left;
  margin: 35px 0 25px 0;
}

.sele-calss {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 13px;
  grid-row-gap: 13px;
}

.sele-calss1 {
  border: 1px solid #E9E9E9;
  background: #fff;
  padding: 13px;
  border-radius: 5px;
  text-align: center;
}

.sele-calss1 h4 {
  font-weight: 500;
  font-family: 'nexabold', sans-serif;
}

.sele-calss1.active {
  border: 1px solid #2EC193;
  color: #2EC193;
  background: #f7fdfb;
}

.sele-calss1.complete {
  border: 1px solid #FE6E00;
  color: #FE6E00;
  background: #fff;
}

/*.student-img { float: right; width: 90%; }*/
.student-img img {
  width: 100%;
}

.activies-box {
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  min-height: 585px;
}

.activies-top .htx2 {
  font-size: 17px;
  font-weight: normal;
}

.activies-top {
  padding: 15px 20px;
  display: inline-block;
  width: 100%;
}

.activies-top .checkbox {
  margin: 0;
}

.activies-mid ul li {
  padding: 15px 20px;
  border-top: 1px solid #ddd;
  font-size: 16px;
  font-family: 'nexabold', sans-serif;
  font-weight: 500;
  color: #8C8C8C;
  transition: 0.5s;
  position: relative;
}

.activies-mid ul li.active .star {
  position: absolute;
  right: 15px;
  width: 16px;
}

.activies-mid ul li .addacbtn {
  float: right;
  color: #FE6E00;
  text-transform: uppercase;
  font-size: 14px;
  display: none;
  transition: 0.5s;
}

.activies-mid ul li:hover,
.activies-mid ul li.active {
  background: #FFF8F2;
  color: #070707;
}

/*.activies-mid ul li.active .addacbtn img {
	width: 17px;
	position: absolute;
	right: 30px;
}*/
.activies-mid ul li.active .addacbtn {
  display: block;
  right: 40px;
  position: absolute;
  top: 15px;
}

.activies-mid ul li:hover .addacbtn {
  display: block;
}

.activies-mid {
  display: inline-block;
  width: 100%;
}

.activies-mid label {
  font-size: 14px;
  background: #fff;
  border: 1px solid #E9E9E9;
  border-radius: 5px;
  padding: 8px 10px;
  display: inline-block;
  margin: 0 0 8px 5px;
  font-family: 'nexabold', sans-serif;
  font-weight: normal;
  color: #8A8A8A;
  word-break: break-word;
}

.activies-mid.activies-mid1 {
  padding: 0 13px;
  border-top: 1px solid #ddd;
  padding-top: 15px;
}

.activies-mid label img {
  width: 13px;
  height: 13px;
}

.activies-mid label a {
  float: right;
  margin: 0 0 0 10px;
}

/* .activies-mid ul li.added-activates img.star {
  top: 15px !important;
  right: 37px !important;
} 
.activies-mid ul li.added-activates a.remove-icon {
  top: 15px !important;
  right: 13px !important;
  width: 15px;
}*/

/****************Student Activity Details**************/
/*.ava-brad .content {
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  padding: 2rem;
  width: 400px;
}*/
.activies-mid ul li.active .remove-icon {
  right: 41px;
  top: 15px;
  width: 17px;
}

.forminpt .checkbox label {
  font-weight: 600;
  color: #070707;
  font-size: 15px;
}

.tablestar img {
  width: 17px;
  height: 17px;
  float: left;
  margin: -3px 8px 0 0;
}

.ava-brad .switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 24px;
}

.ava-brad .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.ava-brad .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #E4E4E4;
  transition: 0.4s;
}

.ava-brad .slider::before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

.ava-brad input:checked+.slider {
  background-color: #2EC193;
}

/*.ava-brad input:focus + .slider {
  box-shadow: 0 0 0 4px rgba(21, 156, 228, 0.7);
  outline: none;
}*/

.ava-brad input:checked+.slider::before {
  transform: translateX(11px);
}

.ava-brad .slider.round {
  border-radius: 34px;
}

.ava-brad .slider.round:before {
  border-radius: 50%;
}

.activi-first-box h3 {
  float: left;
  font-size: 18px;
}

.activi-first-box .ava-brad {
  float: right;
  display: flex;
  align-items: center;
}

.ava-brad b,
.ava-brad strong {
  margin: 0 14px 0 0;
  font-size: 14px;
  font-family: 'nexaxbold', sans-serif;
  font-weight: normal;
}

.activi-first-box {
  display: inline-block;
  width: 100%;
  border-top: 1px solid #ddd;
  padding: 20px 0;
}

.stutable.stutable1 {
  background: #fff;
  border-radius: 0px;
  margin-top: -1px;
}

.stutablemain {
  margin: 25px 0;
}

.stutable {
  border: 1px solid #E9E9E9;
  border-radius: 5px 5px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background: #F8F8F8;
  position: relative;
}

.stutable .remove-icon {
  top: 12px;
  right: 12px;
}

.stutablebox {
  padding: 15px 20px;
}

.stutablebox h4 {
  text-transform: uppercase;
  font-size: 13px;
  color: #5C5C5C;
  line-height: 17px;
  font-weight: 500;
  font-family: 'nexabold', sans-serif;
}

.stutablebox h4 img {
  width: 31px;
  height: 15px;
}

.stutablebox .checkbox.checkbox1 {
  margin: 0;
}

.stutablebox .add-inve {
  float: none;
}

.stutablebox .checkbox input:checked+label::before {
  background: #FE6E00;
  border-color: #FE6E00;
}

.stutablebox .checkbox input:checked+label::after {
  top: -1px;
  left: 7px;
  width: 4px;
  height: 9px;
  border-color: #fff;
}

/*.checkbox input:checked + label::after { top: 0px; left: 7px; width: 4px; height: 9px; border: solid #fff; }*/
.checkbox input:checked+label::before {
  background: #fe6e00;
  border-color: #fe6e00;
}

.forminpt .checkbox label a {
  color: #fe6e00;
  margin: 0 0 0 5px;
}

/*.sele-check .dropdown {
  position: absolute;
  top:50%;
  transform: translateY(-50%);
}*/

.sele-check a {
  color: #fff;
}

.sele-check .dropdown dd,
.sele-check .dropdown dt {
  margin: 0px;
  padding: 0px;
  float: right;
}

.sele-check .dropdown ul {
  margin: -1px 0 0 0;
}

.sele-check .dropdown dd {
  position: relative;
}

.sele-check .dropdown a,
.sele-check .dropdown a:visited {
  color: #070707;
  text-decoration: none;
  outline: none;
  font-size: 12px;
}

.sele-check .dropdown dt a {
  display: block;
  padding: 8px 20px 5px 10px;
  min-height: 25px;
  line-height: 24px;
  overflow: hidden;
  width: 240px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 15px;
  background: url(../../images/down-arrow.png) 93% 49% no-repeat #fff;
}

.sele-check {
  position: relative;
  z-index: 1;
}

.sele-check .dropdown dt a span,
.sele-check .multiSel span {
  cursor: pointer;
  display: inline-block;
  padding: 0 3px 2px 0;
}

.sele-check .dropdown dd ul {
  background: #fff;
  color: #070707;
  display: none;
  left: 0px;
  padding: 5px 15px 5px 5px;
  position: absolute;
  top: 45px;
  width: 240px;
  list-style: none;
  height: 150px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.sele-check .dropdown dd ul li {
  padding: 7px 10px;
  font-size: 14px;
}

.sele-check .dropdown dd ul li input {
  width: auto;
  height: auto;
  margin: 0 7px 0 0;
}

.sele-check .dropdown dt a p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.sele-check .dropdown span.value {
  display: none;
}

.sele-check .dropdown dd ul li a {
  padding: 5px;
  display: block;
}

.sele-check .dropdown dd ul li a:hover {
  background: #fff;
}

.sele-check button {
  background: #6BBE92;
  width: 302px;
  border: 0;
  padding: 10px 0;
  margin: 5px 0;
  text-align: center;
  color: #fff;
  font-weight: bold;
}

.sele-check .dropdown1 dd,
.sele-check .dropdown1 dt {
  margin: 0px;
  padding: 0px;
  float: right;
}

.sele-check .dropdown1 ul {
  margin: -1px 0 0 0;
}

.sele-check .dropdown1 dd {
  position: relative;
}

.sele-check .dropdown1 a,
.sele-check .dropdown1 a:visited {
  color: #070707;
  text-decoration: none;
  outline: none;
  font-size: 12px;
}

.sele-check .dropdown1 dt a {
  display: block;
  padding: 8px 20px 5px 10px;
  min-height: 25px;
  line-height: 24px;
  overflow: hidden;
  width: 240px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 15px;
  background: url(../../images/down-arrow.png) 93% 49% no-repeat #fff;
}

.sele-check .dropdown1 dt a span,
.sele-check .multiSe1n span {
  cursor: pointer;
  display: inline-block;
  padding: 0 3px 2px 0;
}

.sele-check .dropdown1 dd ul {
  background-color: #fff;
  color: #070707;
  display: none;
  left: 0px;
  padding: 5px 15px 5px 5px;
  position: absolute;
  top: 45px;
  width: 240px;
  list-style: none;
  height: 150px;
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.sele-check .dropdown1 dd ul li {
  padding: 7px 10px;
  font-size: 14px;
}

.sele-check .dropdown1 dd ul li input {
  width: auto;
  height: auto;
  margin: 0 7px 0 0;
}

.sele-check .dropdown1 dt a p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.sele-check .dropdown1 span.value {
  display: none;
}

.sele-check .dropdown1 dd ul li a {
  padding: 5px;
  display: block;
}

.sele-check .dropdown1 dd ul li a:hover {
  background-color: #fff;
}

/******************Onboarding Summary*****************/
.onboding {
  width: 70%;
}

.personal-box-top .htx2.mb20 {
  margin: 0;
}

.personal-box {
  background: #F8F8F8;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 25px;
}

.personal-box-bot .inf-stru-img {
  width: 100%;
  margin-bottom: 20px;
}

.editbtn {
  font-size: 16px;
  text-transform: uppercase;
  color: #070707;
  font-weight: 500;
  font-family: 'nexa_boldregular', sans-serif;
  display: flex;
  width: auto;
  align-content: center;
  align-items: center;
  justify-content: end;
}

.editbtn img {
  margin: -1px 5px 0 0;
}

.personal-box-top {
  border-bottom: 1px solid #eee;
  padding: 10px 0 15px 0;
}

.personal-bottom {
  padding: 20px 0;
}

.personal-box-bot p,
.personal-box-bot b,
.personal-box-bot strong {
  padding: 10px 0;
  width: 100%;
  display: inline-block;
  font-size: 14px;
}

.personal-box-bot p {
  color: #8A8A8A;
  font-weight: normal;
  font-family: 'nexaregular', sans-serif;
}

.personal-box-bot b,
.personal-box-bot strong {
  color: #070707;
  font-weight: 600;
  font-family: 'nexaregular', sans-serif;
}

.activi-box-open {
  display: none;
}

.personal-bottom .class-grade {
  margin-top: 15px;
}

.personal-bottom .class-grade .htx2 {
  margin-bottom: 10px;
}

.personal-box-bot {
  font-family: 'nexa_boldregular', sans-serif;
  font-weight: 300;
}

.ovelap.active {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 5;
}

.school-specify.relative {
  position: relative;
}

/* .tabs-side.ovelaper.active::before{
  background-color: #0000008a;
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 9;
  width: 100%;
  height: 100%;
  content:"";
  border-radius: 3px;
} */
.tabs-side.ovelaper.active::after {
  position: fixed;
  content: "Upload in progress, please wait";
  top: 50%;
  left: 0%;
  background-color: black;
  color: #ffffff;
  padding: 16px 30px;
  border-radius: 6px;
  z-index: 10;
  right: 0%;
  margin: 0 auto;
  width: fit-content;
}

.popup-body .facili-avai-in {
  max-height: calc(100vh - 250px);
  overflow-x: auto;
  margin: 0;
  padding: 0;
}

.popup-header h3 {
  padding: 0 8px;
}

.popup-body .facili-avai-in h3 {
  font-size: 17px;
  color: #070707;
  font-weight: 500;
}

.popup-body .facili-avai-in .facili-avai1 h3 {
  font-size: 15px;
}

.popup-body .facili-avai-in .facili-avai1 {
  min-height: 100px;
}

.popup-header .htx2 {
  text-transform: none;
  color: #070707;
  font-size: 19px;
}

.popup-body .facili-avai-in .facili-avai-in {
  max-height: initial;
}

.content.mCustomScrollbar.fixhight .rightPart {
  overflow: visible;
}

.invetory-list .onboding {
  width: 90%;
}


.hide {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
  -moz-transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
  -o-transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
  -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8) !important;
}

.close {
  position: absolute;
  right: 7px;
  top: 0;
  width: 45px;
  height: 45px;
  font-size: 0;
  cursor: pointer;
}

.close:before,
.close:after {
  content: '';
  position: absolute;
  background: #767676;
  -moz-border-radius: 1px;
  -webkit-border-radius: 1px;
  -o-border-radius: 1px;
  border-radius: 1px;
  left: 14px;
  right: 14px;
  top: 30px;
  height: 1.5px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  -webkit-transition: all 100ms ease-in;
  -o-transition: all 100ms ease-in;
}

.close:after {
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: #fff;
  -moz-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  max-width: 700px;
  box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.09), 0px 11px 12px 7px rgba(0, 0, 0, 0.02);
  -moz-transition: all 120ms;
  -webkit-transition: all 120ms;
  -o-transition: all 120ms;
  transition: all 120ms;
  z-index: 99;
  padding: 25px;
  width: 100%;
}

/*added new css 26 july*/
.sele-calss1.active .star {
  width: 15px;
  position: absolute;
  right: 30px;
  top: 12px;
}

#login-form-parent .forminpt input,
.login-form input {
  margin: 0;
  height: 45px;
}

.mobileNoIcon .custom-select .css-1hwfws3 {
  height: 100% !important;
}

.updatebar.sliderbar ul li a:hover span svg.logoutsvg path,
.updatebar.sliderbar ul li a:hover span svg.logoutsvg rect,
.updatebar.sliderbar ul li a:hover svg.logoutsvg svg circle {
  stroke: none;
}

.updatebar.sliderbar ul li a:hover span svg.logoutsvg path {
  fill: #FE6E00;
}

.sc-ad-rightmenu ul {
  float: right;
  display: flex;
  align-items: center;
}

.sc-ad-rightmenu ul li {
  margin: 0 0 0 25px;
  position: relative;
}

.sc-ad-rightmenu .dropdownShow.showSearchbox.active {
  right: 0;
  top: 36px;
}

.sc-ad-rightmenu li img {
  width: 21px;
  height: 21px;
}

.sc-ad-rightmenu li a {
  color: #070707;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  position: relative;
  font-family: 'nexaregular';
  display: inline-flex;
  width: 100%;
}

/*css start new dashboard school admin*/

.campaign-box {
  margin-top: 20px;
  background: transparent;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  display: inline-block;
  min-height: 190px;
  width: 100%;
  transition: 0.5s;
}

/*.campaign-box:hover {
  background: #efedff;
  cursor: pointer;
}*/
.campaign-bg1 {
  background: #EFEDFF;
}

.campaign-bg2 {
  background: #EBFFF2;
}

.campaign-bg3 {
  background: #ECFBFF;
}

.campaign-bg4 {
  background: #f6f6f6;
}

.campaign-box .camgtoplogo {
  width: 35px;
  height: 35px;
  background: #fff;
  display: grid;
  border-radius: 5px;
  /* box-shadow: 2px 2px 1px #ddd; */
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.campaign-box h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: 'nexaxbold';
  margin: 25px 0 10px 0;
}

.campaign-box p {
  font-size: 11px;
  float: left;
  width: auto;
  margin: 0 15px 0 0;
  color: #979799;
  font-family: 'nexabold';
}

.campaign-box p b {
  clear: both;
  width: 100%;
  display: block;
  font-size: 13px;
  color: #070707;
  margin-top: 3px;
}

.next-arow {
  position: absolute;
  right: 20px;
  bottom: 15px;
  width: 14px;
}

.bookingStatus {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 10px;
}

.booking1 {
  text-align: center;
  cursor: pointer;
}

.bookingTop {
  font-size: 11px;
  color: #8a8a8a;
  transition: 0.5s;
}

.bookingMid {
  min-height: 100px;
  transition: 0.5s;
  background: #f6f6f6;
  border-radius: 10px;
  position: relative;
  margin: 7px 0;
}

.bookingBot {
  transition: 0.5s;
  font-size: 13px;
  color: #8a8a8a;
  margin-top: 10px;
}

.bookingOver {
  background: #f7e7d2;
  /* min-height: 50px; */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 5px;
  transition: 0.5s;
}

.booking1.active .bookingBot {
  color: #000;
  font-family: 'nexaregular';
}

.booking-full {
  display: grid;
  /*grid-template-columns: 1fr 2px 1fr 2px 1fr 2px 1fr;*/
  width: 100%;
  position: relative;
  grid-column: 1 / span 12;
  margin-top: 20px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 0px 18px #ddd;
  margin-bottom: 10px;
  padding: 20px;
  grid-column-gap: 20px;
}

.booking-full1 b {
  font-size: 13px;
  margin-bottom: 10px;
  color: #8a8a8a;
  display: block;
}

.booking-full1 h2 {
  font-size: 14px;
  word-break: break-word;
}

.booking-border {
  height: 100%;
  width: 1px;
  background: #e9e9e9;
}

.booking-full1 h2 a {
  color: #fe6e00;
}

.booking1.active .bookingTop {
  color: #ff9406;
  font-family: 'nexaregular';
}

.booking1.active .bookingOver {
  background: #ff9406;
}

.booking1:hover .bookingTop {
  color: #ff9406;
  font-family: 'nexaregular';
}

.booking1:hover .bookingOver {
  background: #ff9406;
}

.booking1:hover .bookingBot {
  color: #000;
  font-family: 'nexaregular';
}

.booking-full:before {
  content: "";
  position: absolute;
  left: 79px;
  top: -10px;
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}

.Jan:before {
  left: 2%;
}

.Feb:before {
  left: 11%;
}

.Mar:before {
  left: 19%;
}

.Apr:before {
  left: 27%;
}

.May:before {
  left: 36%;
}

.Jun:before {
  left: 45%;
}

.Jul:before {
  left: 53%;
}

.Aug:before {
  left: 61%;
}

.Sep:before {
  left: 70%;
}

.Oct:before {
  left: 78%;
}

.Nov:before {
  left: 87%;
}

.Dec:before {
  left: 95%;
}

.schoolAdmin {
  grid-template-columns: 1fr 2px 1fr 2px 1fr;
}

.schoolPrincipal {
  grid-template-columns: 1fr 2px 1fr 2px 1fr 2px 1fr;
}

.blog-listing {
  background: #fff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  display: inline-block;
  padding: 20px 15px;
  width: 98%;
  margin-left: 2%;
  margin-bottom: 20px;
  min-height: 280px;
}

.blog-listing h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.blog-listingText {
  white-space: normal;
}

.slideshowSlider .slide img {
  border-radius: 5px;
}

.blog-listingText h3 {
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  width: 100%;
  font-family: 'nexabold';
}

.blog-listingText p {
  font-size: 14px;
  font-weight: normal;
  color: #7f7f7f;
  margin-top: 0;
  line-height: 22px;
  font-family: 'nexaregular';
}

.bloghead {
  margin-bottom: 15px;
}

.bloghead p {
  font-size: 13px;
  color: #909090;
}

.viewwLink {
  float: right;
  color: #FE6E00;
  text-transform: uppercase;
  font-size: 13px;
  margin-top: 4px;
  font-weight: 500;
  transition: 0.5s;
}

.viewwLink:hover {
  opacity: 0.6;
}

.blog-listingText li {
  font-size: 13px;
  margin-bottom: 9px;
  color: #8A8A8A;
  position: relative;
  padding-left: 17px;
  line-height: 22px;
  font-family: 'nexaregular';
}

.blog-listingText li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 6px;
  width: 6px;
  height: 6px;
  background: #8a8a8a;
  border-radius: 100%;
}

.brand-btn {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 15px;
  margin-top: 15px;
}

.brand-class .brand-btn {
  padding: 0 !important;
}

.brand-btn a {
  margin: 0;
}

.brand-class {
  padding: 20px;
  border: 1px solid #eee;
  border-radius: 5px 5px 0 0;
  margin-top: 21px;
  display: inline-block;
  width: 100%;
}

.approve-deadline {
  font-size: 13px;
  background: #ffeded;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  color: #ff4a4a;
  display: inline-block;
}

.brand-creat-img {
  margin-top: 20px;
  display: inline-block;
  width: 100%;
}

.brand-creat-img ul {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
}

.brand-creat-img ul li img {
  width: 100%;
  height: 80px;
}

.brand-creat-img ul li span {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: grid;
  justify-content: center;
  align-items: center;
  color: #fff;
  z-index: 2;
}

.brand-creat-img ul li {
  position: relative;
}

.brand-creat-img h3 {
  font-size: 13px;
  margin-bottom: 8px;
  color: #8a8a8a;
}

.brand-creat-img h4 {
  font-size: 14px;
  font-family: 'nexabold';
}

.starend {
  position: relative;
}

.starend p {
  float: left;
  font-size: 12px;
  width: 50%;
  color: #070707;
}

.starend .next-arow {
  right: auto;
  left: 30%;
  bottom: -20px;
}

.starend p b {
  display: block;
  font-size: 14px;
  margin-top: 2px;
}

.brand-pagi {
  border: 1px solid #eee;
  border-top: 0;
  display: grid;
  grid-template-columns: 45px 1fr 45px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 0px 0px 5px 5px;
  height: 50px;
}

.brand-pagi-arrow {
  height: 100%;
  display: grid;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.brand-pagi-arrow svg {
  width: 16px;
  height: 16px;
}

/*.brand-pagi-arrow svg path {
  fill:#919191;
}*/
.brand-pagi1 {
  height: 100%;
  display: grid;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

.brand-pagi1 ul {
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  grid-column-gap: 10px;
}

.brand-pagi1 ul li a {
  padding: 4px 9px;
  color: #a1a1a1;
  border: 1px solid #fff;
  border-radius: 6px;
  font-size: 14px;
  transition: 0.5s;
}

/* Accordian */
.tabs input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Icon */
.tabs {
  border-radius: 8px;
  overflow: hidden;
  /* box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5); */
}

.tab {
  width: 100%;
  color: white;
  overflow: hidden;
}

.tab-label {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  color: #070707;
  font-size: 16px;
  font-family: 'nexaxbold';
  font-weight: bold;
  cursor: pointer;
  /* Icon */
}

.tab-label:hover {
  /* background: #1a252f; */
}

.tab-label::after {
  content: "❯";
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
}

.tab-content {
  max-height: 0;
  padding: 0 1em;
  color: #2c3e50;
  background: white;
  transition: all 0.35s;
  font-size: 14px;
  color: #8a8a8a;
  line-height: 20px;
  font-family: 'nexabold';
}

.tab-close {
  display: flex;
  justify-content: flex-end;
  padding: 1em;
  font-size: 0.75em;
  background: #2c3e50;
  cursor: pointer;
}

.tab-close:hover {
  /* background: #1a252f; */
}

input:checked+.tab-label {
  /* background: #1a252f; */
}

input:checked+.tab-label::after {
  transform: rotate(90deg);
}

input:checked~.tab-content {
  max-height: 100vh;
  padding: 1em;
}

/* Accordian */

.brand-pagi1 ul li a:hover,
.brand-pagi1 ul li a.active {
  border: 1px solid #070707;
  color: #070707;
}

#brandViewDetail {
  max-width: 550px;
}

.fromInventory {
  z-index: 99 !important;
}

#brandViewDetail .popup-header h3,
#approvalAll .popup-header h3,
#inventoryMore .popup-header h3,
#approveActive .popup-header h3 {
  padding: 0px;
  font-size: 20px;
  color: #070707;
}

#brandViewDetail .brand-creat-img {
  margin-top: 30px;
}

#brandViewDetail .brand-btn {
  margin-top: 35px;
  padding: 0 !important;
}

.brand-creat-img h5 {
  font-size: 18px;
  color: #070707;
}

.viewdetailsMain {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 25px;
}

.viewdetailsLeft {
  display: grid;
  align-content: normal;
}

.viewSlide img {
  width: 100%;
  border-radius: 10px;
}

.viewScroll {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 5px 0;
}

.viewScroll .brand-pagi-arrow svg {
  width: 13px;
  height: 13px;
}

.viewScroll span {
  font-size: 13px;
  color: #070707;
}

.brand-creat-img p .viewwLink {
  float: none;
  text-transform: none;
  font-size: 14px;
}

#brandViewDetail .brand-btn .btn {
  padding: 13px !important;
}

#brandViewDetail .brand-creat-img p {
  color: #070707;
  font-size: 15px;
  line-height: 22px;
}

#approvalAll {
  max-width: 400px;
}

.approveall-div h2 {
  color: #070707;
  font-size: 18px;
  display: flex;
  grid-column-gap: 10px;
  align-items: center;
}

.approveall-div h2 .approve-deadline {
  font-family: 'nexabold';
  margin: 0;
}

.approveall-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.approveall-text .brand-creat-img h3 {
  font-size: 13px;
  margin-bottom: 3px;
}

.approveall-text .brand-creat-img h4 {
  color: #070707;
}

.approveall-div {
  margin-top: 10px;
  border-bottom: 1px solid #ddd;
  display: inline-block;
  width: 100%;
  padding-bottom: 30px;
}

#approvalAll .popup-body {
  padding-bottom: 0;
}

.invetorypopblock {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 8px;
}

.invetorypopInner {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  display: grid;
  grid-template-columns: 50px 1fr;
  grid-column-gap: 11px;
  align-items: center;
  margin-bottom: 10px;
}

.invetorypopInner h3 {
  font-size: 14px;
  font-family: 'nexabold';
}

.invetorypopInner img {
  height: 45px;
  width: 100%;
}

#approveActive,
#confirmedApprovalModal {
  max-width: 400px;
  text-align: center;
}

.approveActive p {
  font-size: 15px;
  line-height: 24px;
  margin-bottom: 25px;
  color: #8a8a8a;
}

.searchinput input {
  height: 100% !important;
}

.dropdownShow {
  width: 350px;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  display: inline-block;
  position: absolute;
  right: 180px;
  top: 100px;
  z-index: 9999;
  background: #fff;
  transition: 0.5s;
  opacity: 0;
  pointer-events: none;
  box-shadow: none;
}

.dropdownShow.active {
  top: 24px;
  opacity: 1;
  pointer-events: all;
}

.dropdownShow .searchinput {
  width: 100%;
}

.dropdownShow .searchinput {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
}

.searchlist {
  margin-top: 15px;
}

.searchlist h3 {
  font-size: 14px;
  color: #8a8a8a;
  margin-bottom: 10px;
}

.searchlist ul li a {
  display: inline-flex;
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  align-items: center;
  grid-column-gap: 8px;
  font-size: 14px;
  color: #070707;
  transition: 0.5s;
  margin-bottom: 3px;
  position: relative;
  padding-left: 40px;
}

.searchlist ul li a:hover {
  background: #fff1e1;
}

.searchlist ul li span {
  width: 27px;
  height: 25px;
  display: flex;
  padding: 3px;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  left: 5px;
  top: 5px;
}

.searchlist.searchlistimg li a {
  padding-left: 9px;
}

.searchlist {
  margin-top: 15px;
  border-bottom: 1px solid #ddd;
}

.dropboxclick.dropboxop {
  opacity: 1;
  pointer-events: all;
}

.notificationtop {
  position: relative;
}

.notificationtop h4 {
  font-size: 14px;
  float: left;
  width: 90%;
}

.notificationtop a {
  position: absolute !important;
  width: auto !important;
  right: 0;
}

.notificationtop img {
  width: 17px !important;
  height: 16px !important;
  fill: #737373;
  /* position: absolute; */
  /* right: 0; */
  /* top: 0; */
}

.notificationList {
  margin-top: 15px;
  display: inline-block;
  width: 100%;
  max-height: 260px;
  overflow-x: auto;
}

.notificationListview {
  display: grid;
  grid-template-columns: 30px 1fr 20px;
  grid-column-gap: 8px;
  padding: 10px;
  transition: 0.5s;
  border-radius: 5px;
  margin-bottom: 5px;
  position: relative;
}

.notificationListview:hover {
  background: #fff1e1;
}

.notification-img {
  background: #fff;
  height: 25px;
  width: 100%;
  border-radius: 5px;
  padding: 3px 5px;
}

.notification-title h4 {
  font-size: 14px;
  color: #070707;
  margin-bottom: 5px;
}

.notification-title span {
  font-size: 13px;
  color: #a7a39f;
  width: 100%;
  display: inline-block;
}

.notification-title span b {
  width: 8px;
  height: 8px;
  background: #2ec193;
  float: left;
  border-radius: 100%;
  margin: 2px 6px 0px 0px;
}

.notification-title p {
  font-size: 12px;
  margin-top: 4px;
  color: #a7a39f;
}

.notification-dot a img {
  width: 18px;
  height: 19px;
  margin-top: 2px;
}

.dropdownShow.showSearchbox.active {
  top: 65px;
}

.dropdownShow.showSearchbox {
  right: 0;
}

.updatebar.sliderbar {
  width: 100%;
}

/*MY PROFILE PAGE CSS START*/
.dblock {
  display: block !important;
}

.viewwLink.proEdit {
  color: #070707;
  position: relative;
}

.table-container .table thead tr th {
  text-align: left;
  padding: 13px 15px;
  font-size: 11px;
  font-family: 'nexabold';
  vertical-align: middle;
}

.addAdmininput a.viewwLink.proEdit {
  margin: 0;
}

.table-container .table tbody tr td {
  text-align: left;
  padding: 16px 15px;
  font-size: 14px;
  font-weight: normal;
}

.tableInner td.imgInventory,
.tableInner td.inventoryCustomName {
  white-space: normal !important;
}

.table-container.table-inventory .table tbody tr td {
  padding: 22px 15px;
  font-family: 'nexaxbold';
}

/* .table-container.table-inventory .table thead tr th {
  padding: 12px 15px;
  font-size: 11px;
} */
/* .table-container.table-inventory .table thead tr th a {
  margin: 0 0px 0 10px;
} */

td.inventoryCustomName .forminpt label {
  font-size: 11px;
  text-transform: uppercase;
  color: #8A8A8A;
  font-weight: 600;
}

.iconAddEdit a img {
  width: 18px;
  height: 18px;
}

.iconAddEdit a {
  margin: 0 10px 0 0;
}

.input-table {
  width: 100%;
  height: 40px !important;
  border: 1px solid #eeeeee;
  font-family: 'nexaregular';
}

td.iconAddEdit {
  text-align: right !important;
}

.align-R {
  text-align: right !important;
}

/* .table-container .table tbody tr td a.btn {
  min-width: initial;
  font-size: 12px;
  padding: 11px 25px !important;
} */
.viewwLink img {
  float: left;
  width: 13px;
  margin: 0px 5px 0 0;
  height: 13px;
}

/*INVENTORY PAGE CSS START*/
.table-status {
  font-size: 13px;
  padding: 4px 9px;
  border-radius: 4px;
}

.greenbg {
  background: #e6f8f2;
  color: #31c295 !important;
}

.orangebg {
  background: #fff1e1 !important;
  color: #fe6e00 !important;
}

.redbg {
  background: #ffeded !important;
  color: #ff4a4a !important;
}

.yellowbg {
  background: #fffce1;
  color: #b4a51f !important;
}

.graybg {
  background: #e9e9e9;
  color: #8a8a8a !important;
}

ul.tabsinventory {
  display: inline-block;
  margin: 20px 0 15px 0;
  /* width: calc(100% - 270px); */
  width: calc(100% - 520px);
}

.tabWidth {
  width: 43% !important
}

ul.tabsinventory li a {
  color: #cdcdcd;
  border-bottom: 3px solid #fff;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-size: 14px;
  transition: 0.5s;
}

ul.tabsinventory li a:hover {
  color: #090909;
}

ul.tabsinventory li a.active {
  color: #090909;
  border-color: #090909;
}

.inventory-tableTabs {
  display: inline-block;
  width: 100%;
  position: relative;
}

.inventorySearch {
  position: absolute;
  right: 0;
  top: 2px;
}

.inventorySearch .searchinput {
  width: 260px;
  float: left;
  height: 45px;
}

.inventory-tableTabs td.iconAddEdit {
  text-align: left !important;
}

.inventory-tableTabs td.iconAddEdit a {
  color: #000;
  text-transform: uppercase;
  font-size: 13px;
}

.inventory-tableTabs td.iconAddEdit a img {
  width: 12px;
  height: 13px;
  margin: 1px 5px 0 0;
  float: left;
}

.paginationInventory ul {
  display: flex;
  justify-content: end;
  grid-column-gap: 10px;
  margin: 25px 0;
  float: right;
}

.paginationInventory ul li a {
  align-content: center;
  color: #8a8a8a;
  display: inline-flex;
  height: 35px;
  width: 35px;
  border: 2px solid #fff;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  font-family: 'nexa_boldregular';
  font-size: 14px;
}

.paginationInventory ul li a svg {
  width: 13px;
  height: 13px;
  fill: #8a8a8a;
}

.paginationInventory ul li a:hover,
.paginationInventory ul li a.active {
  border: 2px solid #070707;
  color: #070717;
}

ul.inventory-overTabs {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

ul.inventory-overTabs li a {
  border: 1px solid #eee;
  display: grid;
  padding: 25px 25px;
  border-radius: 7px;
  position: relative;
}

ul.inventory-overTabs li a p {
  color: #8a8a8a;
  font-size: 14px;
}

ul.inventory-overTabs li a h3 {
  font-size: 19px;
  color: #262626;
  margin-top: 5px;
}

ul.inventory-overTabs li a h3 span {
  font-size: 12px;
  font-family: 'nexabold';
  color: #8a8a8a;
  font-weight: normal;
  margin: 4px 0 0 4px;
}

ul.inventory-overTabs li a h3 span b {
  color: #15bc88;
  margin: 0 5px 0 0;
}



.inventory-overHead p {
  color: #8a8a8a;
  font-size: 14px;
}

.inventory-overHead h3 {
  font-size: 19px;
  color: #070707;
  margin-top: 5px;
  font-family: 'nexabold';
  /* width: 75%; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

.inventory-overHead h3 span {
  font-size: 12px;
  font-family: 'nexabold';
  color: #8a8a8a;
  width: 100%;
  display: inline-block;
}

.inventory-overHead h3 span b {
  color: #15bc88;
}

.inventory-overInner {
  border: 1px solid #eee;
  border-radius: 7px 7px 7px 7px;
  margin-top: 0px;
  padding: 25px;
  position: relative;
}

/*ul.inventory-overTabs li a.active {
  border-bottom: 0;
  border-radius: 7px 7px 0 0;
}
ul.inventory-overTabs li a.active:before {
  content: "";
  position: absolute;
  left: -1px;
  bottom: -31px;
  height: 50%;
  width: 1px;
  background: #eee;
  
}
ul.inventory-overTabs li a.active:after {
  content: "";
  position: absolute;
  right: -1px;
  bottom: -31px;
  height: 50%;
  width: 1px;
  background: #eee;
 
}
.inventory-overInner:before {
  content: "";
  top: -3px;
  left: 0;
  width: 32%;
  background: #fff;
  height: 5px;
  position: absolute;
}*/
.inventory-overTabs01L img {
  height: 180px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 5px;
  object-fit: revert;
}

ul.inventory-overTabs li a.active {
  padding-bottom: 50px;
  border-bottom: 0;
  border-radius: 7px 7px 0px 0px;
}

ul.inventory-overTabs li a.active:before {
  content: "";
  height: 5px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  background: #fff;
  width: 100%;
  z-index: 1;
}

ul.inventory-overTabs li a svg {
  position: absolute;
  right: 25px;
  top: 45%;
  transform: rotate(180deg);
  transition: 0.5s;
}

ul.inventory-overTabs li a.active svg {
  transform: rotate(0);
}

.tabBorderColor1.active {
  border-color: #CFC9FF;
}

.tabBorderColor1 {
  border-color: #CFC9FF !important;
}

.tabBorderColor2 {
  border-color: #F19DFF !important;
}

.tabBorderColor3 {
  border-color: #85DAF0 !important;
}

.inventory-overInner.overTabs01 {
  border-color: #CFC9FF;
  border-radius: 0px 7px 7px 7px;
}

.inventory-overInner.overTabs02 {
  border-color: #F19DFF;
}

.inventory-overInner.overTabs03 {
  border-color: #85DAF0;
}

.inventory-overHead label {
  margin-top: 5px;
  display: inline-block;
  font-size: 14px;
}

.inventory-overTabs03 .brand-btn {
  width: 50%;
  float: right;
  margin-top: 40px;
}

/*SETTINGS PAGE START CSS*/
.passShow {
  position: absolute;
  right: 15px;
  top: 34px;
  width: 17px;
  height: 21px;
}

.line-full {
  height: 1px;
  background: #E9E9E9;
  width: 100%;
}

.tableInner table.table tr td {
  white-space: normal !important;
}

.notification-showhide h4 {
  font-size: 16px;
  margin: 0 0 8px 0;
  font-weight: normal;
}

.notification-showhide p {
  font-size: 14px;
  color: #8a8a8a;
  font-family: 'nexaregular';
}

.last-login svg.location {
  float: left;
}

.last-login .notification-showhide {
  float: left;
  margin: 0 0 0 15px;
}

.last-login .dotdrop {
  width: 17px;
  height: 23px;
  float: right;
  cursor: pointer;
}

/*SUPPORT PAGE START CSS*/
.support-watch {
  margin-top: 20px;
}

.support-watch img {
  width: 100%;
}

.have-question {
  background: #f8f8f8;
  padding: 40px;
  border-radius: 5px;
  padding-bottom: 0;
}

.have-question .htx1 {
  margin-bottom: 5px;
}

.have-question .forminpt {
  margin-top: 20px;
}

.have-question .forminpt textarea {
  height: 200px;
  padding: 15px;
}

.have-question .forminpt small {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: auto;
}

.have-question .btn.btn-orange {
  float: right;
  margin: -20px 0 0 0;
  min-width: initial;
  padding: 15px 25px 12px 25px !important;
  font-size: 13px;
}

.faq-tablist {
  border: 1px solid #E9E9E9;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 12px;
}

.tabs .faq-tablist {
  padding: 0px;
  margin-bottom: 0px;
}

.faq-tablist h4 {
  font-size: 16px;
  font-family: 'nexaxbold';
}

.faq-tablistView {
  margin-top: 15px;
  display: none;
}

.tabs .faq-tablistView {
  margin-top: 0px;
  display: none;
}

.faq-tablistView p {
  font-size: 14px;
  color: #8a8a8a;
  line-height: 22px;
  font-family: 'nexaregular';
}

.faq-tabs-list {
  margin-top: 0px;
  display: inline-block;
  width: 100%;
}

.faq-tablist.active .faq-tablistView {
  display: block;
}

.faq-tablist h4 img {
  width: 13px;
  height: 12px;
  float: right;
  transition: 0.5s;
}

.faq-tablist.active h4 img {
  transform: rotate(180deg);
}
.faq-tablist.active div a #img {
  transform: rotate(180deg);
}

.faq-tablist h4 a {
  color: #070707;
  width: 100%;
  display: inline-block;
  font-size: 14px;
  font-family: 'nexaxbold';
}

/*my school page css start*/

.my-school-reptative {
  background: #ecfbff;
  padding: 15px;
  width: 95%;
  border-radius: 5px;
}

.my-school-reptative h3 {
  font-size: 15px;
  color: #8a8a8a;
  margin-bottom: 10px;
}

.my-school-reptative p {
  font-size: 14px;
  margin-top: 5px;
  font-weight: normal;
  word-wrap: anywhere;
}

.my-school-features h4 {
  font-size: 14px;
  color: #8a8a8a;
  border-bottom: 1px solid #ddd;
  padding-bottom: 9px;
  font-weight: normal;
}

.my-school-features h4 a {
  float: right;
  font-size: 13px;
  color: #070707;
  text-transform: uppercase;
}

.my-school-features h4 a img {
  width: 12px;
  margin: 1px 5px 0 0;
  float: left;
}

.my-school-features ul {
  display: inline-block;
  margin-top: 14px;
}

.my-school-features ul li {
  font-size: 13px;
  display: inline-block;
  margin: 0 10px 8px 0;
  position: relative;
  font-weight: normal;
  padding-right: 15px;
  color: #222;
  font-family: 'nexabold';
}

.my-school-features ul li:before {
  content: "";
  position: absolute;
  right: 0;
  top: 3px;
  width: 6px;
  height: 6px;
  background: #C4C4C4;
  border-radius: 100%;
}

.brand-creat-img p.ptx2 {
  color: #070707;
  font-size: 14px;
  line-height: 22px;
}

.brand-creat-img p.ptx2 a,
.brand-creat-img h4 a {
  color: #FE6E00;
}

.schoolcalendar-head {
  display: flex;
  align-items: center;
  align-content: center;
  grid-column-gap: 20px;
}

.cale-today {
  background: #fff1e1;
  color: #fe7e1c;
  padding: 4px 15px;
  border-radius: 4px;
  font-size: 13px;
}

.cale-months {
  display: flex;
  align-items: center;
  grid-column-gap: 7px;
  font-size: 14px;
}

.schoolcalendar-body {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 15px;
}

.calendar-month-head,
.calendar-days-head {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  height: 40px;
  justify-content: center;
  align-content: center;
  background: #f8f8f8;
  border-radius: 8px;
}

.calendar-days-head {
  background: #fff;
  height: auto;
  text-align: left;
  position: relative;
}

.calendar-day {
  height: 120px;
  padding: 15px 10px;
  display: grid;
  width: 100%;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
  align-content: space-between;
}

.calendar-day:hover {
  background: #EEFEEE;
}

.calendar-day p {
  font-size: 12px;
}

.calendar-day b {
  font-size: 9px;
  color: #8a8a8a;
  font-family: 'nexabold';
  text-transform: uppercase;
}

.calendar-month {
  font-size: 12px;
}

.current-day {
  background: #fff1e1;
  border-color: #eee;
}

.day-brand {
  margin: 0 0 0 -3px;
}

.day-brand span,
.day-brand a {
  width: 28px;
  height: 28px;
  float: left;
  background: #fff;
  border: 1px solid #dddd;
  border-radius: 100%;
  padding: 0px;
  margin: 0 -9px 0 0;
}

.day-brand span img {
  border-radius: 100%;
  height: 100%;
  width: 100%
}

.day-brand a {
  background: #FE6E00;
  color: #fff;
  font-size: 12px;
  display: grid;
  justify-content: center;
  align-content: end;
  word-spacing: -2px;
  border-color: #FE6E00;
}

.day-brand a.brands-popup-count {
  justify-content: center;
  align-content: center;
  padding: 5px;
}

.updatebar.sliderbar ul li.line-sidebar a {
  background: #f3ab74;
  padding: 0;
  height: 1.2px;
  width: 90%;
  margin: 20px auto;
  display: block;
}

.logoNew {
  padding-left: 15px;
}

.inventory-overTabs01R select {
  background-position: 90% 50%;
}

.inventory-overInner.overTabs03 {
  border-radius: 7px 0px 7px 7px;
}

.item-end {
  align-items: end;
}

.item-center {
  align-items: center;
}

.htx-school {
  font-family: 'Playfair Display', serif !important;
  font-size: 35px !important;
  font-weight: bold !important;
}

.day-disable {
  background: #fff !important;
  color: #e8e8e8;
  pointer-events: none;
}

.my-campaign {
  width: 80%;
}

.formset input#phone,
.formset input#fosPhone,
.formset input#contactNumber {
  height: 100%;
  width: calc(100% - 70px);
  background: #fff;
}

.formset select.phcode {
  background-color: #fff;
}

.formset .phcode {
  background-color: #fff;
  height: 100%;
  display: grid;
}

.formset .custom-select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDE3NTQ5RUJCNEE3MTFFQjlFM0E5NzYxNUVBNjE4NDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDE3NTQ5RUNCNEE3MTFFQjlFM0E5NzYxNUVBNjE4NDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MTc1NDlFOUI0QTcxMUVCOUUzQTk3NjE1RUE2MTg0MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MTc1NDlFQUI0QTcxMUVCOUUzQTk3NjE1RUE2MTg0MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpJ5h9sAAADvSURBVHjahNG/q4FRHMfxx2GUDAYyuCVFZLH4C2SwcEvp1h2MDBaTxfAMJhOLP4CIkh/DvdNd7sDiH6GUDCx4P/o+9fTkx6nX6fz4fk6dcxy6rjs0TevgB0vtfcsgj6qLrosKvpDF+kUwhT58cCq6nWx4MUXiSTCKmQSNtjPCTbRkIYAFwrZgCHMEZd5GQ8mkIfc22occYBb6JRiReQ91Y6Asp9cwkHEME8QxQlLWx/I+9+ayhK8ow4Mc0ljBLfu/+MbFDCjb3c4o4U/mZvAfRZysxerBqx7xafmyDQo42AvVk2/ZywFDCW4fFd0EGADTySfgDkYvjAAAAABJRU5ErkJggg==) 84% 50% no-repeat #fff;
  background-size: 12px;
  width: 70px;
  float: left;
  border: 0;
  height: 100%;
}

.ava-brad.ava-school strong a {
  margin: 0px 0 0 6px;
  display: inline-block;
  font-family: 'nexabold';
}

.ava-brad.ava-school strong {
  float: left;
  display: flex;
}

.ava-brad.ava-school strong .tooltip:hover::before {
  left: 0px;
}

.ava-brad.ava-school strong .tooltip:hover::after {
  right: -10px;
}

.tab-panels .btn {
  margin: 0;
}

.tableInner {
  padding: 0 !important;
}

.tableInner .table,
.tableInner .table tr td {
  border: 0 !important;
  border-radius: 5px;
}

.tableInner td {
  padding: 0 15px 15px 0px !important;
  border: 0 !important;
}

.tableInner .table td {
  padding: 15px 10px !important;
  background: #F8F8F8;
}

.forminpt.eventunits .wisr-react-select__control.css-yk16xz-control {
  min-width: 100px;
}

td.inventoryCustomName {
  background: #f8f8f8;
  padding: 10px 25px 10px 15px !important;
}

table.table.placementTable.inventoryCustomMargin thead tr th {
  padding: 0 15px;
}

table.table.placementTable.inventoryCustomMargin .forminpt {
  margin: 0;
}

table.table.placementTable.inventoryCustomMargin .forminpt select#units {
  width: 90px;
}

td.inventoryCustomName .forminpt input#customName,
td.inventoryCustomName .forminpt .single-select {
  margin-top: 7px;
}

td.imgInventory {
  padding: 0 15px 15px 15px !important;
}

table.table.placementTable.inventoryCustomMargin tr th,
table.table.placementTable.inventoryCustomMargin tr td {
  padding: 5px 10px !important;
}

td.inventoryCustomName.pr-0 {
  padding-right: 0px !important;
}

td.inventoryCustomName.pl-0 {
  padding-left: 0px !important;
}

td.iconAddEdit {
  white-space: nowrap;
}

td.imgInventory img {
  max-width: 150px;
  height: 120px;
  border-radius: 5px;
}

.tableInner .table td b {
  color: #8A8A8A;
  width: 100%;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 6px;
  font-family: 'nexabold';
  white-space: nowrap;
}

.tableInner .table td span {
  color: #070707;
  display: inline-block;
  width: 100%;
  margin: 10px 0 0 0;
  word-break: break-all;
}

.tableInner .table td span em {
  font-style: normal;
  color: #8a8a8a;
  margin: 0 0px 0 4px;
}

.tableInner .table td select {
  margin: 8px 0 0 0;
  background-position: 90% 50%;
}

/* .faq-view {
  position: relative;
}
.faq-view .searchinput {
  position: absolute;
  right: 0;
  top: -10px;
} */
.approve-deadline img {
  float: left;
  margin: 0px 5px 0 0;
  width: 14px;
}

.viewwLink img.arrowView {
  float: revert;
  width: 7px;
  margin: 0 0 0 1px;
  height: 10px;
}

.blog-arrow {
  text-align: right;
}

.blog-arrow a {
  margin: 0 8px;
}

.brand-btn a.btn {
  padding: 13px 0 !important;
  font-size: 13px;
}

.inventorySearch a.btn {
  min-width: 50px;
}

.flex-between {
  align-items: inherit;
  display: grid;
}

td.sendRequest {
  border: 0 !important;
  padding-top: 0 !important;
  text-align: right !important;
}

.mypro-disable .forminpt.disabled::before {
  background: rgba(255, 255, 255, 0.6);
}

.faq-view {
  position: relative;
}

.faq-view .inventorySearch {
  top: 4px;
}

.popup-center {
  max-width: 450px !important;
  text-align: center;
  padding: 35px 25px 28px 25px !important;
}

.popup-center .brand-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.popup-body {
  max-height: calc(100vh - 146px);
  overflow-x: auto;
}

#amenitiesModal {
  max-width: 800px;
}

#galleryModal {
  max-width: 650px;
  padding: 20px 0px !important;
  padding-bottom: 0px !important;
  /* padding-top: 5px !important; */

  /* background: black; */
}


#amenitiesModal .tol-built {
  grid-template-columns: 150px 2fr 150px 100px;
}

#galleryModal .tol-built {
  grid-template-columns: 150px 2fr 150px 100px;
}

input#avgAnnualFee {
  text-align: center;
  padding-right: 5px;
}

#amenitiesModal .tol-built .imgSchool {
  position: initial;
  padding: 5px;
}

#galleryModal .tol-built .imgSchool {
  position: initial;
  padding: 5px;
}

.popup-center .forminpt {
  display: flex;
  justify-content: center;
}

input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
  display: none;
}

.settingInput .forminpt input {
  /* commented because of icon is removed. not as per figma */
  /* padding-left: 50px; */
  padding-right: 40px;
}

.last-login .modal {
  position: relative;
}

.last-login .modal .modal-content {
  position: absolute;
  right: -99px;
  top: -8px;
  background: #fff;
  border: 1px solid #ddd;
  padding: 9px 20px;
  color: #FF4A4A;
  cursor: pointer;
  border-radius: 5px;
}

.table .btn {
  transition: none !important;
}

.dropdownShow .searchboxtop .searchinput img {
  left: 0;
  width: 21px;
  height: 21px;
  top: 5px;
}

.dropdownShow .searchboxtop .searchinput input {
  padding-left: 0;
  font-size: 16px;
}

.my-campaign .starend p {
  font-size: 13px;
  font-weight: normal;
}

.my-campaign .starend p b {
  font-size: 14px;
  margin-top: 4px;
}

.have-question p.ptx2 {
  font-size: 14px;
  margin-top: 13px;
  font-family: 'nexaregular';
}

.sc-ad-rightmenu li a b {
  background: #2EC193;
  width: 10px;
  height: 10px;
  position: absolute;
  border-radius: 100%;
  right: -1px;
  top: -1px;
  border: 2px solid #fff;
}

.notificationList .modal {
  position: absolute;
  right: 10px;
  top: 35px;
  max-width: 230px;
  background: #fff;
  box-shadow: 1px 1px 3px #ddd;
  border: 1px solid #ddd;
  border-radius: 5px;
  z-index: 1;
  text-transform: none;
  padding: 10px 0;
}

.notificationList .modal a.notificationListview {
  display: inline-block;
  width: 100%;
  margin: 0;
  font-size: 14px;
  padding: 7px 15px;
  color: #070707;
  text-transform: none;
}

.down-arrow {
  transform: rotate(180deg);
}

.table-container.table-inventory .table tbody tr td svg {
  cursor: pointer;
  float: right;
}

.inventory-tableTabs td.iconAddEdit a svg {
  height: 11px;
  width: 11px;
  float: left !important;
  margin: 2px 5px 0 0;
}

.settingInput .forminpt b.vertext.vrifiyotp {
  right: 40px;
}

.settingInput .verifyLoader.shiftLeft {
  right: 40px;
}

.settingInput a.btn {
  padding: 15px 25px 14px 25px !important;
  font-size: 13px;
}

.notification-showhide.load-more {
  float: right;
  text-align: right;
}

.mypro-disable .forminpt {
  margin: 0 0 10px 0;
}

.popup-center .popup-header .htx2 {
  font-size: 20px;
  font-weight: normal;
  font-family: 'nexabold';
}

.popup-center .popup-body {
  padding-top: 15px;
}

.popup-center .popup-body .facili-avai-in h3 {
  font-size: 15px;
  font-weight: normal;
  font-family: 'nexaregular';
}

.overTabs03 .inventory-overHead p {
  font-size: 13px;
  margin: 0;
}

.inventory-overInner .brand-btn a.btn {
  padding: 15px 0 !important;
  font-size: 13px;
}

.inventory-overTabs01L .tabSlide1 {
  height: 230px;
}

.inventory-overHead {
  margin-top: 15px;
}

.tabSlide1-box ul {
  display: flex;
  grid-column-gap: 5px;
}

.tabSlide1-box ul li {
  display: flex;
  position: relative;
}

.tabSlide1-box ul li span {
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: grid;
}

.tabSlide1-box ul li img {
  height: auto;
}

.tabSlide1-box ul li a {
  height: 45px;
}

.inventorySearch a.btn {
  min-width: 50px;
  padding: 12px 10px !important;
  margin: 0 0 0 10px;
}

#brands-popup,
#events-popup,
#school-events-popup {
  position: absolute;
  max-width: 320px;
}

#brands-popup .popup-header .htx2,
#events-popup .popup-header .htx2,
#school-events-popup .popup-header .htx2 {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  margin-top: 5px;
}

#brands-popup .popup-body .searchlist.cale-popup-tue h3,
#events-popup .popup-body .searchlist.cale-popup-tue h3,
#school-events-popup .popup-body .searchlist.cale-popup-tue h3 {
  text-align: center;
  width: 100%;
  display: none;
}

.searchlist.cale-popup-tue {
  width: 100%;
  display: inline-block;
  margin: 0;
  border: 0;
}

#brands-popup .invetorypopblock {
  display: inline-block;
  width: 100%;
}

#brands-popup,
#events-popup,
#school-events-popup {
  position: absolute;
  max-width: 320px;
  /* border: 1px solid #E9E9E9; */
  /* box-shadow: 3px 2px 5px #ddd; */
}

#school-events-popup .searchlist.cale-popup-tue ul li a:hover {
  background: transparent;
}

.searchlist.cale-popup-tue ul li a {
  padding: 15px;
  padding-left: 50px;
}

.searchlist.cale-popup-tue ul li span {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  padding: 0px;
  border: 1px solid #E9E9E9;
  left: 10px;
  top: 5px;
}

.searchlist.cale-popup-tue ul li span img {
  border-radius: 100%;
}

#events-popup .invetorypopblock {
  display: inline-block;
  width: 100%;
}

#events-popup .searchlist.cale-popup-tue {
  border: 0;
}

#events-popup .searchlist.cale-popup-tue ul li a span,
#school-events-popup .searchlist.cale-popup-tue ul li a span {
  display: none;
}

#events-popup .searchlist.cale-popup-tue ul li a,
#school-events-popup .searchlist.cale-popup-tue ul li a {
  padding-left: 15px;
}

.addAdmininput input#email {
  padding-right: 15px;
}

/* user verify input pading .userVefifyinput .forminpt input {
	padding: 10px 15px;
} */
#events-popup .close {
  right: 10px;
  top: 7px;
}

#amenitiesModal .bottom-error-btn {
  display: none;
}

#amenitiesModal .facili-avai {
  margin: 0;
}

#amenitiesModal .tol-built {
  margin: 0;
}

#galleryModal .bottom-error-btn {
  display: none;
}

#galleryModal .close {
  right: -5px;
  z-index: 9999;
  top: -5px;
}

#galleryModal .facili-avai {
  margin: 0;
}

#galleryModal .tol-built {
  margin: 0;
}



span.closeEdit {
  position: absolute;
  right: -35px;
  top: 3px;
  width: 25px !important;
  left: auto;
}

span.closeEdit img {
  width: 15px;
}

label.placementName {
  margin: 16px 0 0 0;
}

select#units {
  width: 100%;
}

table.table.placementTable td b,
table.table.placementTable td label {
  margin-bottom: 10px;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: -10px;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
  background: #FE6E00;
}

.button--loading {
  position: relative !important;
}

.forminpt.button--loading .btn-orange {
  color: transparent;
}

.forminpt.button--loading {
  width: auto;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}


.popup-body .facili-avai-in .facili-avai-in.clearfix {
  max-height: inherit;
}

/******pop css start********/
.vertext span.button--loading {
  background: #fff;
  position: absolute !important;
  right: 0;
  top: 0;
  width: 100%;
  left: 0;
  margin: auto;
  display: block;
  pointer-events: none;
  height: 100%;
  z-index: 1;
}

.verifyLoader {
  position: absolute;
  right: 15px;
  top: 35px;
}

.verifyLoader .vertext {
  position: initial;
  /* opacity: 0.5; */
}

.verifyLoader span.button--loading {
  position: absolute !important;
  right: 24px;
  top: 10px;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
}

.verifyLoader.active b.vertext.vrifiyotp {
  display: none;
}

.verifyLoader span.button--loading::after {
  right: -22px;

  background-color: transparent;
  border: 4px solid #ffe6d399;
  border-top-color: #fe6e00 !important;
}

.verifyLoader.active {
  pointer-events: none;
}

.checkboxinputList {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}

.checkboxinputList .checkbox,
.checkboxinputList .radioBtn {
  float: left;
  margin: 0 20px 18px 0;
}

.forminpt .checkboxinputList .checkbox label::before {
  margin-top: -3px;
  margin-right: 8px;
}

.forminpt .checkboxinputList .checkbox label {
  font-weight: normal;
  font-family: 'nexaregular';
  font-size: 14px;
}

.tol-built.campaignBudget {
  grid-template-columns: 60px 2fr 150px;
}

.campaignBudget span {
  position: relative;
}

.campaignBudget span b {
  position: absolute;
  left: -2px;
  top: 11px;
}

/*radio button css start*/
.radioBtn [type="radio"]:checked,
.radioBtn [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.radioBtn [type="radio"]:checked+label,
.radioBtn [type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
  text-transform: capitalize;
  font-size: 13px;
}

.radioBtn [type="radio"]:checked+label:before,
.radioBtn [type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0;
  top: -3px;
  width: 18px;
  height: 18px;
  border: 1px solid #070707;
  border-radius: 100%;
  background: #fff;
}

.radioBtnInfra [type="radio"]:checked+label:before,
.radioBtnInfra [type="radio"]:not(:checked)+label:before {
  content: '';
  position: absolute;
  left: 0 !important;
  top: 3px !important;
  width: 15px !important;
  height: 15px !important;
  border: 1px solid #070707;
  border-radius: 100%;
  background: #fff;
}
.radioBtnActive{
  border: 2px black solid !important;
}

.radioBtn [type="radio"]:checked+label:after,
.radioBtn [type="radio"]:not(:checked)+label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 3px ;
  left: 6px ;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.radioBtnInfra [type="radio"]:checked+label:after,
.radioBtnInfra [type="radio"]:not(:checked)+label:after {
  content: '';
  width: 8px;
  height: 8px;
  background: #fff;
  position: absolute;
  top: 7px !important;
  left: 4.5px !important;
  border-radius: 100%;
  transition: all 0.2s ease;
}

.radioBtn [type="radio"]:checked+label:before {
  border-color: #fe6e00;
  background: #fe6e00;
}

.radioBtnInfra [type="radio"]:checked+label:before {
  border-color: #fe6e00;
  background: #fe6e00;
}

.radioBtn [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radioBtnInfra [type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.radioBtn [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radioBtnInfra [type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.radioBtnInfra {
  float: left;
  padding: 5px;
  height: 40px;
  border: 1px solid #E9E9E9;
  border-radius: 6px;
  margin: 0px 10px;
}


/*radio button css end*/

/**NO DATA CSS**/
.noData {
  background: #f6f6f6;
  text-align: center;
  border-radius: 5px;
  display: grid;
  /* justify-content: center;  */
  /* Commented because image got center aligned in high resolution 1920+*/
  align-content: center;
  font-size: 13px;
  color: #8A8A8A;
}


.noData h3 {
  font-weight: normal;
}

.height-one {
  min-height: 240px;
}

.height-two {
  min-height: 400px;
}

.height-one.colored {
  min-height: 245px;
  background: #fbf3e8;
}

.height-one.colored img {
  max-width: 474px;
}

.flexcenter {
  justify-content: center;
  align-items: center;
  display: flex;
}

.support-watch div {
  width: 100% !important;
  height: 100% !important;
}

.support-watch div video {
  border-radius: 6px;
}

.school-events b {
  font-size: 11px;
  border: 1px solid #ddd;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 7px 0 0;
}

/**NO DATA CSS**/

#cropImageModal .cropImg {
  width: 100%;
  height: 450px;
  position: relative;
}

#cropImageModal .popup-body {
  padding: 0;
  /* overflow-x: auto; */
  margin: 10px 0;
}

.cropBtn {
  margin-top: 15px;
  float: right;
}

.cropBtn .btn {
  margin: 0 10px 0 0;
}

.redDownPercentage h3 span b {
  color: red !important;
}

.redDownPercentage h3 span b img.arrowUp {
  display: none;
}

.redDownPercentage h3 span b img.arrowDown {
  display: inline-block;
  transform: rotate(180deg);
}

.removeArrow h3 span b img {
  display: none !important;
}

.redDownPercentage h3 span b img.arrowDown {
  display: inline-block;
}

.redDownPercentage h3 span b img.arrowUp {
  display: none;
}

.greenUpPercentage h3 span b img.arrowDown {
  display: none;
}

.greenUpPercentage h3 span b img.arrowUp {
  display: inline-block;
}

.removeArrow h3 span b {
  color: #8a8a8a !important;
}

/* Gopal CSS */
.anchor-disabled {
  pointer-events: none !important;
  opacity: 0.6;
}

.text-uppercase {
  text-transform: uppercase;
}

/* Gopal CSS end */
.mypro-disable .mb-20 {
  position: relative;
}

.mypro-disable .proEdit {
  top: -3px;
  z-index: 2;
  position: absolute;
  right: 10px;
}

.manageImgSlider #image-gallery {
  display: inline-flex;

}

.manageImgSlider #image-gallery .image {
  width: 70px !important;
  margin: 0 10px 0 0;
}

.inventoryDashboardModal {
  height: 400px;
}

.inventoryDashboardModal .popup-body {
  max-height: 100%;
  height: 100%;
}

.venueInputother {
  margin-top: 10px;
}

.venueInputother .react-tagsinput {
  margin: 0;
  padding: 0;
}

.venueInputother .react-tagsinput span {
  margin: 0 !important;
  padding: 0;
}


.venueInputother .react-tagsinput span input {
  padding: 5px 10px;
  height: 35px !important;
}

.venueInputother .react-tagsinput span span.react-tagsinput-tag-custom {
  width: auto !important;
  padding: 4px 8px !important;
  font-size: 13px !important;
  margin: 0 5px 0 0 !important;
}

.venueInputother .react-tagsinput span {
  margin: 5px !important;
}

.sch-add-img #image-gallery .image {
  margin-right: 10px;
}

#addInventoryBtn {
  padding: 14px 10px 10px 10px !important;
}

.venueInputother .react-tagsinput span {
  margin: 0 !important;
  padding: 0;
}

.tableInner .table td b {
  margin: 10px 0 0 0;
}

.brand-creat-img ul {
  display: grid !important;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 8px;
  grid-row-gap: 10px;
}

.brand-creat-img ul li {
  border: 0;
  padding: 0;
  margin: 0 !important;
  height: 55px;
  border-radius: 5px;
}

.brand-creat-img ul li img {
  height: 100%;
  object-fit: revert-layer;
  border-radius: 5px;
}

#brandViewDetail .viewSlide {
  height: 250px;
  border: 1px solid #ddd;
  padding: 0;
  border-radius: 5px;
  margin-bottom: 20px;
}

.overheadThree .ptx2 {
  font-size: 12px;
}

#brandViewDetail .viewSlide img {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

.formset.mobileNoIcon .css-yk16xz-control,
.formset.mobileNoIcon .css-1pahdxg-control {
  height: 30px !important;
}

@media screen and (max-width: 1366px) {
  .tabWidth {
    width: 100%
  }

  .htx2 {
    font-size: 18px;
    font-weight: normal;
  }

  /* .brand-btn a.btn {
    min-width: inherit;
  } */
  .brand-btn {
    padding: 10px 5px 10px 5px !important;
    min-width: auto;
  }

  .height-one.colored img {
    max-width: 100%;
  }

}

@media screen and (max-width: 1024px) {
  .tabWidth {
    width: 100%
  }

  .brand-btn {
    grid-column-gap: 10px;
  }

  .brand-creat-img ul li img {
    height: 50px;
  }

  .onboding .htx1.mb40 {
    margin: 0;
  }

}

@media screen and (max-width: 1000px) {
  .tabWidth {
    width: 100%
  }

  .inventorySearch {
    top: -30px;
  }

  .htx-school {
    font-size: 25px !important;
  }

  .schoolcalendar {
    margin-top: 30px;
  }

  .my-campaign {
    width: 100%;
  }

  ul.inventory-overTabs li a svg {
    right: 15px;
  }

  ul.inventory-overTabs li a {
    padding: 15px 20px;
  }

  .popup {
    width: 80%;
  }
}

.brand-creat-img ul {
  display: inline-block;
}

.brand-creat-img ul li {
  display: inline-block;
  margin: 0 10px 0 0;
}

ul.inventory-overTabs li a p {
  color: #8a8a8a;
  font-size: 14px;
  /* white-space: nowrap; */
  /* overflow: hidden; */
  text-overflow: ellipsis;
}

ul.inventory-overTabs li a h3 {
  font-size: 18px;
}

.inventory-overTabs01L img {
  height: 170px;
}

.inventory-overHead p {
  font-size: 12px;
  margin-bottom: 6px;
  text-transform: none;
  font-family: 'nexaregular';
  font-weight: normal;
}

.inventory-overHead h3 {
  font-size: 18px;
}

ul.tabsinventory li a {
  font-size: 14px;
}

ul.tabsinventory li {
  margin: 0 40px 25px 0;
  display: inline-block;
}

.tableInner .table td span {
  font-size: 14px;
}

.sliderbar.updatebar.no_highlight {
  width: 350px;
}

.popup-header {
  text-transform: uppercase;
  font-size: 18px;
}

.popup-body {
  padding: 25px 0;
}

.button {
  padding: 10px 15px;
  border: 1px solid #F5F5F5;
  text-transform: uppercase;
  box-shadow: 1px 2px 3px #F5F5F5;
  color: #5E5E5E;
  cursor: pointer;
  transition: all 120ms ease-in;
  outline: none;
}

.button:hover {
  box-shadow: 1px 3px 5px #E9E9E9;
  transform: translateY(-1px);
}

.button:active,
.button:visited {
  transform: translateY(1px);
  outline: none;
  box-shadow: 1px 2px 3px #F5F5F5;
}

/******pop css end********/
.poz {
  position: relative;
  z-index: 5;
}

.longEnough {
  max-height: 220px;
  width: 350px;
  overflow: auto;
}

.rightPart.w80.content .mCSB_inside>.mCSB_container {
  margin-right: 30px;
}

.addnameart {
  position: relative;
  margin-top: 14px;
  display: none;
}

.addnameart.active {
  display: block;
}

.addnameart .add-inve {
  position: absolute;
  right: 15px;
  top: 9px;
  /* font-weight: normal; */
  /* font-family: 'nexa_boldregular'; */
  z-index: 1;
}

.tabbed-content.tabs-side .tabs {
  width: 200px;
  float: left;
  max-height: 680px;
  overflow-x: auto;
  padding-right: 15px;
  position: relative;
  z-index: 0;
}

.tabs ul li a.active::before {
  content: "";
  position: absolute;
  right: -8px;
  top: 38%;
  /* height: 10px; */
  /* width: 15px; */
  border-left: 8px solid #FF9406;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.tabs ul li a.active::after {
  content: "";
  position: absolute;
  right: -6px;
  top: 38%;
  /* height: 10px; */
  /* width: 15px; */
  border-left: 8px solid #fffbf5;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
}

.active.complete::before,
.active.complete::after {
  display: none;
}

.tabbed-content.tabs-side .tabs .mCSB_inside>.mCSB_container {
  margin-right: 0;
}

.tabbed-content.tabs-side .mCustomScrollBox {
  padding-right: 25px;
}

#image-gallery {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  grid-column-gap: 10px;
}

.img-wrapper a img {
  border-radius: 5px;
  position: relative;
  height: 55px;
}

.img-wrapper {
  position: relative;
  margin-top: 15px;
}

.img-wrapper img {
  width: 100%;
}

.img-overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 90%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  border-radius: 5px;
  transition: 0.5s;
}

#image-gallery .image .img-wrapper:hover .img-overlay {
  opacity: 1;
}

#image-gallery .image {
  position: relative;
  margin-bottom: 10px;
}

#image-gallery .img-wrapper:hover .img-overlay {
  opacity: 1;
}

.img-overlay i.fa.fa-plus {
  position: absolute;
  right: 6px;
  top: 4px;
  transform: rotate(45deg);
  font-size: 15px;
}

.img-overlay i {
  color: #fff;
  font-size: 18px;
}

.img-wrapper a {
  display: inline-block;
  width: 100%;
}

.imageView {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 414px;
  height: 414px;
}

.imageView img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

#overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

#overlay.imageView {
  background: rgba(128, 128, 128, 0.7);
  width: 100%;
  height: 55%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 5px;
}

#overlay.imageView img {
  margin: 0;
  width: auto;
  height: auto;
  /* object-fit: contain; */
  padding: 0%;
  background-color: #fff;
}


@media screen and (max-width: 768px) {
  #overlay img {
    width: 60%;
  }

  .tabWidth {
    width: 100%
  }

  .bookingStatus {
    margin-bottom: 25px;
  }

  .brand-creat-img ul {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .tabWidth {
    width: 100%
  }

  #overlay img {
    width: 450px;
    padding: 0;
    height: 330px;
    object-fit: cover;
    border-radius: 5px;
  }
}

#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}

#nextButton:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  #nextButton {
    font-size: 20px;
    position: absolute;
    bottom: 31px;
    right: 37%;
    cursor: pointer;
  }
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
}

#prevButton:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  #prevButton {
    font-size: 20px;
    position: absolute;
    bottom: 31px;
    right: 39%;
    cursor: pointer;
  }
}

#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

#exitButton:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) {
  #exitButton {
    font-size: 22px;
  }
}

/**********tabs html css start**************/

.tabs ul {
  margin: 0;
  padding: 0 0 1em 0;
  font-weight: bold;
}

.tabs ul li a {
  padding: 0.5em 1em;
}

.tabs ul li a.active {
  background: #fffbf5;
  color: #eee;
  border-color: #FF9406;
}

.tabs ul li a:hover {
  background: #fffbf5;
  color: #eee;
  border-color: #FF9406;
}

.tabs ul li a.complete {
  background: #f7fdfb;
  color: #eee;
  border-color: #2ec193;
  position: relative;
}

.tabbed-content .tabs li a img {
  display: none;
}

.tabs ul li a.complete img {
  position: absolute;
  right: 7px;
  top: 5px;
  width: 19px;
  height: 19px;
  display: block;
}

/* GOPAL CSS START */
.tabs ul li a.complete.custom-complete img {
  position: absolute;
  right: 30px;
  top: 9px;
  width: 19px;
  height: 19px;
  display: block;
}

/* GOPAL CSS END */
.item {
  margin-bottom: 2px;
}

.item::before {
  cursor: pointer;
  background: #fff;
  padding: 10px 10px;
  display: block;
  border: 1px solid #E9E9E9;
  text-align: center;
  font-weight: 600;
  border-radius: 5px;
}

.item.active::before {
  background: #fffbf5;
  color: #FF9406;
  border-color: #FF9406;
  border-radius: 5px 5px 0 0;
}

.item.active .item-content {
  padding: 0 0 0 15px;
  -webkit-transition: opacity 0.3s ease-in-out;
  -moz-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  -ms-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}

@media all and (min-width:700px) {
  .item.active .item-content {
    padding-top: 0;
  }

  .tabs-side .tabs li {
    margin-bottom: 10px;
  }
}

/* 
The project specific CSS starts here
This is the minimum CSS that you will need in order for this to work
*/
.tabbed-content .tabs {
  display: none;
}

.tabbed-content .item {
  min-height: 2em;
}

.tabbed-content .item::before {
  content: attr(data-title);
}

.tabbed-content .item .item-content {
  opacity: 0;
  visibility: hidden;
  height: 0;
}

.tabbed-content .item.active .item-content {
  opacity: 1;
  visibility: visible;
  height: auto;
}

/*
 CSS for the main interaction
*/

.tabset>input[type="radio"] {
  position: absolute;
  left: -200vw;
}

/* .tabset .tab-panel { display: none; } */
.tabset>input:first-child:checked~.tab-panels>.tab-panel:first-child,
.tabset>input:nth-child(3):checked~.tab-panels>.tab-panel:nth-child(2),
.tabset>input:nth-child(5):checked~.tab-panels>.tab-panel:nth-child(3),
.tabset>input:nth-child(7):checked~.tab-panels>.tab-panel:nth-child(4),
.tabset>input:nth-child(9):checked~.tab-panels>.tab-panel:nth-child(5),
.tabset>input:nth-child(11):checked~.tab-panels>.tab-panel:nth-child(6) {
  display: block;
}

/*
 Styling
*/
.tabset>label {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  border: 0;
  border-bottom: 0;
  cursor: pointer;
  font-weight: 500;
  text-transform: uppercase;
  color: #777777;
  font-family: 'nexabold', sans-serif;
  font-size: 14px;
}

.tabset>label:hover,
.tabset>input:focus+label {
  color: #FE6E00;
}

.tabset>label:hover::after,
.tabset>input:focus+label::after,
.tabset>input:checked+label::after {
  background: #06c;
}

.tabset>input:checked+label {
  border-color: #ccc;
  border-bottom: 4px solid #070707;
  margin-bottom: -2px;
  color: #070707;
}

.attri-add {
  margin-top: 15px;
}

.tab-panel {
  padding: 20px 0;
  border-top: 1px solid #ccc;
}

/*
 Demo purposes only
*/
.content-loader {
  width: 60%;
  height: 9px;
  border-radius: 6px;
  animation: loader 1.5s ease-out infinite alternate;
}

.content-loader-block {
  width: 60%;
  height: 18px;
  border-radius: 6px;
  animation: loader 1.5s ease-out infinite alternate;
}

@keyframes loader {
  0% {
    background-color: hsl(216, 9%, 90%);
  }

  100% {
    background-color: hsl(0, 0%, 100%);
  }
}

@media all and (min-width:700px) {
  .tabbed-content .tabs {
    display: block;
  }

  .tabbed-content .tabs li {
    display: inline-block;
  }

  .tabbed-content .tabs li a {
    border: 1px solid #E9E9E9;
    background: #fff;
    min-height: 120px;
    display: grid;
    justify-content: center;
    align-content: center;
    align-items: center;
    justify-items: center;
    border-radius: 5px;
    position: relative;
  }

  .tabbed-content .item {
    min-height: 0;
  }

  .tabbed-content .item::before {
    display: none;
  }

  .tabbed-content.tabs-side .tabs {
    width: 200px;
    float: left;
  }

  .tabbed-content.tabs-side .tabs li {
    display: block;
  }

  .tabbed-content .tabs li a h4 {
    font-size: 16px;
    font-weight: 500;
    font-family: 'nexabold', sans-serif;
    color: #070707;
  }

  .tabbed-content .tabs li a p {
    font-size: 13px;
    color: #8C8C8C;
    margin-top: 7px;
    font-family: 'nexaregular', sans-serif;
    font-weight: normal;
  }
}

/**********tabs html css end**************/

/*email verification*/

.email-pop {
  position: fixed;
  right: -100%;
  top: 0;
  width: 350px;
  background: #fff;
  padding: 50px 30px;
  height: calc(100vh - 0px);
  z-index: 9;
  transition: 0.5s;
}

.email-pop.active {
  right: 0;
}

.close-pop img {
  height: 16px;
  position: absolute;
  right: 15px;
  top: 10px;
}

.email-pop .htx2 {
  margin-bottom: 10px;
}

.email-pop .ptx2 {
  color: #070707;
  margin-bottom: 20px;
}

.email-pop label {
  margin: 15px 0;
  display: block;
  color: #8A8A8A;
  font-weight: 500;
}

.email-pop label a {
  color: #FE6E00;
  text-transform: uppercase;
}

.email-pop .btn {
  width: 100%;
  margin-top: 15px;
}

.mobile-verifi {
  position: relative;
}

.mobile-verifi span {
  position: absolute;
  right: 14px;
  top: 14px;
  font-size: 14px;
}

/***********************Update Details********************************/

.searchbox {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column-gap: 16px;
}

.searchinput {
  display: grid;
  grid-template-columns: 35px 1fr;
  width: 290px;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
  height: 40px;
}

.searchinput input {
  background: transparent;
  border: 0;
  border-radius: 0;
  height: 100%;
  padding-right: 0;
}

.searchinput img {
  position: absolute;
  left: 12px;
  top: 9px;
  width: 17px;
  pointer-events: none;
  height: 20px;
}

.searchadd {
  border-radius: 100%;
  width: 40px;
  height: 40px;
  min-width: auto;
  padding: 0;
  display: grid;
  justify-content: center;
  align-items: center;
}

.searchadd img {
  width: 16px;
  height: 16px;
}

.sc-ove-list {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: 30px;
}

.sc-ove-list span {
  height: 64px;
  width: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #EBEBEB;
  border-radius: 5px;
}

.sc-ove-text {
  width: calc(100% - 84px);
  padding: 0 0 0 20px;
}

.sc-ove-text h3 {
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 6px 0;
  font-family: 'nexabold', sans-serif;
}

.sc-ove-text p {
  font-size: 15px;
  font-weight: normal;
  color: #8A8A8A;
}

.sc-ove-text .tx-orange {
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 6px;
}

.table {
  width: 100%;
  background-color: transparent;
  border-collapse: collapse;
  border: 1px solid #E9E9E9;
  background: #f8f8f8;
}

.table tbody {
  background: #fff;
}

.table td,
.table th {
  padding: .75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table-bordered td,
.table-bordered th {
  font-family: 'nexaxbold', sans-serif;
  border: 1px solid #E9E9E9;
  text-align: left;
  width: 25%;
  border-left: 0;
  border-right: 0;
  white-space: nowrap;
}

.table-bordered th:last-child {
  border-right: 1px solid #E9E9E9;
}

.table-bordered td:last-child {
  border-right: 1px solid #E9E9E9;
}

.table thead th,
.table td.head {
  vertical-align: bottom;
  border: 0;
  color: #8A8A8A;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  background: #f8f8f8;
}

.table-bordered td {
  font-size: 14px;
  /* font-weight: 500; */
}

.table-bordered td p {
  margin-bottom: 10px;
  font-family: 'nexaxbold', sans-serif;
}

.table-bordered .img-school {
  width: 265px;
  min-width: 202px;
  overflow-x: auto;
}

.img-school a img {
  width: 45px;
  height: 45px;
}

.img-school a {
  position: relative;
  margin: 0 8px 0 0;
  display: inline-block;
}

.img-school a span {
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
}

/*.img-school a:last-child::before { content: ""; position: absolute; left: 0; top: 0; width: 100%; height: 90%; background: rgba(0, 0, 0, 0.4); border-radius: 5px; }*/
.btn-green-light {
  background: #E6F8F2;
  color: #2EC193;
}

.invetory-table-list .table-bordered th {
  padding: 12px 12px;
}

.invetory-table-list.school-list .table-bordered td {
  white-space: normal;
}

.invetory-table-list.school-list .table-bordered tr:hover {
  background-color: #fbfbfb;
}

.invetory-table-list .tx-orange {
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 16px;
}

.invetory-table-list .tx-orange img {
  width: 16px;
}

.table-bordered td h2 {
  font-size: 15px;
  position: relative;
  font-family: 'nexaxbold', sans-serif;
}

.table-bordered td p.ptx2 {
  font-size: 14px;
  font-family: 'nexaxbold', sans-serif;
}

/************************Update Details***********************/
.table-bordered thead th {
  white-space: nowrap;
}

.table-bordered td p img {
  margin: 0 7px 0px 0;
}

.reven-gend {
  position: absolute;
  right: 0;
  top: -3px;
  text-align: right;
}

.reven-gend h4 {
  font-size: 20px;
  font-family: 'nexabold', sans-serif;
}

.reven-gend .ptx2 {
  font-size: 14px;
}

.blanktd {
  background-image: linear-gradient(to right, #eee, #f7f7f7);
  height: 25px;
  max-width: 130px;
  margin-bottom: 8px;
}

.noresult {
  text-align: center;
  padding: 35px 0;
}

.noresult h5 {
  font-size: 17px;
  margin-bottom: 8px;
}

.tooltip:hover::after {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  background: #070707;
  border-radius: 5px;
  color: #fff;
  content: attr(title);
  margin: 0;
  font-size: 13px;
  padding: 6px 12px;
  width: 135px;
  position: absolute;
  right: 0;
  top: -36px;
  text-transform: none;
  /* text-align: center; */
  z-index: 1;
  font-weight: 600;
  white-space: nowrap;
}

.tooltip {
  display: inline;
  position: relative;
  top: 1px;
  white-space: normal;
}

.tooltip:hover::before {
  border: solid;
  border-color: #070707 transparent;
  border-width: 8px 6px 0 6px;
  content: "";
  left: 0;
  bottom: 23px;
  position: absolute;
}

.breadcrumb li {
  font-size: 14px;
  display: inline-flex;
  margin: 0 9px 0px 0;
}

.breadcrumb li span,
.breadcrumb li b,
.breadcrumb li strong {
  color: #8A8A8A;
}

.breadcrumb {
  margin-bottom: 25px;
}

.breadcrumb li a {
  color: #070707;
}

.formset input {
  width: calc(100% - 60px);
  float: left;
  border-radius: 0;
  border: 1px solid #fff;
  height: 100%;
  background: transparent;
  padding: 5px 8px;
}

.formset select {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAKCAYAAABrGwT5AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDE3NTQ5RUJCNEE3MTFFQjlFM0E5NzYxNUVBNjE4NDAiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDE3NTQ5RUNCNEE3MTFFQjlFM0E5NzYxNUVBNjE4NDAiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MTc1NDlFOUI0QTcxMUVCOUUzQTk3NjE1RUE2MTg0MCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MTc1NDlFQUI0QTcxMUVCOUUzQTk3NjE1RUE2MTg0MCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PpJ5h9sAAADvSURBVHjahNG/q4FRHMfxx2GUDAYyuCVFZLH4C2SwcEvp1h2MDBaTxfAMJhOLP4CIkh/DvdNd7sDiH6GUDCx4P/o+9fTkx6nX6fz4fk6dcxy6rjs0TevgB0vtfcsgj6qLrosKvpDF+kUwhT58cCq6nWx4MUXiSTCKmQSNtjPCTbRkIYAFwrZgCHMEZd5GQ8mkIfc22occYBb6JRiReQ91Y6Asp9cwkHEME8QxQlLWx/I+9+ayhK8ow4Mc0ljBLfu/+MbFDCjb3c4o4U/mZvAfRZysxerBqx7xafmyDQo42AvVk2/ZywFDCW4fFd0EGADTySfgDkYvjAAAAABJRU5ErkJggg==) 84% 50% no-repeat #fff;
  background-size: 12px;
  width: 60px;
  float: left;
  border: 0;
  height: 100%;
}

.create-top {
  display: inline-block;
  width: 100%;
}

.create-top ul {
  display: inline-block;
}

.create-top ul li {
  display: inline-block;
  margin: 0 40px 0 0;
}

.stepul li a {
  color: #9c9c9c;
  text-transform: uppercase;
  font-size: 13px;
  position: relative;
  padding-left: 40px;
  transition: 0.5s;
}

.stepul li a span {
  width: 28px;
  height: 28px;
  position: absolute;
  border-radius: 100%;
  left: 0;
  top: -9px;
  background: #fff;
  border: 2px dashed #9c9c9c;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 6px 0;
  transition: 0.5s;
}

.stepul li a:hover,
.stepul li a.active {
  color: #070707;
}

.stepul li a:hover span,
.stepul li a.active span {
  background: #070707;
  color: #fff;
  border-color: #070707;
}

.stepul {
  margin: 20px 0 40px 0;
}

.forminpt {
  margin-bottom: 30px;
  position: relative;
}

.stepul li a.active.complete span {
  background: #2EC193;
  color: #fff;
  border-color: #2EC193;
  font-size: 0;
}

.stepul li a img {
  display: none;
}

/********update school details*******/

.stepul li a.active.complete span img {
  margin-top: 1px;
  display: block;
}

.steptabs li a {
  color: #c9c9c9;
  border-bottom: 2px solid transparent;
  padding: 9px 0;
  text-transform: uppercase;
  font-size: 15px;
  transition: 0.6s;
}

.invetory-list .steptabs li a {
  pointer-events: none;
}

.steptabs {
  margin-bottom: 30px;
}

.steptabs li {
  display: inline-flex;
  margin: 0 40px 0 0;
}

.steptabs li a:hover,
.steptabs li a.active {
  border-bottom: 3px solid #070707;
  color: #070707;
}

/*your details*/
.css-yk16xz-control {
  border: 1px solid #ddd;
  /* height: 45px; */
  padding: 0;
  color: #171717;
  font-size: 14px;
}

.css-yk16xz-control .css-g1d714-ValueContainer {
  /*padding: 0 15px;*/
  height: 100%;
}

.css-1hb7zxy-IndicatorsContainer span {
  display: none;
}

.css-g1d714-ValueContainer {
  overflow: visible !important;
}

#closePopup .popup-body .facili-avai-in .forminpt {
  margin: 0;
}

.popup-center .popup-body .facili-avai-in .forminpt input,
.popup-center .popup-body .facili-avai-in .forminpt a {
  margin: 0 0 0 10px;
  padding: 16px 20px !important;
  height: auto;
  width: 45%;
  min-width: auto;
}

.activies-mid.activi-customeadd ul li.active a {
  top: 13px;
  right: 14px;
  width: 14px;
  height: 13px;
}

.activies-mid.activi-customeadd ul li.active img.star {
  top: 11px;
  right: 37px;
}

.stutablebox.activeadd-custome {
  grid-column: 1 / span 2;
}

.checkbox.onboardcheck label:after {
  /* border-color: #fff !important; */
  width: 3px !important;
  height: 7px !important;
}

/* SWATI CSS START */
.slideshow {
  overflow: hidden;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  width: 100%;
}

/* SWATI CSS END */

/* GOPAL CSS START */
#addNewAttribute {
  position: relative;
}

.tabbed-content.tabs-side .tabs {
  overflow-y: auto;
}

/* GOPAL CSS END */

/* SARANI CSS */
.invetorypopblock-school {
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-bottom: 8px;
  text-align: '-webkit-center';
}

.schools-list-popup {
  justify-content: center;
}

/* SARANI CSS */